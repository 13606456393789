import { Route, Switch, useLocation } from 'react-router-dom'
import {
  COORDINATOR_LEVELTWO_ROUTES,
  COORDINATOR_LEVELONE_ROUTES,
  COORDINATOR_PREAP_ROUTES,
  NAV_LINKS,
  COORDINATOR_LEVELTWO_NAV,
  COORDINATOR_LEVELONE_NAV,
  COORDINATOR_PREAP_NAV,
} from '../../constants/NavConstants'
import * as pages from '../../containers'
import { PrimaryNavigation, CoordinatorSecondaryNavigation } from '../navigation'
import CoordinatorStatusPanel from '../status/CoordinatorStatusPanel'
import CoordinatorExamReadinessPromo from '../examReadiness/coordinator/CoordinatorPromo'
import SetupRoute from '../routes/SetupRoute'
import FundingRoute from '../routes/FundingRoute'
import Surveys from '../surveys/Surveys'
import { setPartFormToSigned } from '../../actions/settingsOrg'
import CoordinatorNotifications from '../notifications/coordinator/CoordinatorNotifications'
import { isFeatureEnabled } from '../../selectors/features'

const mapStateToProps = state => {
  const {
    user: {
      data: { isCoordinator, selectedOrgId, isPreAPOnly },
    },
    settingsOrg,
  } = state
  const { data, fetching } = settingsOrg[selectedOrgId] || {}
  const partFormSigned = data?.participationForm?.coordinatorSigned

  return {
    ...state.user.data,
    turnOnListener: isCoordinator && !partFormSigned && !isPreAPOnly,
    orgSettingsLoadedAtLeastOne: data || isPreAPOnly,
    fetching,
    statusPanelEnabled: isFeatureEnabled(state, 'STATUS_PANEL'),
  }
}

const shouldShowStatusPanel = ({ pathname }) =>
  pathname === NAV_LINKS.dashboard ||
  pathname === NAV_LINKS.courses ||
  pathname === NAV_LINKS.students
const shouldShowNotices = ({ pathname }) =>
  pathname === NAV_LINKS.orders || shouldShowStatusPanel({ pathname })
const showExamReadinessProp = ({ pathname }) => pathname === NAV_LINKS.dashboard

const MainAppRoute = props => {
  const {
    Component,
    userDefaults,
    isLevelTwo,
    showSecondaryNav = true,
    fetching,
    statusPanelEnabled,
  } = props
  const location = useLocation()

  return (
    <>
      {/* NAVIGATION */}
      <PrimaryNavigation {...userDefaults} hasSecondaryNav={showSecondaryNav} />
      {showSecondaryNav ? <CoordinatorSecondaryNavigation /> : null}

      <div className="skipwrapper">
        <a id="main" tabIndex="-1">
          -
        </a>
      </div>

      {/** NOTIFICATIONS */}
      {shouldShowNotices(location) ? <CoordinatorNotifications /> : null}

      {/* EXAM READINESS PROMO */}
      {isLevelTwo && showExamReadinessProp(location) && !fetching ? (
        <CoordinatorExamReadinessPromo />
      ) : null}

      {/* STATUS PANEL */}
      {isLevelTwo && shouldShowStatusPanel(location) && statusPanelEnabled ? (
        <CoordinatorStatusPanel />
      ) : null}

      <Component />
      <Surveys />
    </>
  )
}

const getNavOptionsByUser = ({ isLevelOne, isPreAPOnly }) => {
  const navOptions = {
    routes: isPreAPOnly
      ? COORDINATOR_PREAP_ROUTES
      : isLevelOne
      ? COORDINATOR_LEVELONE_ROUTES
      : COORDINATOR_LEVELTWO_ROUTES,
    title: 'AP Registration and Ordering',
    menu: isPreAPOnly
      ? COORDINATOR_PREAP_NAV
      : isLevelOne
      ? COORDINATOR_LEVELONE_NAV
      : COORDINATOR_LEVELTWO_NAV,
  }

  return navOptions
}

const CoordinatorApp = props => {
  const { orgSettingsLoadedAtLeastOne } = props
  const userNavOptions = getNavOptionsByUser(props)

  if (orgSettingsLoadedAtLeastOne)
    return (
      <Switch>
        <SetupRoute path={NAV_LINKS.setup} />
        <FundingRoute path={NAV_LINKS.districtfunding} />
        {userNavOptions.routes.map((r, i) => (
          <Route
            exact
            path={r.path}
            render={() => {
              return (
                <MainAppRoute
                  Component={pages[r.component]}
                  userDefaults={userNavOptions}
                  {...props}
                />
              )
            }}
            key={i}
          />
        ))}
        <Route component={pages.NotFoundPage} />
      </Switch>
    )

  return null
}

export default connect(mapStateToProps, { setPartFormToSigned })(props => (
  <CoordinatorApp {...props} />
))

// Appsync not working
// Temporarily remove until backend is fixed

/* export default withRouter(
  connect(
    mapStateToProps,
    { setPartFormToSigned }
  )(props => (
    <NotificationMessageListener
      Component={CoordinatorApp}
      componentProps={props}
      processMessage={data => props.setPartFormToSigned(props.selectedOrgId, data.lastUpdated)}
      subscription="MsgByOrg"
      variables={{
        orgId: props.selectedOrgId,
        role: props.roles[props.selectedRole]?.role,
        type: 'DS',
      }}
      turnOnListener={props.turnOnListener}
    />
  ))
) */
