import { getExamWindowRefData } from '../../selectors/examWindows'
import * as c from '../../constants/PackingListConstants'

const mapStateToProps = state => ({ examWindowRefData: getExamWindowRefData(state) })

export const ShipmentWindowTitle = connect(mapStateToProps)(
  ({ pkg: { testDayType, weekNumber }, examWindowRefData }) => {
    let title = `${examWindowRefData[testDayType]?.displayName} Testing`
    if (weekNumber) {
      title += weekNumber === 1 ? `, ${c.TITLE_WEEK_1}` : `, ${c.TITLE_WEEK_2}`
    }
    return title
  }
)

export default ({ pkg }) => {
  const { examType } = pkg
  let exam = ''
  switch (examType) {
    case c.STANDARD_PACKAGE_ENUM:
      exam = c.STANDARD_PACKAGE
      break
    case c.BRAILLE_LARGE_FONT_PACKAGE_ENUM:
      exam = c.BRAILLE_LARGE_FONT_PACKAGE
      break
    case c.STUDIO_ART_PACKAGE_ENUM:
      exam = c.STUDIO_ART_PACKAGE
      break
    case c.NON_STOCK_PACKAGE_ENUM:
      exam = c.NON_STOCK_PACKAGE
      break
    default:
      break
  }
  const title = (
    <>
      <ShipmentWindowTitle pkg={pkg} /> - {exam}
    </>
  )
  return (
    <h3>
      Packing Shipment:
      <br />
      {title === ' - ' ? '' : title}
    </h3>
  )
}
