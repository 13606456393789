/**
 * Link that opens new tab or window on link click while ignoring
 * other event effects.
 */
export default ({ url, disabled, children }) => (
  <a
    href={disabled ? '#' : url}
    className={disabled ? 'disabled' : ''}
    onClick={e => {
      e.preventDefault()
      if (!disabled) {
        window.open(url)
      }
    }}
  >
    {children}
  </a>
)
