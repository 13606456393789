import * as c from '../constants/StudentConstants'

const initialState = {
  fetching: false,
  fetched: false,
  error: null,
  exams: [],
  studentMap: {},
  courseMap: {},
  sectionMap: {},
  teacherMap: {},
  accommodationCategoryMap: {},
  update: {
    id: null,
    type: null,
    updating: false,
    error: null,
  },
  selected: [],
  multi: {
    type: null,
    updating: false,
    updated: false,
    error: null,
  },
}

const partitionExams = (state, exams = [], key = 'studentId') => {
  return state.exams.reduce(
    (acc, exam) => {
      const index = exams.find(pe => pe[key] === exam[key]) ? 1 : 0
      acc[index].push(exam)
      return acc
    },
    [[], []]
  )
}

export default function reducer(state = initialState, { type, payload = {} }) {
  switch (type) {
    // pending
    case c.FETCH_ORG_STUDENTS_PENDING:
    case c.FETCH_STUDENT_DETAILS_PENDING:
      return { ...state, fetching: true, fetched: false, error: null }

    // fulfilled
    case c.FETCH_ORG_STUDENTS_FULFILLED:
    case c.FETCH_STUDENT_DETAILS_FULFILLED:
      return { ...state, fetching: false, error: null, fetched: true, ...payload }

    // rejected
    case c.FETCH_ORG_STUDENTS_REJECTED:
    case c.FETCH_STUDENT_DETAILS_REJECTED:
      return { ...state, fetching: false, fetched: true, error: payload }

    case c.RESET_STUDENT_DATA:
      return initialState

    // reset update
    case c.RESET_UPDATE_TYPE:
      return { ...state, update: initialState.update }

    // student update pending
    case c.UPDATE_ORG_STUDENT_PENDING:
      return {
        ...state,
        update: {
          type: payload.type,
          id: payload.id,
          updating: true,
          error: null,
        },
      }

    // student update error
    case c.UPDATE_ORG_STUDENT_REJECTED:
      return {
        ...state,
        update: {
          ...state.update,
          updating: false,
          error: payload.error,
        },
      }

    case c.UPDATE_STUDENT_EXAM_INTENT_FULFILLED:
    case c.UPDATE_STUDENT_EXAM_INTENT_UNUSED_FULFILLED:
    case c.UPDATE_STUDENT_EXAM_TESTWINDOW_FULFILLED:
    case c.UPDATE_STUDENT_EXAM_TESTWINDOW_INTENT_FULFILLED:
    case c.UPDATE_STUDENT_EXAM_SSDMATERIALS_FULFILLED:
    case c.UPDATE_STUDENT_EXAM_DIGITAL_ACCOMMODATIONS_FULFILLED:
    case c.UPDATE_STUDENT_FEESTATUS_FULFILLED: {
      const { exams = [], studentMap = {} } = payload
      const [untouchedExams] = partitionExams(state, exams)

      return {
        ...state,
        update: initialState.update,
        exams: [...untouchedExams, ...exams],
        studentMap: {
          ...state.studentMap,
          ...studentMap,
        },
      }
    }

    case c.UPDATE_STUDENT_ENROLLMENT_SUBSIDIES_FULFILLED: {
      const { enrollmentIds, action, value } = payload
      // search through exams and for each matching enrollment,
      // update exclude or include prop with value
      const updatedExams = state.exams.map(exam => {
        if (enrollmentIds.includes(exam.enrollmentId)) {
          exam[`${action.toLowerCase()}Subsidy`] = value
          if (
            action === c.INCLUDE_SUBSIDIES &&
            value === true &&
            exam[c.EXCLUDE_FROM_SUBSIDIES] !== undefined
          ) {
            exam[c.EXCLUDE_FROM_SUBSIDIES] = false
          }
          if (
            action === c.EXCLUDE_SUBSIDIES &&
            value === true &&
            exam[c.EXCLUDE_FROM_SUBSIDIES] !== undefined
          ) {
            exam[c.INCLUDE_IN_SUBSIDIES] = false
          }
        }
        return exam
      })
      return {
        ...state,
        update: initialState.update,
        exams: updatedExams,
      }
    }

    case c.UPDATE_STUDENT_REGISTRATION_SUBSIDY_FULFILLED: {
      const { exams = [], studentMap = {}, subsidies = {} } = payload
      const [untouchedExams] = partitionExams(state, exams)

      return {
        ...state,
        update: initialState.update,
        exams: [...untouchedExams, ...exams],
        studentMap: {
          ...state.studentMap,
          ...studentMap,
        },
        subsidies,
      }
    }

    case c.TOGGLE_STUDENT_ACTION_MENU: {
      const updatedExams = state.exams.map(exam => {
        if (exam.enrollmentId === payload.enrollmentId) {
          return { ...exam, menuOpen: payload.menuOpen }
        }
        return exam
      })
      return { ...state, exams: updatedExams }
    }

    case c.CHANGE_TESTING_WITH_ACCOMMODATIONS_FULFILLED:
    case c.TRANSFER_OUT_STUDENT_WITHPENDING_FULFILLED:
    case c.DROP_STUDENT_ENROLLMENT_FULFILLED:
    case c.CHANGE_STUDENT_SECTION_FULFILLED: {
      const { sectionMap = {}, teacherMap = {}, exams = [] } = payload
      const [untouchedExams] = partitionExams(state, exams)

      return {
        ...state,
        update: initialState.update,
        exams: [...untouchedExams, ...exams],
        sectionMap: { ...state.sectionMap, ...sectionMap },
        teacherMap: { ...state.teacherMap, ...teacherMap },
      }
    }

    case c.DROP_STUDENT_FULFILLED:
    case c.TRANSFER_OUT_STUDENT_FULFILLED: {
      const { exam } = payload
      const [untouchedExams] = partitionExams(state, [exam])
      const { [exam.studentId]: studentToRemove, ...studentMap } = state.studentMap

      return {
        ...state,
        update: initialState.update,
        exams: untouchedExams,
        studentMap,
      }
    }

    case c.STUDENT_MULTISELECT_CHECK:
      return {
        ...state,
        selected: [
          ...state.selected,
          ...payload.filter(
            payloadId => !state.selected.find(selectedId => selectedId === payloadId)
          ),
        ],
      }
    case c.STUDENT_MULTISELECT_UNCHECK:
      return {
        ...state,
        selected: payload ? state.selected.filter(selectedId => selectedId !== payload) : [],
      }
    case c.UPDATE_STUDENT_MULTISELECT_PENDING:
      return { ...state, multi: { ...state.multi, updating: true, updated: false } }
    case c.UPDATE_STUDENT_MULTISELECT_REJECTED:
      return {
        ...state,
        multi: { ...state.multi, updating: false, error: payload.error },
      }
    case c.UPDATE_STUDENT_MULTISELECT_FULFILLED:
      return {
        ...state,
        multi: { ...state.multi, updating: false, error: null, updated: true },
      }
    case c.UPDATE_STUDENT_MULTISELECT_RESET:
      return { ...state, multi: initialState.multi }

    default:
      return state
  }
}
