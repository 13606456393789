import ExceptionExamInstructions from './ExceptionExamInstructions'
import IncidentReportSubmission from './IncidentReportSubmission'
import { fetchCourseTypes } from '../../actions/course'
import { getSelectedEducationPeriod } from '../../selectors/settings'
import CoordinatorLink from './CoordinatorLink'
import DasPortalLink from './DasPortalLink'
import DigitalPortfolioLink from './DigitalPortfolioLink'
import TestDayToolkitLink from './TestDayToolKitLink'

const AP_COORDINATOR_RESOURCE_LIBRARY_URL =
  'https://apcentral.collegeboard.org/about-ap/ap-coordinators/resource-library'
const AP_COORDINATOR_EXPERIENCE_URL =
  'https://apcentral.collegeboard.org/professional-learning/learning-opportunities-ap-coordinators'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId },
    },
    availableCourseTypes: { hasAP, hasPreAP },
  } = state

  return {
    educationPeriodCd: getSelectedEducationPeriod(state),
    orgId: selectedOrgId,
    hasAP,
    hasPreAP,
  }
}

const CoordinatorDashboard = ({
  isCSR,
  educationPeriodCd,
  orgId,
  hasAP = false,
  hasPreAP = false,
  fetchCourseTypes,
}) => {
  let title = ''
  const titles = []
  if (hasAP) {
    titles.push('AP')
  }
  if (hasPreAP) {
    titles.push('Pre-AP')
  }
  title = titles.join(' & ')

  useEffect(() => {
    if (orgId && educationPeriodCd) {
      fetchCourseTypes(orgId, educationPeriodCd)
    }
  }, [orgId, educationPeriodCd])

  return (
    <div className="container">
      <CoordinatorLink
        linkName={`${title} Classroom`}
        disabled={isCSR}
        linkUrl={Config.FINE_TUNE_URL}
        description="Access AP coordinator interactive tutorials and AP resources for your teachers and students"
      />
      <ExceptionExamInstructions />
      <IncidentReportSubmission />
      <DigitalPortfolioLink />
      <DasPortalLink />
      <TestDayToolkitLink />
      <CoordinatorLink
        linkName="AP Coordinator Resource Library"
        disabled={isCSR}
        linkUrl={AP_COORDINATOR_RESOURCE_LIBRARY_URL}
      />
      <CoordinatorLink
        linkName="AP Coordinator Experience"
        disabled={isCSR}
        linkUrl={AP_COORDINATOR_EXPERIENCE_URL}
      />
      <CoordinatorLink
        linkName="Back to MyAP"
        disabled={isCSR}
        linkUrl={Config.MYAP_URL.prof}
        newWindow={false}
      />
    </div>
  )
}

export default connect(mapStateToProps, { fetchCourseTypes })(CoordinatorDashboard)
