import { isDateBefore, isDateSameOrAfter } from '@myap/ui-library/esm/date'
import { HelpIcon, Loader } from '.'
import {
  STUDENT_ENROLLMENT_STATUS,
  STUDENT_ENROLLMENT_STATUS_NO_OTHER,
} from '../../constants/StudentConstants'
import { openModal } from '../../actions/app'
import {
  isDigitalExamInCutoffPeriod,
  getExamDay,
  getExamWindowsForCourse,
} from '../../selectors/examWindows'
import { isEmpty } from '../../utils/common'

const MENU_NAME = 'decisionOptions'

const mapStateToProps = (state, ownProps) => {
  const {
    user: {
      data: { isLevelOne },
    },
    studentsByOrg: { courseMap, exams },
  } = state
  const {
    exam: { examConflictCourseName, examIntent, testCd, testWindow, studentId },
    exam,
  } = ownProps
  const isPastDigitalDeadline = isDigitalExamInCutoffPeriod(state, { testCd, testWindow })
  const isBeforeExamDay = !isEmpty(testWindow)
    ? isDateBefore(new Date(), getExamDay(state, testCd, testWindow))
    : false
  const isConflictingCoursePastExamDate = () => {
    // Abort this process if this isn't a conflicting course
    if (!isEmpty(examConflictCourseName)) {
      let conflictingCourseTestCd
      const courseKeys = Object.keys(courseMap)
      courseKeys.forEach(c => {
        if (courseMap[c].name === examConflictCourseName) {
          conflictingCourseTestCd = courseMap[c].testCd
        }
      })
      const conflictingEnrollment =
        conflictingCourseTestCd &&
        exams.find(e => e.testCd === conflictingCourseTestCd && e.studentId === studentId)
      const examWindowsForConflictingCourse =
        conflictingEnrollment && getExamWindowsForCourse(state, conflictingCourseTestCd)
      return (
        examWindowsForConflictingCourse &&
        conflictingEnrollment &&
        isDateSameOrAfter(
          new Date(),
          examWindowsForConflictingCourse[conflictingEnrollment.testWindow]?.examDateTime
        )
      )
    }
    return false
  }

  return {
    hasConflictCourse: !isEmpty(examConflictCourseName),
    showExamConflictTrigger:
      examConflictCourseName &&
      !isPastDigitalDeadline &&
      isBeforeExamDay &&
      !isConflictingCoursePastExamDate(),
    showNoOtherTrigger: examIntent === STUDENT_ENROLLMENT_STATUS_NO_OTHER,
    hideValue:
      !exam.examIntent || (isLevelOne && examIntent !== STUDENT_ENROLLMENT_STATUS_NO_OTHER),
  }
}

export class ExamIntentDropdown extends Component {
  constructor(props) {
    super(props)
    this.examDecisionDropdown = this.examDecisionDropdown.bind(this)
    this.openConflictModal = this.openConflictModal.bind(this)
    this.openNoOtherModal = this.openNoOtherModal.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  getModalTrigger() {
    const { availableExamIntentOptions, showExamConflictTrigger, showNoOtherTrigger, innerRef } =
      this.props
    const conflictClickEvent =
      showExamConflictTrigger && availableExamIntentOptions ? this.openConflictModal : null
    const noOtherClickEvent = showNoOtherTrigger ? this.openNoOtherModal : null
    const clickEvent = conflictClickEvent || noOtherClickEvent

    if (clickEvent)
      return (
        <button
          type="button"
          style={{ padding: '0 0 0 3px', lineHeight: 'normal', verticalAlign: 'middle' }}
          className="btn-link"
          ref={innerRef}
          onClick={clickEvent}
        >
          <HelpIcon srText="Why Can't This Be Changed?" />
        </button>
      )

    return null
  }

  getRenderedContent() {
    const {
      dropdownOptions = {},
      exam: { examIntent, reasonInactive },
      showExamConflictTrigger,
      showNoOtherTrigger,
      DropdownComponent,
      isEditable = true,
      hasConflictCourse,
    } = this.props
    const showModalTrigger = showExamConflictTrigger || showNoOtherTrigger
    const examIntentValue = STUDENT_ENROLLMENT_STATUS[examIntent]
    const editable = isEditable && !showModalTrigger && !hasConflictCourse

    if (reasonInactive)
      return (
        <div>
          <em>{examIntentValue}</em>
        </div>
      )

    if (editable) return <DropdownComponent {...this.examDecisionDropdown()} {...dropdownOptions} />

    return (
      <div>
        {examIntentValue} {showModalTrigger && this.getModalTrigger()}
      </div>
    )
  }

  handleClick(e) {
    const intentVal = e.target.getAttribute('data-val')
    const { exam, handleUpdateAction } = this.props

    e.preventDefault()

    if (intentVal !== exam.examIntent) handleUpdateAction(intentVal)
  }

  openNoOtherModal() {
    const { openModal, courseName, innerRef } = this.props
    return openModal('TextModal', {
      title: "Why Can't This Be Changed?",
      BodyComponent: () => (
        <p>
          This student is registered to take the <strong>{courseName}</strong> exam at another
          school. A student may not take the same AP exam more than once in the same year.
        </p>
      ),
      modalCloseFocusElem: innerRef.current,
    })
  }

  openConflictModal() {
    const { conflictModal } = this.props
    return conflictModal && conflictModal()
  }

  examDecisionDropdown() {
    const { availableExamIntentOptions, exam } = this.props
    const examIntentOrder = Object.keys(STUDENT_ENROLLMENT_STATUS)
    const options = availableExamIntentOptions.sort(
      (a, b) => examIntentOrder.indexOf(a) - examIntentOrder.indexOf(b)
    )

    return {
      label: STUDENT_ENROLLMENT_STATUS[exam.examIntent],
      id: (this.menuId = `${MENU_NAME}${exam.examId}`),
      menuItems: options.map(intent => ({
        label: STUDENT_ENROLLMENT_STATUS[intent],
        val: intent,
        clickEvent: this.handleClick,
      })),
    }
  }

  render() {
    const {
      exam: { examId },
      showLabel = false,
      hideValue,
      updating,
      innerRef,
    } = this.props

    if (updating) return <Loader size="sm" style={{ margin: '3px auto' }} />

    if (!hideValue)
      return (
        <div ref={innerRef}>
          {showLabel ? <h5 id={`examIntent_${examId}`}>Order Exam?</h5> : null}
          {this.getRenderedContent()}
        </div>
      )

    return null
  }
}

const ConnectedExamIntentDropdown = connect(mapStateToProps, { openModal })(ExamIntentDropdown)
export default React.forwardRef((props, ref) => (
  <ConnectedExamIntentDropdown innerRef={ref} {...props} />
))
