import { useParams } from 'react-router-dom'
import { fetchCourses } from '../../actions/course'
import { Loader, Error } from '../../components/common'
import SectionDetails from '../../components/section/teacher/SectionDetails'
import {
  setNavLink,
  unsetNavLink,
  suppressSchoolMenu,
  unSuppressSchoolMenu,
} from '../../actions/nav'

const mapStateToProps = state => ({
  selectedOrgId: state.user.data.selectedOrgId,
  ...state.courses,
  educationPeriod: state.settingsEducationPeriod.selectedEducationPeriod,
})

const SectionDetailsPage = ({
  fetched,
  fetching,
  error,
  selectedOrgId,
  educationPeriod,
  fetchCourses,
  setNavLink,
  unsetNavLink,
  suppressSchoolMenu,
  unSuppressSchoolMenu,
}) => {
  const { sectionId } = useParams()

  useEffect(() => {
    setNavLink('/courses', 'Back to Course List')
    suppressSchoolMenu()
    if (!fetched && !fetching) {
      fetchCourses(selectedOrgId, educationPeriod)
    }

    return () => {
      unsetNavLink()
      unSuppressSchoolMenu()
    }
  }, [])

  if (error)
    return (
      <div className="container" style={{ marginTop: '20px' }}>
        <Error
          title="Error Retrieving Data"
          message="An error occurred retrieving courses. Please try again later."
        />
      </div>
    )

  if (fetching) return <Loader />

  return (
    <CSSTransition
      classNames="animate-fade"
      in={true}
      timeout={500}
      appear={true}
      enter={false}
      exit={false}
    >
      <SectionDetails sectionId={sectionId} />
    </CSSTransition>
  )
}

export default connect(
  mapStateToProps,
  {
    fetchCourses,
    setNavLink,
    unsetNavLink,
    suppressSchoolMenu,
    unSuppressSchoolMenu,
  }
)(SectionDetailsPage)
