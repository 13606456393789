import React from 'react'
/**
 * Common Utils
 */

const _isObjectEmpty = obj => {
  // Could probably be simplified to: return Object.keys(obj).length === 0
  for (var prop in obj) {
    if (obj[prop] !== '') return false
  }
  return true
}

export const getCountSum = (items, key) => {
  return items.reduce((a, b) => {
    return a + b[key]
  }, 0)
}

export const truncateLongNames = (name = '', limit, withSpaces) => {
  const nameArr = withSpaces ? name.split(' ') : [name]
  return nameArr
    .map(thisName => {
      if (!limit || thisName.length < limit) return thisName

      return APRICOT.utils.textTruncate(thisName, limit, 'last', '...')
    })
    .join(' ')
}

export const truncateLongNamesWithSpaces = (name = '', limit) => {
  if (!limit || name.length < limit) return name

  return APRICOT.utils.textTruncate(name, limit, 'last', '...')
}

export const isEmpty = val => {
  if (val === null || val === undefined) return true

  return typeof val === 'string'
    ? !val.trim().length
    : typeof val === 'object'
    ? _isObjectEmpty(val)
    : typeof val === 'boolean' || (typeof val === 'number' && val > -1)
    ? false
    : true
}

export const getValsFromObjectArray = (obj, key) => {
  if (_isObjectEmpty(obj)) return null

  const valArray = []
  obj.forEach(item => {
    valArray.push(item[key])
  })

  return valArray
}

export const removeAttributesFromObject = (obj, attrs) => {
  const updated = { ...obj }
  attrs.forEach(a => {
    delete updated[a]
  })
  return updated
}

export const plural = (len, word, format = 's') => `${word}${len === 1 ? '' : format}`

export const convertArrayToString = (arr, joiner, lastJoiner) =>
  lastJoiner
    ? [arr.slice(0, -1).join(joiner), arr.slice(-1)[0]].join(arr.length < 2 ? '' : lastJoiner)
    : arr.join(joiner)

export const convertToArray = data => (data ? (Array.isArray(data) ? data : [data]) : [])

// Works like array.filter()
// NOTE: assumes object keys are meant to be integers (as in the normalized objects in the store)
// originalObject: the object to be filtered
// filterBy: anonymous function by which originalObject will be filtered
//   ex: (key) => parseInt(key) !== payload.enrollmentId
//   Remember that Object.keys() will convert the integer keys of an object to strings, so make sure your
//   type comparisons are valid.
export const filterObject = (originalObject, filterBy) => {
  const filteredArr = Object.keys(originalObject).filter(filterBy)
  const reducedArr = filteredArr.reduce((accObj, key) => {
    accObj[key] = originalObject[key]
    return accObj
  }, {})
  return reducedArr
}

export const getWindowOffsetTop = () =>
  (window.pageYOffset || document.documentElement.scrollTop) -
  (document.documentElement.clientTop || 0)
export const getWindowOffsetLeft = () =>
  (window.pageXOffset || document.documentElement.scrollLeft) -
  (document.documentElement.clientLeft || 0)
export const getViewportHeight = () =>
  Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
export const getElementOffsetTop = elem =>
  (elem.pageYOffset || elem.scrollTop) - (elem.clientTop || 0)
export const getElementOffsetLeft = elem =>
  (elem.pageXOffset || elem.scrollLeft) - (elem.clientLeft || 0)
export const getElementViewportHeight = elem => Math.max(elem.clientHeight, elem.innerHeight || 0)
export const getAllFocusableElementsByContainer = container =>
  container.querySelectorAll(
    'button:not([disabled]):not(.disabled), [href]:not(.disabled), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"])'
  )

export const isObject = obj => {
  return obj === Object(obj)
}

export const queryParamsToObj = search => {
  const searchString = search.startsWith('?') ? search.substring(1) : search
  return searchString.split('&').reduce((obj, str) => {
    const [key, value] = str.split('=')
    let existing = obj[key]

    if (!key || !value) return obj

    if (existing) {
      existing = Array.isArray(existing) ? existing : [existing]
      return { ...obj, [key]: [...existing, value] }
    }
    return { ...obj, [key]: value }
  }, {})
}

export const getNestedAttr = (obj, attrKeyString = '', returnAsObj = false) => {
  const keyArr = attrKeyString.split('.')
  const value = keyArr.reduce((valueToReturn, key) => {
    return (valueToReturn && valueToReturn[key]) || null
  }, obj)

  return returnAsObj ? { [keyArr[keyArr.length - 1]]: value } : value
}

export const decodeEntities = s => {
  const el = document.createElement('p')
  el.innerHTML = s
  const str = el.textContent
  el.remove()
  return str
}

export const convertReactElementToString = el => {
  if (React.isValidElement(el)) {
    return el?.props?.children
  }
  return decodeEntities(el)
}

export const replaceSpaceWithUnderscore = stringToProcess => stringToProcess.replace(/( )/g, '_')
