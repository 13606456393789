import { DropdownPortal, TestDateIcon, Loader } from '../../../common'
import {
  STUDENT_ENROLLMENT_STATUS_NO_OTHER,
  STUDENT_ENROLLMENT_STATUS_YES,
  SEE_SUBMISSION_DEADLINE_MSG,
  UPDATE_TYPE_DATE,
  UPDATE_TYPE_DATE_INTENT,
} from '../../../../constants/StudentConstants'
import {
  DIGITAL_EXAM,
  TEST_LOCATION_HOME,
  TEST_LOCATION_SCHOOL,
} from '../../../../constants/SettingsConstants'
import TestDate from '../updatemodals/TestDate'
import { openModal } from '../../../../actions/app'
import { isUnusedExam } from '../../../../selectors/section'
import { canChangeExamWindow, canChangeReasonAndLocation } from '../../../../selectors/student'
import { isFeatureEnabled } from '../../../../selectors/features'
import {
  getExamWindowsForCourse,
  getSortedExamWindowsForCourse,
} from '../../../../selectors/examWindows'
import { isAdministeringDigitalExams } from '../../../../selectors/settings'
import { TEST_LOCATION_FILTER_VALUES } from '../../../../constants/FilterConstants'
import DisplayExamDateTime from '../../../common/DisplayExamDateTime'

const MENU_NAME = 'testDateDropdown'

const mapStateToProps = (state, { exam }) => {
  const {
    studentsByOrg,
    settingsPricing: { lateDayAdminPricing: lateReasons = [] },
    user: {
      data: { isLevelOne, isPreAPOnly },
    },
  } = state
  const {
    update: { type, id, updating },
  } = studentsByOrg
  const {
    testCd,
    testWindow,
    reasonInactive,
    testWindows,
    examIntent,
    examId,
    hasConflict = false,
  } = exam
  const examWindowData = getExamWindowsForCourse(state, testCd)
  const { examDateTime = '', noEndOfCourseExam = true } = examWindowData[testWindow] || {}
  const sortedExamWindows = getSortedExamWindowsForCourse(state, testCd, testWindows)
  const isEnabled = isFeatureEnabled(state, 'CHANGE_EXAM_DATE')

  return {
    examWindowData,
    sortedExamWindows,
    noEndOfCourseExam,
    examDateTime,
    hasConflict,
    canChangeExamDate: isEnabled && canChangeExamWindow(exam) && sortedExamWindows.length > 1,
    isEditable: isEnabled && canChangeReasonAndLocation(exam),
    lateReasons,
    hideValue:
      !testWindow ||
      reasonInactive ||
      (isLevelOne && examIntent !== STUDENT_ENROLLMENT_STATUS_NO_OTHER) ||
      isPreAPOnly,
    updating:
      (type === UPDATE_TYPE_DATE || type === UPDATE_TYPE_DATE_INTENT) && id === examId && updating,
    administeringDigitalExams: isAdministeringDigitalExams(state),
  }
}

const MAX_WIDTH = 245
const ICON_WIDTH = 21 // width: 16, margin: 5

const TestDateMenu = ({
  exam,
  exam: { testWindow, testLocation, examId, examIntent, examFormat, lateReasonCode, examStarted },
  examWindowData,
  sortedExamWindows,
  dropdownOptions,
  hasConflict,
  showLateReason,
  showTestLocation,
  noEndOfCourseExam,
  examDateTime,
  canChangeExamDate,
  isEditable,
  showLabel,
  lateReasons,
  hideValue,
  updating,
  openModal,
}) => {
  const isHomeTestLocation = testLocation === TEST_LOCATION_HOME
  const menuRef = useRef(null)
  const menuId = MENU_NAME + examId
  const wrapperStyles = {
    position: 'relative',
    whiteSpace: 'nowrap',
    paddingTop: showLateReason ? 0 : 11,
    paddingRight: showLabel ? 0 : 11,
    paddingBottom: showLateReason ? 0 : 11,
    paddingLeft: hasConflict ? 8 : showLabel ? 0 : 11,
    borderLeft: hasConflict ? '3px solid rgb(229, 114, 0)' : 'none',
  }
  const iconAdjustment = (hasConflict ? ICON_WIDTH : 0) + (isHomeTestLocation ? ICON_WIDTH : 0)
  const widthAdjustment =
    MAX_WIDTH - wrapperStyles.paddingLeft - wrapperStyles.paddingRight - iconAdjustment

  const updateTestDate = (val, makesOrderChange = true) => {
    openModal('UpdateStudentExamModal', {
      Component: TestDate,
      exam: val
        ? {
            ...exam,
            examIntent: isUnusedExam(exam) ? STUDENT_ENROLLMENT_STATUS_YES : examIntent,
            testWindow: val || exam.testWindow,
            previousTestDate: examDateTime,
            previousTestWindow: testWindow,
            previousLateReasonCode: lateReasonCode,
            previousTestLocation: testLocation,
            previousExamStarted: examStarted,
          }
        : exam,
      type: UPDATE_TYPE_DATE,
      modalCloseFocusElem: document.getElementById(menuId),
      makesOrderChange,
    })
  }

  const handleClick = e => {
    const val = e.currentTarget.getAttribute('data-val')

    e.preventDefault()

    if (exam.testWindow !== val) {
      updateTestDate(val)
    }
  }

  const DropdownLabel = () => {
    return (
      <DisplayExamDateTime course={examWindowData[testWindow]} iconAdjustment={iconAdjustment} />
    )
  }

  const createDropdownOptions = () => {
    return {
      label: <DropdownLabel />,
      applyStyles: { display: 'inline-block', width: `${widthAdjustment}px` },
      id: menuId,
      hasHTMLMenuItems: true,
      ariaDescribedBy: hasConflict ? `conflict${menuId}` : null,
      menuItems: sortedExamWindows.map(w => ({
        label: <DisplayExamDateTime course={w} iconAdjustment={iconAdjustment} />,
        val: w.adminWindow,
        clickEvent: handleClick,
      })),
    }
  }

  const getDropdownComponent = () => {
    const options = createDropdownOptions()
    return <DropdownPortal {...options} {...dropdownOptions} />
  }

  const lateReasonDescription =
    showLateReason &&
    lateReasonCode &&
    lateReasons.find(reason => reason.cd === lateReasonCode)?.description

  if (updating) return <Loader size="sm" style={{ margin: '15px auto' }} />

  if (!hideValue)
    return (
      <>
        {showLabel && <h5 id={`testDate_${examId}`}>Exam Date</h5>}
        <div style={wrapperStyles} ref={menuRef}>
          {noEndOfCourseExam ? (
            SEE_SUBMISSION_DEADLINE_MSG
          ) : (
            <>
              <TestDateIcon type={hasConflict ? 'C' : null} id={menuId} />
              <TestDateIcon type={isHomeTestLocation ? 'H' : null} id={menuId} />
              {canChangeExamDate ? (
                getDropdownComponent()
              ) : (
                <div style={{ marginRight: 10, display: 'inline-block' }}>
                  <DropdownLabel />
                </div>
              )}
              {lateReasonDescription ? (
                <div
                  style={{
                    fontSize: '1em',
                    whiteSpace: 'normal',
                    marginLeft: 0,
                  }}
                >
                  {lateReasonDescription}{' '}
                  {isEditable && (
                    <button
                      type="button"
                      className="btn-link"
                      style={{ padding: 0 }}
                      onClick={() => updateTestDate(testWindow, false)}
                    >
                      edit
                    </button>
                  )}
                </div>
              ) : null}
              {showTestLocation && testLocation && examFormat === DIGITAL_EXAM ? (
                <div>
                  Testing Location:{' '}
                  {isHomeTestLocation
                    ? TEST_LOCATION_FILTER_VALUES[TEST_LOCATION_HOME]
                    : TEST_LOCATION_FILTER_VALUES[TEST_LOCATION_SCHOOL]}
                </div>
              ) : null}
            </>
          )}
        </div>
      </>
    )

  return null
}

export default connect(mapStateToProps, { openModal })(TestDateMenu)
