import { openModal } from '../../../actions/app'

import Tooltip from '../../../components/common/Tooltip'

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ openModal }, dispatch)
}

export class DeleteSectionButton extends Component {
  constructor(props) {
    super(props)
    this.deleteSectionModal = this.deleteSectionModal.bind(this)
  }

  deleteSectionModal(id) {
    const {
      section: { sectionId },
      openModal,
      onCloseAction,
    } = this.props

    openModal('SectionDeleteModal', {
      sectionId,
      modalCloseFocusElem: this.modalCloseFocusElem,
      onCloseAction,
    })
  }

  render() {
    let {
      section: { enrollmentCount, sectionName },
    } = this.props
    let tipTitle =
      'You cannot delete a section when students are enrolled. To remove this section, first drop the students.'

    return (
      <div ref="deleteButton" style={{ display: 'inline-block' }}>
        {enrollmentCount ? (
          <Tooltip
            title={tipTitle}
            placement="top"
            container="body"
            label="More information: Delete Section"
            isDisabled={true}
            style={{ float: 'none' }}
          >
            <div className="cb-small-font-size" style={{ color: 'rgb(150, 150, 150)' }}>
              <span aria-hidden="true" className="cb-glyph cb-x-mark" />
            </div>
          </Tooltip>
        ) : (
          <button
            type="button"
            style={{ padding: 0, border: 0 }}
            aria-label={`Delete section ${sectionName}`}
            className="btn-link cb-small-font-size"
            ref={node => (this.modalCloseFocusElem = node)}
            onClick={this.deleteSectionModal.bind(this)}
          >
            <span aria-hidden="true" className="cb-glyph cb-x-mark" data-alt="Delete Section" />
          </button>
        )}
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(DeleteSectionButton)
