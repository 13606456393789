import { setNavLink, unsetNavLink } from '../../actions/nav'
import { fetchShipmentsSummary } from '../../actions/shipments'
import { Error, Loader } from '../../components/common'
import { getExamWindowRefData } from '../../selectors/examWindows'
import { NAV_LINKS } from '../../constants/NavConstants'
import Shipments from '../../components/shipment/Shipments'
import { isEmpty } from '../../utils/common'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId: orgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod: educationPeriodCd },
    shipments: { fetching, error, suborders },
  } = state
  return {
    orgId,
    educationPeriodCd,
    fetching,
    error,
    isSuborderProcessing: Object.values(suborders).some(suborder => suborder.length > 0),
    examWindowRefData: getExamWindowRefData(state),
  }
}

const ShipmentsPage = ({
  orgId,
  educationPeriodCd,
  fetching,
  error,
  isSuborderProcessing,
  examWindowRefData,
  fetchShipmentsSummary,
  setNavLink,
  unsetNavLink,
}) => {
  useEffect(() => {
    if (orgId && educationPeriodCd && !isEmpty(examWindowRefData)) {
      fetchShipmentsSummary({ orgId, educationPeriodCd, examWindowRefData })
    }
  }, [orgId, educationPeriodCd, examWindowRefData])

  useEffect(() => {
    setNavLink(NAV_LINKS.orders, 'Back to order summary')
    return () => unsetNavLink()
  }, [])

  if (error) {
    return (
      <div className="container" style={{ marginTop: 20 }}>
        <Error title="Error Retrieving Data" message={error} />
      </div>
    )
  }

  if (fetching) {
    return <Loader />
  }

  if (isSuborderProcessing) {
    return (
      <CSSTransition
        classNames="animate-fade"
        in={true}
        timeout={500}
        appear={true}
        enter={false}
        exit={false}
      >
        <Shipments />
      </CSSTransition>
    )
  }

  return (
    <div className="container" style={{ marginTop: 20 }}>
      <p>No data available</p>
    </div>
  )
}

export default connect(mapStateToProps, {
  fetchShipmentsSummary,
  setNavLink,
  unsetNavLink,
})(ShipmentsPage)
