import { Children, cloneElement } from 'react'
import PropTypes from 'prop-types'
import { SSD_MATERIALS_FIELDS } from '../../constants/StudentConstants'
import { isEmpty } from '../../utils/common'

const mapStateToProps = (state, ownProps) => {
  const { selectedSSDMaterials } = ownProps
  const { settingsSSDMaterials: { metaData }} = state

  return {
    selectedSSDMaterials,
    metaData
  }
}

// The generated list of SSD Materials will be added within the provided children
// if there is ONLY A SINGLE child element. If more than one child elements are passed
// in children, the SSD list will not be added within.
// If you pass in a function or a string, this will probably barf all over your face. Don't do that.
export const SSDMaterialsList = connect(mapStateToProps)(({ selectedSSDMaterials, metaData, children }) => {
  let output = null
  if (!isEmpty(selectedSSDMaterials) && metaData) {
    // if the list of SSD Materials does not already include LBAS and does include
    // 14point, 20point, or custom font size, then add Large Block Answer Sheet to list
    if (selectedSSDMaterials.every(item => item.ssdCode !== SSD_MATERIALS_FIELDS.largeBlockAnswerSheet.value)
        && (selectedSSDMaterials.some(item => (
           item.ssdCode
           && (item.ssdCode === SSD_MATERIALS_FIELDS['14point'].value
               || item.ssdCode === SSD_MATERIALS_FIELDS['20point'].value
               || item.ssdCode === SSD_MATERIALS_FIELDS.customFontSize.value))))) {
      selectedSSDMaterials.push({ ssdCode: SSD_MATERIALS_FIELDS.largeBlockAnswerSheet.value, comment: null })
    }
    // if list of SSD Materials does not already include Reader Copy and does include
    // Braille, then add Reader Copy to list
    if (selectedSSDMaterials.every(item => item.ssdCode !== SSD_MATERIALS_FIELDS.readerCopy.value)
        && (selectedSSDMaterials.some(item => (
           item.ssdCode
           && (item.ssdCode === SSD_MATERIALS_FIELDS.braille.value))))) {
      selectedSSDMaterials.push({ ssdCode: SSD_MATERIALS_FIELDS.readerCopy.value, comment: null })
    }
    output = selectedSSDMaterials.map(item => {
      let itemOutput = null
      if (item.ssdCode) {
        itemOutput = metaData[item.ssdCode].description
        if (item.comment) {
          itemOutput += ' ('
          if (item.ssdCode === SSD_MATERIALS_FIELDS.customFontSize.value) {
            itemOutput += `${item.comment} point`
          } else {
            itemOutput += item.comment
          }
          itemOutput += ')'
        }
      }
      return itemOutput
    }).join(', ')
  }
  if (children && Children.count(children) === 1) {
    output = cloneElement(children, null, output)
  }
  return output
})

SSDMaterialsList.displayName = 'SSDMaterialsList'

SSDMaterialsList.propTypes = {
  children: PropTypes.element
//   children: (props, propName, componentName) => {
//     let error = null
//     let prop = props[propName]
//     // const types = [ 'function', 'string' ]
//
//     if (Children.count(prop) > 1 || prop.type == PropTypes.func || prop.type == PropTypes.string) {
//       error = new Error(`${componentName} should have zero or one child and must not be a function or a string.`)
//     }
//     return error
//   }
}
