import Tooltip from '../../common/Tooltip'
import { openModal } from '../../../actions/app'

const mapStateToProps = state => ({})

const RevokeAccessButton = connect(mapStateToProps, { openModal })(
  ({ grant, grant: { canRevoke }, openModal }) => {
    const revokeButtonRef = useRef()
    const cannotRevokeAccessExplanation =
      "You cannot delete access for a teacher who has class sections with students enrolled. To delete access, first either remove the teacher from the class sections or drop the students from this teacher's sections."

    if (canRevoke)
      return (
        <button
          type="button"
          className="btn-link"
          ref={revokeButtonRef}
          onClick={() =>
            openModal('RevokeTeacherAccessConfirmModal', {
              grant,
              modalCloseFocusElem: revokeButtonRef.current,
            })
          }
        >
          Remove
        </button>
      )

    return (
      <>
        <span style={{ fontSize: '14px' }}>Remove</span>{' '}
        <div style={{ display: 'inline-block' }}>
          <Tooltip
            title={cannotRevokeAccessExplanation}
            placement="top"
            container="body"
            label="More information: Teacher revoke access"
          >
            <i
              aria-hidden="true"
              className="cb-glyph cb-glyph-xs cb-glyph-circular cb-exclamation"
              style={{ display: 'inline-block', fontSize: '8px' }}
              data-alt={cannotRevokeAccessExplanation}
            />
          </Tooltip>
        </div>
      </>
    )
  }
)

const GrantedAccessTableRow = ({ grant, num }) => {
  const { firstName, lastName, email = '', courseName } = grant
  const fullName = `${firstName} ${lastName}`

  return (
    <tr>
      <td
        style={{
          textAlign: 'center',
          verticalAlign: 'middle',
          ...(num === 0 && { borderTopWidth: 0 }),
        }}
      >
        {fullName}
        {email && (
          <div>
            (<a href={`mailto:${email}`}>{email}</a>)
          </div>
        )}
      </td>
      <td
        style={{
          textAlign: 'center',
          verticalAlign: 'middle',
          ...(num === 0 && { borderTopWidth: 0 }),
        }}
      >
        {courseName}
      </td>
      <td
        style={{
          textAlign: 'center',
          verticalAlign: 'middle',
          ...(num === 0 && { borderTopWidth: 0 }),
        }}
      >
        <RevokeAccessButton grant={grant} />
      </td>
    </tr>
  )
}

export default GrantedAccessTableRow
