import { Link } from 'react-router-dom'
import CoordinatorCourseSectionRow from './CoordinatorCourseSectionRow'
import DisplayNumberOfSections from '../common/DisplayNumberOfSections'
import AddSectionButton from '../common/AddSectionButton'
import { getCountSum, replaceSpaceWithUnderscore } from '../../../utils/common'
import { numberFormat } from '../../../utils/numbers'
import { COURSE_FILTER, COURSE_TYPE_FILTER } from '../../../constants/FilterConstants'

import stylesheet from '../../../assets/style/scss/courses-table.scss'
import { getFilters } from '../../../actions/filters'

const mapStateToProps = (state, ownProps) => {
  const { course, isCourseView, showExamDecisions, courseSections } = ownProps
  const filters = getFilters(window.location.search) || {}

  return {
    course,
    isCourseView,
    showExamDecisions,
    courseSections,
    courseIsFiltered: Object.keys(filters).find(f => f !== COURSE_TYPE_FILTER.filter)?.length, // Basically, we don't want to auto-expand if the only filter selected is Course Type
  }
}

class CoordinatorCourseRow extends Component {
  constructor(props) {
    super(props)
    this.state = { sectionsVisible: false }
  }

  componentDidMount() {
    const { courseIsFiltered } = this.props

    if (courseIsFiltered) {
      this.setState({ sectionsVisible: true })
    }
  }

  componentDidUpdate(prevProps) {
    const { courseSections, courseIsFiltered } = this.props

    // If a section was just added to a course, expand the course
    if (courseSections.length > prevProps.courseSections.length && !this.state.sectionsVisible) {
      this.setState({ sectionsVisible: true })
    }
    // Expand the course's sections when initially turning a course filter on
    if (!prevProps.courseIsFiltered && courseIsFiltered && !this.state.sectionsVisible) {
      this.setState({ sectionsVisible: true })
    }
  }

  render() {
    const {
      course: { testCd, courseName },
      courseSections,
      isCourseView,
      showExamDecisions = true,
      headerData = [],
    } = this.props
    const { sectionsVisible } = this.state
    const numSections = courseSections.length
    const totalSectionEnrollments = getCountSum(courseSections, 'maxNumberOfStudents')
    const courseEnrollmentCount = getCountSum(courseSections, 'enrollmentCount')
    const examCount = getCountSum(courseSections, 'examCount')
    const showSections = (sectionsVisible && numSections > 1) || numSections === 1
    const rowSpan = showSections && numSections > 0 ? numSections + 1 : 1

    if (!isCourseView && !numSections) {
      return null
    }

    return (
      <tbody>
        {isCourseView ? (
          <tr className={stylesheet['course-row']}>
            <th
              id={replaceSpaceWithUnderscore(courseName)}
              headers={headerData[0].headerAttributes.id}
              className={stylesheet['course-name']}
              rowSpan={rowSpan}
            >
              <Link to={`/students?${COURSE_FILTER.filter}=${testCd}`} id={`course_${testCd}`}>
                {courseName}
              </Link>
            </th>
            <td headers={headerData[1].headerAttributes.id} className={stylesheet['section-count']}>
              <DisplayNumberOfSections
                courseId={testCd}
                numSections={numSections}
                sectionsVisible={showSections}
                toggleVisibility={() => this.setState({ sectionsVisible: !sectionsVisible })}
              />
              <div>
                <AddSectionButton testCd={testCd} styles={{ margin: '0' }} />
              </div>
            </td>
            <td headers={headerData[2].headerAttributes.id} className={stylesheet.teachers}></td>
            <td headers={headerData[3].headerAttributes.id} className={stylesheet.enrollments}>
              {numberFormat(courseEnrollmentCount)}/{numberFormat(totalSectionEnrollments)}
            </td>
            <td headers={headerData[4].headerAttributes.id} className={stylesheet['exam-decision']}>
              {showExamDecisions
                ? `${numberFormat(examCount)}/${numberFormat(courseEnrollmentCount)}`
                : null}
            </td>
            <td headers={headerData[5].headerAttributes.id} className={stylesheet.actions}>
              <span style={{ width: '70px' }} />
            </td>
          </tr>
        ) : null}

        {showSections || !isCourseView
          ? courseSections.map((section, index) => (
              <CoordinatorCourseSectionRow
                key={index}
                section={section}
                isSectionView={!isCourseView}
                showExamDecisions={showExamDecisions}
                headerData={headerData}
              />
            ))
          : null}
      </tbody>
    )
  }
}

export default connect(mapStateToProps)(CoordinatorCourseRow)
