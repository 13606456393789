import { Link } from 'react-router-dom'
import TeacherCourseSectionRow from './TeacherCourseSectionRow'
import AddSectionButton from '../common/AddSectionButton'
import { sortColumnByKey } from '../../../utils/sort'
import { isAPCourse, isCareerKickstartCourse } from '../../../selectors/course'

import stylesheet from '../../../assets/style/scss/courses-table.scss'

function mapStateToProps(state, ownProps) {
  const { selectedOrgId, isLevelOne } = state.user.data
  const sections = ownProps.course.sections
    .map(id => state.courses.entities.sections[id])
    .filter(s => s)

  return {
    sections: sortColumnByKey(sections, 'sectionName', 'asc'),
    hasSections: sections.length > 0,
    showFullAccessHeader:
      !isLevelOne && (isAPCourse(ownProps.course) || isCareerKickstartCourse(ownProps.course)),
    teacherConfirmEnabled:
      state.settingsExamDecisions[selectedOrgId]?.data?.enableTeacherConfirmation,
  }
}

const FullAccessHeader = ({ teacherConfirmEnabled }) => (
  <thead>
    <tr role="row">
      <th style={{ width: '35%' }} scope="col" role="columnheader">
        Section
      </th>
      <th style={{ width: '15%' }} scope="col" role="columnheader">
        Join Code
      </th>
      <th style={{ width: '10%' }} scope="col" role="columnheader">
        Max Students
      </th>
      <th style={{ width: '10%' }} scope="col" role="columnheader">
        Enrolled
      </th>
      <th style={{ width: '10%' }} scope="col" role="columnheader">
        Taking Exam
      </th>
      {teacherConfirmEnabled ? (
        <th style={{ width: '10%' }} scope="col" role="columnheader">
          Confirmed
        </th>
      ) : null}
      <th style={{ width: '10%' }} scope="col" role="columnheader">
        <span className="sr-only">Actions</span>
      </th>
    </tr>
  </thead>
)

const OtherHeader = () => (
  <thead>
    <tr role="row">
      <th style={{ width: '40%' }} scope="col" role="columnheader">
        Section
      </th>
      <th style={{ width: '20%' }} scope="col" role="columnheader">
        Join Code
      </th>
      <th style={{ width: '15%' }} scope="col" role="columnheader">
        Max Students
      </th>
      <th style={{ width: '15%' }} scope="col" role="columnheader">
        Enrolled
      </th>
      <th style={{ width: '10%' }} scope="col" role="columnheader">
        <span className="sr-only">Actions</span>
      </th>
    </tr>
  </thead>
)

export class TeacherCourses extends Component {
  renderSections() {
    const { sections, hasSections, course, showFullAccessHeader, teacherConfirmEnabled } =
      this.props

    if (!hasSections) return null

    return (
      <div className="row">
        <div className="col-xs-12">
          <table
            className={`table course-table ${stylesheet['teacher-courses-table']}`}
            summary="Sections are listed in ascending order by Section Name and are grouped by their associated Course. Courses are listed in ascending order by Course name."
          >
            <caption className="sr-only">
              A consolidated list of all of the Sections that you are assigned to.
            </caption>
            {showFullAccessHeader ? (
              <FullAccessHeader teacherConfirmEnabled={teacherConfirmEnabled} />
            ) : (
              <OtherHeader />
            )}
            <tbody>
              {sections.map(section => (
                <TeacherCourseSectionRow
                  key={section.sectionId}
                  section={section}
                  showAllColumns={showFullAccessHeader}
                  teacherConfirmEnabled={teacherConfirmEnabled}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  render() {
    const {
      course: { courseName, testCd },
      hasSections,
    } = this.props

    return (
      <li>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h2 id={`course_${testCd}`}>{courseName}</h2>
          {hasSections && (
            <AddSectionButton testCd={testCd} styles={{ float: 'right', margin: '12px 0 0' }} />
          )}
        </div>
        {this.renderSections()}

        {!hasSections && <AddSectionButton testCd={testCd} styles={{ margin: '0 0 1.5em 0' }} />}
      </li>
    )
  }
}

export default connect(mapStateToProps)(TeacherCourses)
