import CourseSectionSelector from './CourseSectionSelector'
import { EditSectionButton, DeleteSectionButton, SectionCode } from '../common'
import { Error } from '../../common'
import { CODE_TYPES } from '../../../constants/SectionConstants'
import MiniJoinCode from './MiniJoinCode'
import SectionEnrollmentsTable from './SectionEnrollmentsTable'
import ExamDecisionDeadlineNotice from '../../common/ExamDecisionDeadlineNotice'

const mapStateToProps = (state, ownProps) => ({ section: state.courses.entities.sections[ownProps.sectionId] })

const NoStudentsEnrolled = ({ sectionId }) => (
  <div className="row">
    <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 col-xs-12">
      <div style={{margin: '7em auto 0'}}>
        <p style={{fontSize: '1.5em', marginBottom: '1em'}}>No students have signed up yet.</p>
        <SectionCode sectionId={sectionId} isInModal={false} useCode={CODE_TYPES.join} />
      </div>
    </div>
  </div>
)

const EnrolledStudents = ({ section }) => (
  <div className="row">
    <div className="col-md-7 col-sm-8 col-xs-12" style={{marginTop: '2em' }}>
      <SectionEnrollmentsTable section={section} />
    </div>
    <div className="col-md-5 col-sm-4 col-xs-12" style={{marginTop: '2em' }}>
      <ExamDecisionDeadlineNotice isSectionDetails={true} sectionId={section.sectionId} />
    </div>
  </div>
)

const SectionDetails = ({ section }) => {
  const { enrollmentCount, sectionId, maxNumberOfStudents } = section || {}
  const hasEnrollments = enrollmentCount > 0

  return (
    <div className="container" style={{marginTop: '2em'}}>
      {!section ? <Error title="Error retrieving data" message="The section does not exist." />
        : <div>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="row">
                  <div className="col-xs-12 form-group" style={{marginBottom: '8px'}}>
                    <CourseSectionSelector section={section} />
                    <div style={{float: 'left', lineHeight: '48px', marginLeft: 5 }}>
                      <EditSectionButton section={section} />
                      <DeleteSectionButton section={section} />
                    </div>
                  </div>
                </div>
                <div>{enrollmentCount}/{maxNumberOfStudents} Students Enrolled</div>
              </div>
              <div className="col-md-6 col-sm-12" style={{textAlign: 'right'}}>
                {hasEnrollments ? <MiniJoinCode section={section} /> : null}
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                {!hasEnrollments ? <NoStudentsEnrolled sectionId={sectionId} /> : <EnrolledStudents section={section} />}
              </div>
            </div>
          </div> 
      }
    </div>
  )
}

export default connect(mapStateToProps)(SectionDetails)

