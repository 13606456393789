import { Link } from 'react-router-dom'
import { CODE_TYPES } from '../../../constants/SectionConstants'
import { openModal } from '../../../actions/app'
import { EditSectionButton, DeleteSectionButton, ViewJoinCodeButton } from '../../section/common'
import { SecondSemesterIcon, WeServiceIcon } from '../../common/SectionIcons'

import stylesheet from '../../../assets/style/scss/courses-table.scss'


const TeacherCourseSectionRow = ({ section, showAllColumns, teacherConfirmEnabled }) => (
  <tr className={stylesheet['section-row']}>
    <th className={stylesheet['section-name']} scope="row">
      <WeServiceIcon weService={section.weService} />
      <SecondSemesterIcon sectionType={section.sectionType} />
      <Link to={`/course/${section.testCd}/section/${section.sectionId}`}>
        {section.sectionName}
      </Link>
    </th>
    <td className={stylesheet.joincode}>
      <span>{section[CODE_TYPES.join.prop]}</span> <ViewJoinCodeButton section={section} />
    </td>
    <td className={stylesheet['max-students']}>{section.maxNumberOfStudents}</td>
    <td className={stylesheet.enrolled}>{section.enrollmentCount}</td>
    {showAllColumns ? (
      <td key="exam" className={stylesheet['taking-exam']}>
        {section.examCount}
      </td>
    ) : null}
    {showAllColumns && teacherConfirmEnabled ? (
      <td key="confirm" className={stylesheet.confirmed}>
        {Boolean(section.teacherConfirmedExamDecisionsDate) ? 'Yes' : 'No'}
      </td>
    ) : null}
    <td className={stylesheet.actions}>
      <EditSectionButton section={section} />
      <DeleteSectionButton section={section} />
    </td>
  </tr>
)

export default connect(
  null,
  { openModal }
)(TeacherCourseSectionRow)
