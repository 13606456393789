import { openModal } from '../../actions/app'
import { updateStudentExam } from '../../actions/studentsByOrg'
import { resetStudentUpdate } from '../../actions/studentsCommon'

const mapStateToProps = (state, { type, exam, makesOrderChange }) => {
  const {
    update: { type: updateType, updating, error, id },
    studentMap,
    courseMap,
  } = state.studentsByOrg
  const { firstName, lastName } = studentMap[exam.studentId]
  const isActive = updateType === type && exam.examId === id

  return {
    active: isActive,
    updating: isActive && updating,
    error: isActive && error,
    studentName: `${firstName} ${lastName}`,
    courseName: courseMap[exam.testCd].name,
    breakpoint: state.app.breakpoint.name,
    makesOrderChange,
  }
}

class UpdateStudentExamModal extends Component {
  constructor(props) {
    super(props)
    this.state = { shouldCloseModal: false, active: false }
    this.onCloseAction = this.onCloseAction.bind(this)
    this.getFooterActions = this.getFooterActions.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    if (state.active && !props.active) return { shouldCloseModal: true }

    return { active: props.active }
  }

  componentWillUnmount() {
    const { resetStudentUpdate } = this.props
    resetStudentUpdate()
  }

  onCloseAction(options = {}) {
    const { openModal, type, studentName, courseName, exam, makesOrderChange } = this.props
    const { shouldCloseModal } = this.state
    if (shouldCloseModal && makesOrderChange)
      openModal('UpdateStudentExamSuccessModal', {
        type,
        exam,
        studentName,
        courseName,
        ...options,
      })
  }

  getFooterActions(getUpdates, options = {}) {
    const { updating, updateStudentExam, type, exam } = this.props
    const { disabled = false, actionBtnLabel, cancelBtnLabel } = options
    const updatedExam = getUpdates ? getUpdates() : exam

    return [
      { buttonLabel: cancelBtnLabel || 'Cancel', isDismissable: true, isPrimary: false },
      {
        buttonLabel: actionBtnLabel || 'Update',
        isPrimary: true,
        isDisabled: disabled || updating,
        busy: updating,
        onClick: () => updateStudentExam(updatedExam, type),
      },
    ]
  }

  render() {
    const { Component, breakpoint } = this.props
    const { shouldCloseModal } = this.state

    return (
      <Component
        {...this.props}
        getFooterActions={this.getFooterActions}
        shouldCloseModal={shouldCloseModal}
        onCloseAction={this.onCloseAction}
        modalStyles={breakpoint !== 'mobile' ? { width: 750 } : {}}
      />
    )
  }
}

export default connect(
  mapStateToProps,
  { openModal, updateStudentExam, resetStudentUpdate }
)(UpdateStudentExamModal)
