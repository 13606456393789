import { reduxForm, getFormValues } from 'redux-form'
import {
  SELECT_SPECIAL_DIGITAL_FORMATS_FORM,
  SDF_MULTIDAY,
  PAPERFORMATS_PAPER,
  PAPERFORMATS_BRAILLE,
  MULTIDAY_ONLY2DAY,
  MULTIDAY_OVER2DAY,
  UPDATE_TYPE_DIGITAL_ACCOMMODATIONS,
} from '../../constants/StudentConstants'
import { FormGroup } from '../../components/students/specialdigitalformats'
import { isEmpty } from '../../utils/common'
import { updateStudentExamMaterials } from '../../actions/studentsByOrg'
import { Error } from '../../components/common'
import { flattenSpecialDigitalFormats } from '../../selectors/section'

const mapStateToProps = (state, { exam }) => {
  // Used only to set the initial state of the digital accommodations controls
  const { specialDigitalFormats = {} } = exam

  const {
    settingsSpecialDigitalFormats: {
      ssdCategoryCdDescriptions = {},
      ssdCdDescriptions = {},
      multiDayEligibleSsdCds = [],
    },
  } = state
  return {
    exam,
    categories: ssdCategoryCdDescriptions,
    descriptions: ssdCdDescriptions,
    initialValues: specialDigitalFormats,
    selectedSpecialDigitalFormats:
      getFormValues(SELECT_SPECIAL_DIGITAL_FORMATS_FORM)(state) || specialDigitalFormats,
    multiDayEligibleSsdCds,
  }
}

const SelectSpecialDigitalFormatsToBeOrderedForm = ({
  // exam,
  categories,
  descriptions,
  initialValues,
  selectedSpecialDigitalFormats,
  multiDayEligibleSsdCds,
  error,
  handleSubmit,
}) => {
  const groups = Object.keys(initialValues)
  const flatSDF = flattenSpecialDigitalFormats(selectedSpecialDigitalFormats)
  const anyMultiDayEligibleSelected = flatSDF.some(
    item => multiDayEligibleSsdCds.includes(item[0]) && item[1] === true
  )
  return (
    <form onSubmit={handleSubmit}>
      {error && <Error message={error} />}

      {groups.map(category => (
        <FormGroup
          key={category}
          categoryTitle={categories[category]}
          categoryName={category}
          descriptions={descriptions}
          accommodations={selectedSpecialDigitalFormats[category]}
          anyMultiDayEligibleSelected={anyMultiDayEligibleSelected}
        />
      ))}
    </form>
  )
}

export default connect(mapStateToProps, { updateStudentExamMaterials })(
  reduxForm({
    form: SELECT_SPECIAL_DIGITAL_FORMATS_FORM,
    onSubmit: (values, dispatch, props) => {
      const { exam, updateStudentExamMaterials } = props
      const flattenedValues = flattenSpecialDigitalFormats(values)
      let multiDay = null
      let paperInd = null
      let brailleInd = null
      const optedOutDigitalAccommodationCds = []
      flattenedValues.forEach(item => {
        const [key, value] = item
        switch (key) {
          case PAPERFORMATS_PAPER:
            paperInd = value
            break

          case PAPERFORMATS_BRAILLE:
            brailleInd = value
            break

          case MULTIDAY_ONLY2DAY:
            if (value) {
              multiDay = '2D'
            }
            break

          case MULTIDAY_OVER2DAY:
            if (value) {
              multiDay = 'O2D'
            }
            break

          default:
            if (value === false) {
              optedOutDigitalAccommodationCds.push(key)
            }
        }
      })
      const processedValues = { multiDay, optedOutDigitalAccommodationCds, paperInd, brailleInd }
      return updateStudentExamMaterials(exam, UPDATE_TYPE_DIGITAL_ACCOMMODATIONS, processedValues)
    },
    onChange: (values, dispatch, props) => {
      if (props.error || !isEmpty(props.submitErrors)) {
        props.clearSubmitErrors(SELECT_SPECIAL_DIGITAL_FORMATS_FORM)
      }
    },
  })(SelectSpecialDigitalFormatsToBeOrderedForm)
)
