import { StatusPanel } from '@myap/ui-library/esm/components'
import { fetchStatusSummary } from '../../actions/status'
import { addFetchAction, removeFetchAction } from '../../actions/app'
import history from '../../utils/history'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId },
    },
    settingsOrg,
    settingsEducationPeriod: { selectedEducationPeriod },
    status: { data: statusData, error, stale },
  } = state
  const allowedToOrder = settingsOrg.allowedToOrder[selectedOrgId]
  const data =
    statusData && allowedToOrder !== undefined
      ? { ...statusData, school: { allowedToOrder } }
      : null

  return {
    data,
    stale,
    loadError: error, // changing name of error prop so it won't trigger an error in the StatusPanel widget
    selectedOrgId,
    selectedEducationPeriod,
  }
}

const CoordinatorStatusPanel = props => {
  const {
    addFetchAction,
    removeFetchAction,
    fetchStatusSummary,
    stale,
    loadError,
    selectedOrgId,
    selectedEducationPeriod,
  } = props

  useEffect(() => {
    addFetchAction({
      name: 'fetchStatusSummary',
      action: (orgId, educationPeriodCd) => fetchStatusSummary(orgId, educationPeriodCd),
    })
    return () => removeFetchAction('fetchStatusSummary')
  }, [])

  useEffect(() => {
    if (stale && !loadError) fetchStatusSummary(selectedOrgId, selectedEducationPeriod)
  }, [stale])

  if (loadError) console.error(`Status Panel Error: ${loadError}`)

  return <StatusPanel {...props} showSchoolBand={false} history={history} />
}

export default connect(
  mapStateToProps,
  { fetchStatusSummary, addFetchAction, removeFetchAction }
)(CoordinatorStatusPanel)
