import capitalize from 'lodash-es/capitalize'
import { formatDate, DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import { fetchFreeResponseBookletDownloadUrl } from '../../../actions/orders'
import { isEmpty } from '../../../utils/common'

const mapStateToProps = state => {
  const {
    settingsEducationPeriod: { selectedEducationPeriod },
    user: {
      data: { selectedOrgId, isCSR, isDistrictAdmin, isDistrictSuperAdmin },
    },
    frbDownloads: { fetchedFRBDetails: fetched, data = [], error, downloadError },
  } = state

  return {
    orgId: selectedOrgId,
    educationPeriodCd: selectedEducationPeriod,
    fetched,
    data,
    error,
    downloadError,
    downloadDisabled: isCSR || isDistrictAdmin || isDistrictSuperAdmin,
  }
}

const DownloadFreeResponseBookletRow = ({
  orgId,
  educationPeriodCd,
  fetched,
  downloadError,
  courseName,
  fileSize,
  lastDownloadDate,
  testCd,
  examFormat,
  frbDownloadUrl,
  downloadDisabled,
  fetchFreeResponseBookletDownloadUrl,
}) => {
  const [downloaded, setDownloaded] = useState(false)
  const [clickedDownload, setClickedDownload] = useState(false)

  useEffect(() => {
    if (clickedDownload && fetched && !isEmpty(frbDownloadUrl)) {
      window.open(frbDownloadUrl)
      setClickedDownload(false)
    }
  }, [fetched, frbDownloadUrl, testCd, examFormat, clickedDownload])

  const handleClick = async () => {
    setDownloaded(true)
    try {
      await fetchFreeResponseBookletDownloadUrl({
        orgId,
        educationPeriodCd,
        courses: testCd,
        examFormat,
      })
      setClickedDownload(true)
    } catch (err) {
      console.error(`Free Response Download URL Error: ${err}`)
      return
    }
  }

  return (
    <div key={testCd} style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '24px' }}>
      <div>
        <div style={{ width: '350px' }}>
          {courseName} - {capitalize(examFormat)}
        </div>
        <div className="cb-small-font">{fileSize}</div>
      </div>
      <div style={{ marginLeft: 'auto', textAlign: 'right' }}>
        <button
          type="button"
          className="btn btn-sm btn-primary"
          style={{ marginTop: 0 }}
          onClick={() => handleClick()}
          disabled={downloaded || downloadDisabled}
        >
          Download
        </button>
        <div>
          {downloadError?.[`${testCd}_${examFormat}`] ? (
            <span className="cb-error-msg" aria-live="polite">
              {downloadError?.[`${testCd}_${examFormat}`]}
            </span>
          ) : null}
          {!downloadError?.[`${testCd}_${examFormat}`] && lastDownloadDate
            ? `Last downloaded ${formatDate(lastDownloadDate, DATETIME_FORMATS.tinyMonthDayYear)}`
            : null}
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, { fetchFreeResponseBookletDownloadUrl })(
  DownloadFreeResponseBookletRow
)
