import {
  isCoordinator,
  isAdmin,
  isTeacher,
  isSDP,
  isCSR,
  isStudent,
  isETS,
  isDistrictAdmin,
  isDistrictFundingAdmin,
  isDistrictSuperAdmin,
} from '@myap/metadata'
import {
  setSelectedOrgId,
  getSelectedOrgId,
  removeSelectedOrgId,
} from '@myap/ui-library/esm/cookies'
import * as uc from '../constants/UserConstants'
import * as ac from '../constants/AppConstants'
import { FINISH_COORDINATOR_SETUP } from '../constants/SetupConstants'
import axios, { getServerErrorMessage } from '../utils/axios'
import { sortColumnByKey } from '../utils/sort'
import { getOrgLevelAttrs } from '../selectors/section'
import { isEmpty } from '../utils/common'
import { AppcuesIdentify } from '../utils/appcues'

export const logoutUser = () => async dispatch => {
  dispatch({ type: uc.LOGOUT_USER })
  await axios.post(`${Config.API_URL}/on-logout`, {})
}
export const finishCoordinatorSetup = (orgId, orderByAnotherSchool) => ({
  type: FINISH_COORDINATOR_SETUP,
  payload: { orgId, orderByAnotherSchool },
})

export const setUserTypeAttributes = attrs => {
  const { role } = attrs
  const sdp = isSDP(role)
  const ets = isETS(role)
  const coordinator = isCoordinator(role)
  const admin = isAdmin(role)
  const districtAdmin = isDistrictAdmin(role)
  const districtFundingAdmin = isDistrictFundingAdmin(role)
  const districtSuperAdmin = isDistrictSuperAdmin(role)

  return {
    isCSR: isCSR(role) || sdp || ets,
    isETS: ets,
    isSDP: sdp,
    isCoordinator: coordinator || admin || districtAdmin || districtSuperAdmin,
    isTeacher: isTeacher(role),
    isStudent: isStudent(role),
    isSchoolAdmin: admin,
    isDistrictAdmin: districtAdmin,
    isDistrictFundingAdmin: districtFundingAdmin,
    isDistrictSuperAdmin: districtSuperAdmin,
    ...getOrgLevelAttrs(attrs, coordinator),
  }
}

const redirectToMyAPUnknownApp = () => {
  window.location.replace(Config.MYAP_URL.prof)
}

export const getUserDetails = () => async dispatch => {
  dispatch({ type: uc.GET_USER_DETAILS_PENDING })
  dispatch(logoutUser())
  try {
    const response = await axios.get(`${Config.API_URL}/mydetails`, { params: { app: 'apro' } })
    const { data } = response
    const { roles: r, activeRoleCd } = data
    let unSortedRoles = []
    const roleArr = []
    if (activeRoleCd) {
      roleArr.push(activeRoleCd)
      // filter roles by activeRoleCd
      unSortedRoles = r.filter(({ role }) => role === activeRoleCd)
    } else {
      r.forEach(({ role }) => roleArr.push(role))
      unSortedRoles = r
    }
    const roles = sortColumnByKey(
      unSortedRoles.map(r => ({ ...r })),
      'orgName',
      'asc'
    )
    const roleTypes = [...new Set(roleArr)] // Produces a set of unique role codes
    const hasMultipleRoles = roleTypes.length > 1

    const preselectedOrgId = getSelectedOrgId()
    const preLoadedIndex = roles.findIndex(r => r.orgId === parseInt(preselectedOrgId, 10))
    const orgNeedsSetupIndex = roles.findIndex(
      r => r.role === 'C' && r.needsSetup && !isEmpty(r.aiCode)
    )
    const selectedRoleIndex =
      preLoadedIndex !== -1 ? preLoadedIndex : orgNeedsSetupIndex !== -1 ? orgNeedsSetupIndex : 0
    const types = setUserTypeAttributes(roles[selectedRoleIndex])

    if (roles[selectedRoleIndex].orgId) {
      setSelectedOrgId(roles[selectedRoleIndex].orgId)
    } else if (preselectedOrgId) {
      removeSelectedOrgId()
    }

    const districtAdmin = roles.find(
      ({ role }) => isDistrictAdmin(role) || isDistrictSuperAdmin(role)
    )
    // District Admin and District Super Admin also get a coordinator-like experience
    if (districtAdmin) {
      types.isCoordinator = true
    }

    // *** Identify user to Appcues
    AppcuesIdentify(data.personId, {
      role: roles[selectedRoleIndex].role,
      orgId: roles[selectedRoleIndex].orgId,
      namespace: data.namespace,
    })

    if (
      !types.isCoordinator &&
      !types.isTeacher &&
      !types.isStudent &&
      !types.isCSR &&
      !types.isSDP &&
      !types.isSchoolAdmin &&
      !types.isDistrictAdmin &&
      !types.isDistrictFundingAdmin &&
      !types.isDistrictSuperAdmin
    )
      dispatch({ type: uc.GET_USER_DETAILS_NOACCESS, payload: 'Error: You do not have access.' })
    else
      dispatch({
        type: uc.GET_USER_DETAILS_FULFILLED,
        payload: {
          ...data,
          ...types,
          hasMultipleRoles,
          roleTypes,
          roles: roles.map(r => ({
            ...r,
            notAbleToOrder: Boolean(
              (isCoordinator(r.role) ||
                isAdmin(r.role) ||
                isDistrictAdmin(r.role) ||
                isDistrictFundingAdmin(r.role) ||
                isDistrictSuperAdmin(r.role)) &&
                (r.needsSetup || (!r.preAPOnly && r.aiCode && !r.setupCompletionDate))
            ),
          })),
          selectedRole: selectedRoleIndex,
          selectedOrgId: types.isCSR || types.isSDP ? null : roles[selectedRoleIndex].orgId || null,
          acceptedGeneralTAC: types.isCSR || types.isSDP ? true : data.termsAndConditions?.general,
          ...(types.isDistrictFundingAdmin || types.isDistrictSuperAdmin
            ? { acceptedFundingTAC: data.termsAndConditions?.funding }
            : {}),
        },
      })
    return response
  } catch (err) {
    let status
    let message
    let stack

    if (err.response) {
      status = err?.response?.status
      message = err?.response?.message
    } else {
      message = err?.message
      stack = err?.stack
      status = 200
    }

    if (status === 200) {
      dispatch({ type: ac.GENERIC_APP_ERROR, payload: { message, stack } })
    } else if (status === 403) {
      dispatch({ type: uc.GET_USER_DETAILS_NOACCESS })
      redirectToMyAPUnknownApp()
    } else {
      dispatch({
        type: status === 401 ? uc.GET_USER_DETAILS_NOACCESS : uc.GET_USER_DETAILS_REJECTED,
        payload: message || 'An error occurred. Try again later.',
      })
    }
  }
}

export const getOrgDetails = orgId => async (dispatch, getState) => {
  const {
    user: {
      data: { isCSR, isSDP, isETS },
    },
  } = getState()
  dispatch({ type: uc.GET_USER_DETAILS_PENDING })
  try {
    const { data } = await axios.post(`${Config.API_URL}/v1/csr/context`, { orgId })
    dispatch({
      type: uc.GET_USER_DETAILS_FULFILLED,
      payload: {
        roles: [data.roleData],
        selectedRole: 0,
        selectedOrgId: data.roleData.orgId,
        ...setUserTypeAttributes(data.roleData, true),
        isCSR,
        isSDP,
        isETS,
        acceptedGeneralTAC: true,
      },
    })
  } catch (err) {
    dispatch({ type: uc.GET_USER_DETAILS_REJECTED, payload: getServerErrorMessage(err) })
  }
}

export const setSelectedRole = orgId => (dispatch, getState) => {
  const { roles } = getState().user.data
  const index = roles.findIndex(r => r.orgId === parseInt(orgId, 10))

  dispatch({
    type: uc.USER_SET_SELECTED_ROLE,
    payload: {
      selectedRole: index,
      selectedOrgId: orgId,
      ...setUserTypeAttributes(roles[index]),
    },
  })
  setSelectedOrgId(orgId)
}

export const setAcceptedTermsAndConditions = tacType => async dispatch => {
  dispatch({ type: uc.ACCEPT_TERMS_AND_CONDITIONS_PENDING })

  let tacName = ''
  let acceptedTACType = ''
  switch (tacType) {
    case uc.TAC_FUNDING:
      tacName = uc.TAC_FUNDING_NAME
      acceptedTACType = 'acceptedFundingTAC'
      break

    case uc.TAC_GENERAL:
    default:
      tacName = uc.TAC_GENERAL_NAME
      acceptedTACType = 'acceptedGeneralTAC'
  }

  try {
    await axios.post(`${Config.API_URL}/terms-and-conditions/accept?type=${tacType}`)
    dispatch({
      type: uc.ACCEPT_TERMS_AND_CONDITIONS_FULFILLED,
      payload: { data: { [acceptedTACType]: true }, termsAndConditions: { [tacName]: true } },
    })
  } catch (err) {
    dispatch({ type: uc.ACCEPT_TERMS_AND_CONDITIONS_REJECTED, payload: getServerErrorMessage(err) })
  }
}

export const selectRole = selectedRole => async dispatch => {
  dispatch({ type: uc.SELECT_ROLE_PENDING })
  try {
    await axios.put(`${Config.API_URL}/active-role/${selectedRole}`)
    dispatch({ type: uc.SELECT_ROLE_FULFILLED, payload: selectedRole })
  } catch (err) {
    dispatch({ type: uc.SELECT_ROLE_REJECTED, error: getServerErrorMessage(err) })
  }
}
