import { Select, Input } from '../../common'
import {
  CREATE_FORM_FIELDS as FIELDS,
  CREATE_FORM_EXAM_ONLY_VALUE,
  CREATE_FORM_SCHEDULE_LABEL_ID,
  COURSE_SCHEDULE_TYPES,
} from '../../../constants/SectionConstants'
import SecondSemesterSelector from './SecondSemesterSelector'

export const CourseScheduleSelector = ({
  schedule,
  selectedIsCurrent,
  disabled,
  examOnlySelected,
}) => {
  let options = []
  if (selectedIsCurrent) {
    options = [COURSE_SCHEDULE_TYPES.S_FY, COURSE_SCHEDULE_TYPES.S_FS, COURSE_SCHEDULE_TYPES.S_SS]
  } else {
    options = [COURSE_SCHEDULE_TYPES.S_FY]
  }
  return (
    <>
      <Select
        options={options}
        isRequired={schedule !== CREATE_FORM_EXAM_ONLY_VALUE}
        name={FIELDS.schedule}
        labelledBy={CREATE_FORM_SCHEDULE_LABEL_ID}
        disabled={disabled || examOnlySelected}
      />
      {schedule === COURSE_SCHEDULE_TYPES.S_SS.value ? <SecondSemesterSelector /> : null}
    </>
  )
}

export const CourseScheduleWithExamOnlyOption = props => {
  const { examOnlySelected, schedule, disabled } = props

  return (
    <>
      <div className="row">
        <div className="col-xs-2" style={{ paddingRight: 0 }}>
          <Input
            type="radio"
            style={{ lineHeight: '48px' }}
            name={FIELDS.schedule}
            value={examOnlySelected ? '' : schedule}
            isRequired={true}
            disabled={disabled}
            ariaLabel="Course Schedule"
            showError={false}
          />
        </div>
        <div className="col-xs-10" style={{ paddingLeft: 0, marginBottom: -10 }}>
          <CourseScheduleSelector {...props} />
        </div>
      </div>
      <div style={{ marginBottom: 12 }}>
        <Input
          type="radio"
          name={FIELDS.schedule}
          value={CREATE_FORM_EXAM_ONLY_VALUE}
          label=" Exam Only"
          ariaLabel="Exam Only"
          isRequired={true}
          disabled={disabled}
          showError={false}
        />
      </div>
    </>
  )
}
