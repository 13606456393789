import { useParams, useLocation } from 'react-router-dom'
import { fetchSection } from '../../actions/section'
import { suppressNav, unSuppressNav } from '../../actions/nav'
import { Loader } from '../../components/common'
import style from '../../assets/style/scss/join-code.scss'

const mapStateToProps = state => {
  const {
    section: { data, fetching },
  } = state

  return {
    section: data,
    loadingSection: fetching,
  }
}

const DisplaySection = ({
  section: { courseName, sectionName, joinCode, transferCode, sectionTeachers },
  type,
}) => (
  <div className={style['print-version-page']}>
    <div className={`h1 cb-margin-bottom-zero ${style['print-course-name']}`}>
      {courseName} - {sectionName}
    </div>
    {sectionTeachers && sectionTeachers.length > 0 && (
      <div className={`h2 cb-margin-bottom-zero ${style['print-teacher-name']}`}>
        Teacher{sectionTeachers.length > 1 ? 's' : ''}:{' '}
        {sectionTeachers.map(t => t.teacherName).join(', ')}
      </div>
    )}
    <div className={`cb-digital-gray-bg ${style['join-code-container']}`}>
      <div className={`text-center ${style['join-code-wrap']}`}>
        <div>SECTION {type === 'join' ? 'JOIN' : 'TRANSFER'} CODE</div>
        <div className="roboto-slab-bold" style={{ whiteSpace: 'nowrap' }}>
          {type === 'join' ? joinCode : transferCode}
        </div>
      </div>
    </div>
    <div>
      To enroll in this section online, sign into https://myap.collegeboard.org and enter the
      section {type === 'join' ? 'join' : 'transfer'} code
    </div>
  </div>
)

const ViewSectionCode = ({ section, loadingSection, fetchSection, suppressNav, unSuppressNav }) => {
  const { pathname } = useLocation()
  const { sectionId } = useParams()
  const pathSegments = pathname.split('/')
  const type = pathSegments.includes('joincode') ? 'join' : 'transfer'

  useEffect(() => {
    fetchSection(sectionId)
    suppressNav()

    return () => unSuppressNav()
  }, [])

  if (!section && !loadingSection) return null

  return (
    <CSSTransition
      classNames="animate-fade"
      in={false}
      timeout={500}
      appear={false}
      enter={false}
      exit={false}
    >
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            {loadingSection ? <Loader /> : <DisplaySection section={section} type={type} />}
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export default connect(
  mapStateToProps,
  { fetchSection, suppressNav, unSuppressNav }
)(ViewSectionCode)
