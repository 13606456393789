import { reduxForm, getFormValues, reset } from 'redux-form'
import {
  formatDate,
  formatDateTime,
  manipulateDays,
  DATETIME_FORMATS,
} from '@myap/ui-library/esm/date'
import {
  Input,
  Label,
  Error,
  Date,
  Loader,
  ChangesSavedLabelNotification,
  Tooltip,
} from '../../components/common'
import {
  EXAM_DECISIONS_FORM_NAME,
  EXAM_DECISIONS_FORM_FIELDS,
} from '../../constants/SettingsConstants'
import { openModal } from '../../actions/app'
import { Prompt } from 'react-router-dom'
import {
  DefaultSettingHeader,
  DefaultSettingIntro,
  AdvancedSettingHeader,
  AdvancedSettingIntro,
} from '../../components/settings/examdecisions/ExamDecisionSettingsText'
import { updateExamDecisionsSettings } from '../../actions/settingsOrg'
import { getSelectedEducationPeriod } from '../../selectors/settings'

import stylesheet from '../../assets/style/scss/settings-form.scss'

const DECISION_DEADLINE_LABEL = 'Student Decision Deadline'
const ENABLE_TEACHER_CONFIRMATION_LABEL = 'Enable Teacher Confirmation'
const DISABLE_TEACHER_CONFIRMATION_LABEL = 'Disable Teacher Confirmation'
const teacherConfirmationMessage =
  "Teachers can review and confirm students' exam decisions. After a teacher confirms the decisions, they will be locked just as if the deadline had passed."
const changesSavedNotificationDisplayDuration = 500

const normalizeBooleans = val => (val === 'true' ? true : false)

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId, selectedRole, roles },
    },
    settingsExamDecisions,
    settingsEducationPeriod: { selectedEducationPeriod, availableEducationPeriods },
    settingsDeadlines: {
      data: {
        [selectedEducationPeriod]: {
          regularOrderingDisplayDeadline,
          secondSemesterOrderingDeadline,
          finalOrderingDeadline,
          priorityOrderingDeadline,
        },
      },
    },
  } = state
  const decisionSettings = settingsExamDecisions[selectedOrgId] || {}
  const academicStartDate = formatDate(
    availableEducationPeriods[selectedEducationPeriod].academicYrStartDate
  )
  const formValues = getFormValues(EXAM_DECISIONS_FORM_NAME)(state) || {}

  return {
    initialValues: decisionSettings.data,
    standardDecisionDeadline: formatDate(formValues[EXAM_DECISIONS_FORM_FIELDS.standardDeadline]),
    regularOrderingDisplayDeadline,
    secondSemesterDecisionDeadline: formatDate(
      formValues[EXAM_DECISIONS_FORM_FIELDS.secondSemesterDeadline]
    ),
    finalOrderingDeadline,
    priorityOrderingDeadline,
    updatingSettings: decisionSettings.updating,
    updatedSettings: decisionSettings.updated,
    decisionsEnabled: formValues[EXAM_DECISIONS_FORM_FIELDS.enableDecisions],
    errorSavingSettings: decisionSettings.errored,
    standardDeadlineLimits: {
      minDate: academicStartDate,
      maxDate: manipulateDays(regularOrderingDisplayDeadline, -1),
    },
    secondSemesterDeadlineLimits: {
      minDate: academicStartDate,
      maxDate: manipulateDays(secondSemesterOrderingDeadline, -1),
    },
    selectedOrgId,
    canEditEnableDecisions: roles[selectedRole].editExamDecisionSettings,
  }
}

class ExamDecisionsForm extends Component {
  constructor(props) {
    super(props)
    this.state = { saved: false }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.updatedSettings &&
      !prevProps.updatedSettings &&
      !prevProps.canEditEnableDecisions
    ) {
      this.setState({ saved: true })
      setTimeout(() => {
        this.setState({ saved: false })
      }, changesSavedNotificationDisplayDuration)
    }
  }

  render() {
    const {
      canEditEnableDecisions,
      pristine,
      handleSubmit,
      valid,
      regularOrderingDisplayDeadline,
      finalOrderingDeadline,
      priorityOrderingDeadline,
      standardDecisionDeadline,
      secondSemesterDecisionDeadline,
      standardDeadlineLimits,
      secondSemesterDeadlineLimits,
      decisionsEnabled,
      errorSavingSettings,
      updatingSettings,
      error,
    } = this.props
    const isAdvancedSettings = !canEditEnableDecisions && decisionsEnabled
    const isDefaultSettings = !canEditEnableDecisions && !decisionsEnabled

    if (isDefaultSettings)
      return (
        <>
          <h5 className="h6">{DefaultSettingHeader}</h5>
          <DefaultSettingIntro />
        </>
      )

    return (
      <form role="form" className={stylesheet.settings} onSubmit={handleSubmit}>
        <Prompt
          when={!pristine}
          message={JSON.stringify({
            form: EXAM_DECISIONS_FORM_NAME,
            closeOnPrimaryButtonClick: true,
          })}
        />
        {canEditEnableDecisions ? (
          <fieldset>
            <legend className="sr-only">Exam Decision Indicator</legend>
            <div className="form-group" style={{ marginBottom: 5 }}>
              <Input
                type="radio"
                name={EXAM_DECISIONS_FORM_FIELDS.enableDecisions}
                label={DefaultSettingHeader}
                value={false}
                isRequired={true}
                normalize={normalizeBooleans}
              />
            </div>
            <DefaultSettingIntro classes={stylesheet['radio-indent']} />
            <div className="form-group" style={{ marginBottom: 5 }}>
              <Input
                type="radio"
                name={EXAM_DECISIONS_FORM_FIELDS.enableDecisions}
                label={AdvancedSettingHeader}
                isRequired={true}
                normalize={normalizeBooleans}
                value={true}
              />
            </div>
          </fieldset>
        ) : (
          <h5 className="h6">{AdvancedSettingHeader}</h5>
        )}
        <div
          style={{ marginBottom: 25 }}
          className={canEditEnableDecisions ? stylesheet['radio-indent'] : ''}
        >
          <AdvancedSettingIntro />
          {isAdvancedSettings && errorSavingSettings ? (
            <Error title="Error Saving Data" message={errorSavingSettings} />
          ) : null}
          {isAdvancedSettings ? (
            <ChangesSavedLabelNotification
              in={this.state.saved}
              durationOut={1500}
              overrideDefaultStyle={{ float: 'right' }}
            />
          ) : null}
          <div className="row">
            <div className="col-xs-5">
              <h6>
                <strong>For full-year or first-semester courses:</strong>
              </h6>
              <div className={stylesheet['item-header']}>Preferred Ordering Deadline</div>
              <div className={stylesheet['item-value']}>
                {formatDateTime(priorityOrderingDeadline, DATETIME_FORMATS.longMonthDayYearTime)}
              </div>
              <div className={stylesheet['item-header']}>Final Ordering Deadline</div>
              <div className={stylesheet['item-value']}>
                {formatDateTime(
                  regularOrderingDisplayDeadline,
                  DATETIME_FORMATS.longMonthDayYearTime
                )}
              </div>
              {isAdvancedSettings ? (
                <>
                  <Date
                    name={EXAM_DECISIONS_FORM_FIELDS.standardDeadline}
                    isRequired={true}
                    form={EXAM_DECISIONS_FORM_NAME}
                    openToDate={standardDecisionDeadline}
                    minDate={standardDeadlineLimits.minDate}
                    maxDate={standardDeadlineLimits.maxDate}
                    label={DECISION_DEADLINE_LABEL}
                    labelClasses={stylesheet['item-header']}
                  />
                </>
              ) : null}
              <h6>
                <strong>
                  For courses that begin{' '}
                  <em style={{ fontStyle: 'normal', textDecoration: 'underline' }}>after</em> the
                  Final Ordering Deadline:
                </strong>
              </h6>
              <div className={stylesheet['item-header']}>
                Spring Course Orders and Fall Order Changes Deadline
              </div>
              <div className={stylesheet['item-value']}>
                {formatDateTime(finalOrderingDeadline, DATETIME_FORMATS.longMonthDayYearTime)}
              </div>
              {isAdvancedSettings ? (
                <>
                  <Date
                    name={EXAM_DECISIONS_FORM_FIELDS.secondSemesterDeadline}
                    isRequired={true}
                    form={EXAM_DECISIONS_FORM_NAME}
                    openToDate={secondSemesterDecisionDeadline}
                    minDate={secondSemesterDeadlineLimits.minDate}
                    maxDate={secondSemesterDeadlineLimits.maxDate}
                    label={DECISION_DEADLINE_LABEL}
                    labelClasses={stylesheet['item-header']}
                  />
                </>
              ) : null}
            </div>
            {isAdvancedSettings ? (
              <div className="col-xs-7">
                <fieldset>
                  <legend className="sr-only">Teacher Confirmation</legend>
                  <div className={stylesheet['item-header']}>
                    Teacher Confirmation{' '}
                    <div style={{ display: 'inline-block' }}>
                      <Tooltip
                        title={teacherConfirmationMessage}
                        placement="top"
                        container="body"
                        label="More information: Teacher Confirmation"
                      >
                        <i
                          aria-hidden="true"
                          className="cb-glyph cb-glyph-xs cb-glyph-circular cb-exclamation"
                          style={{ display: 'inline-block', fontSize: '8px' }}
                          data-alt={teacherConfirmationMessage}
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <Input
                    type="radio"
                    name={EXAM_DECISIONS_FORM_FIELDS.teacherConfirmation}
                    label={DISABLE_TEACHER_CONFIRMATION_LABEL}
                    value={false}
                    isRequired={true}
                    normalize={normalizeBooleans}
                  />
                  <div className={stylesheet['radio-indent']}>
                    <em>
                      (AP teachers can view <strong>but not</strong> edit students&#39; exam
                      registrations.))
                    </em>
                  </div>
                  <Input
                    type="radio"
                    name={EXAM_DECISIONS_FORM_FIELDS.teacherConfirmation}
                    label={ENABLE_TEACHER_CONFIRMATION_LABEL}
                    value={true}
                    isRequired={true}
                    normalize={normalizeBooleans}
                  />
                  <div className={stylesheet['radio-indent']}>
                    <em>
                      (AP teachers can view <strong>and</strong> edit students&#39; exam
                      registrations. AP coordinators will still have the ability to make final
                      changes to students&#39; exam registrations.)
                    </em>
                  </div>
                </fieldset>
              </div>
            ) : null}
          </div>
        </div>
        <button
          type="submit"
          disabled={pristine || (isAdvancedSettings && updatingSettings) || !valid}
          className="btn btn-sm btn-primary"
          style={{ position: 'relative' }}
          id={EXAM_DECISIONS_FORM_FIELDS.enableDecisions + '-button'}
          ref={node => (this.trigger = node)}
        >
          Save
          {isAdvancedSettings && updatingSettings ? (
            <Loader size="sm" style={{ position: 'absolute', top: 15, right: 10 }} />
          ) : null}
        </button>
      </form>
    )
  }
}

export default connect(mapStateToProps, { openModal, updateExamDecisionsSettings, reset })(
  reduxForm({
    form: EXAM_DECISIONS_FORM_NAME,
    enableReinitialize: true,
    onSubmit: (data, dispatch, props) => {
      const { canEditEnableDecisions, openModal, updateExamDecisionsSettings, selectedOrgId } =
        props
      return canEditEnableDecisions
        ? openModal('ConfirmEnableExamDecisionsModal')
        : updateExamDecisionsSettings(data, selectedOrgId)
    },
    onSubmitFail: (errs, dispatch, submitErr, props) => props.reset(),
  })(ExamDecisionsForm)
)
