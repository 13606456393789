import memoize from 'memoizee'
import { filterData } from './filters/utils'
import {
  COURSE_TYPE_AP,
  COURSE_TYPE_CAREER_KICKSTART,
  COURSE_TYPE_PREAP,
} from '../constants/CourseConstants'
import { COURSE_FILTER_FUNCTIONS } from './filters/courseFilters'
import { sortColumnByKey } from '../utils/sort'

export const getCoursesWithoutSectionsCount = memoize(
  ({ result, entities }) =>
    result.courses.filter(id => !entities.courses[id].sections.length).length
)
export const getCoursesSectionCount = memoize(courses =>
  courses.reduce((acc, val) => {
    if (!val.sections) {
      return acc
    }
    return acc + val.sections.length
  }, 0)
)

// support both attribute names: testProgramCd, testProgram (coordinator student requests)
export const isAPCourse = memoize(course => {
  const { testProgramCd, testProgram } = course || {}
  return testProgramCd === COURSE_TYPE_AP || testProgram === COURSE_TYPE_AP
})

export const isCareerKickstartCourse = memoize(course => {
  const { testProgramCd, testProgram } = course || {}
  return (
    testProgramCd === COURSE_TYPE_CAREER_KICKSTART || testProgram === COURSE_TYPE_CAREER_KICKSTART
  )
})

export const isPreAPCourse = memoize(course => {
  const { testProgramCd, testProgram } = course || {}
  return testProgramCd === COURSE_TYPE_PREAP || testProgram === COURSE_TYPE_PREAP
})

export const hasMultiCourseTypes = memoize(courses => !courses.every(c => isAPCourse(c)))

export const flattenCourses = memoize(data => {
  const { courses = {}, sections: sectionsObj = {} } = data.entities
  return Object.values(courses).reduce((acc, c) => {
    const sections = c.sections.map(id => sectionsObj[id])
    return sections.length ? [...acc, ...sections] : [...acc, c]
  }, [])
})

// flatten data to array of sections for filtering
// courses without sections will be added as a "section"
// once filtered, reconstruct to nested state: array of courses,
// with array of sections as a child attribute for each course
export const filterCoordinatorCourses = memoize((data, filters) => {
  const courses = flattenCourses(data)
  const filtered = !Object.keys(filters).length
    ? courses
    : filterData(courses, filters, COURSE_FILTER_FUNCTIONS)

  return filtered.reduce((arr, section) => {
    const index = arr.findIndex(c => c.testCd === section.testCd)

    if (index !== -1) {
      // already added course
      arr[index].sections = [...arr[index].sections, section]
      return arr
    }
    // add course without sections or with first section populated
    return [...arr, { ...section, sections: section.sectionId ? [section] : [] }]
  }, [])
})

export const getAllCourses = memoize(
  ({ state, apOnly = false, sortByCourseName = true, sortDirection = 'asc' }) => {
    const {
      user: {
        data: { selectedOrgId: orgId },
      },
      settingsEducationPeriod: { selectedEducationPeriod: educationPeriodCd },
      availableCourses,
    } = state
    const coursesForOrg =
      availableCourses?.fetched && availableCourses?.[educationPeriodCd]?.[orgId]?.length
        ? apOnly
          ? availableCourses[educationPeriodCd][orgId].filter(course => isAPCourse(course))
          : availableCourses[educationPeriodCd][orgId]
        : []
    if (sortByCourseName) {
      return sortColumnByKey(coursesForOrg, ['courseName'], [sortDirection])
    }
    return coursesForOrg
  }
)
