import { useCallback } from 'react'
import { fetchInvoiceDownloadUrl, flushInvoiceDownloadUrl } from '../../actions/invoices'

const defaultKey = 'latest'

const mapStateToProps = (state, { invoiceDt = 'latest' }) => {
  const {
    user: {
      data: { selectedOrgId: orgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod: educationPeriodCd },
    invoiceDownloadUrl,
  } = state

  const downloadUrl = invoiceDownloadUrl?.url
  const error = invoiceDownloadUrl?.error
  const fetching = invoiceDownloadUrl?.fetching

  return {
    orgId,
    educationPeriodCd,
    downloadUrl,
    fetching,
    error,
  }
}

const DownloadInvoiceLink = ({
  showAsButton,
  invoiceDt = defaultKey,
  orgId,
  educationPeriodCd,
  downloadUrl,
  fetching,
  error,
  title = '',
  fetchInvoiceDownloadUrl,
  flushInvoiceDownloadUrl,
}) => {
  const [clickedDownload, setClickedDownload] = useState(false)
  const [fetchUrlError, setFetchUrlError] = useState(null)
  const flushUrl = useCallback(() => flushInvoiceDownloadUrl(), [])

  useEffect(() => {
    if (clickedDownload && !fetching) {
      if (downloadUrl && !error) {
        window.open(downloadUrl)
      } else if (error) {
        setFetchUrlError(error)
      }
      setClickedDownload(false)
      flushUrl()
    }
  }, [clickedDownload, fetching, downloadUrl, error, flushUrl])

  const handleClick = async () => {
    await fetchInvoiceDownloadUrl({ orgId, educationPeriodCd, invoiceDt })
    setClickedDownload(true)
  }

  return (
    <>
      <button
        type="button"
        className={showAsButton ? 'btn btn-sm btn-primary' : 'btn-link'}
        disabled={fetching}
        onClick={handleClick}
      >
        {!showAsButton && <i className="cb-glyph cb-download" aria-hidden={true} />} {title}
      </button>
      {fetchUrlError ? (
        <span className="cb-error-msg" aria-live="polite">
          Error: {fetchUrlError}
        </span>
      ) : null}
    </>
  )
}

export default connect(mapStateToProps, { fetchInvoiceDownloadUrl, flushInvoiceDownloadUrl })(
  DownloadInvoiceLink
)
