import FilterContainer from '../common/FilterContainer'
import { FilterResultsCount, FilterNoResultsMessage } from '../common/FilterMessages'
import {
  COORDINATOR_COURSE_VIEW_BYCOURSE,
  COORDINATOR_COURSE_VIEW_OPTIONS,
} from '../../../constants/CourseConstants'
import { BasicSelect } from '../../common'
import { fetchTeachers } from '../../../actions/teacher'
import { EnrollmentFilterDropdown, TeacherConfirmFilterDropdown } from './CourseFilterDropdowns'
import {
  CourseFilterDropdown,
  TeacherFilterDropdown,
  CourseTypeFilterDropdown,
} from '../common/Dropdowns'
import { getCourseFilterTagData } from '../../../selectors/filters/courseFilters'
import { getCoursesSectionCount } from '../../../selectors/course'
import { clearFilters } from '../../../actions/filters'

import stylesheet from '../../../assets/style/scss/courses-table.scss'

const mapStateToProps = state => {
  const {
    data: { selectedOrgId },
  } = state.user
  const {
    fetching,
    error,
    data: { [selectedOrgId]: orgTeachers = [] },
  } = state.orgTeachers
  const { selectedEducationPeriod } = state.settingsEducationPeriod
  const {
    breakpoint: { name: breakpoint },
  } = state.app

  return {
    selectedOrgId,
    selectedEducationPeriod,
    shouldFetchTeachers: !fetching && !orgTeachers.length && !error,
    breakpoint,
    courses: state.courses?.entities?.courses || {},
    courseCount: state.courses?.result?.courses?.length || 0,
    sectionsCount: Object.keys(state.courses?.entities?.sections)?.length || 0,
    teachers: orgTeachers,
  }
}

const CoordinatorCourseFilters = ({
  selectedOrgId,
  selectedEducationPeriod,
  fetchTeachers,
  shouldFetchTeachers,
  visibleCourses,
  courseCount,
  sectionsCount,
  view,
  toggleView,
  breakpoint,
  courses,
  teachers,
  activeFilters,
}) => {
  const isCourseView = view === COORDINATOR_COURSE_VIEW_BYCOURSE
  const text = isCourseView ? 'course' : 'section'
  const visibleCoursesLen = visibleCourses.length

  useEffect(() => {
    if (shouldFetchTeachers) fetchTeachers(selectedOrgId, selectedEducationPeriod)
  }, [])

  return (
    <>
      <div className="container" style={{ marginTop: 20 }}>
        <div className="row">
          <div className="col-sm-6 col-sm-push-6 col-xs-12">
            <form
              className={`${stylesheet['coordinator-course-view-form']} ${stylesheet[breakpoint]}`}
            >
              <BasicSelect
                input={{
                  name: 'courseCourseViewForm',
                  value: view,
                  onChange: e => {
                    toggleView(e.target.value)
                    clearFilters()
                    window.scrollTo(0, 0)
                  },
                }}
                options={COORDINATOR_COURSE_VIEW_OPTIONS}
                label="View By:"
              />
            </form>
          </div>
          <div className="col-sm-6 col-sm-pull-6 col-xs-12">
            <FilterResultsCount
              visibleCount={
                isCourseView ? visibleCoursesLen : getCoursesSectionCount(visibleCourses)
              }
              totalCount={isCourseView ? courseCount : sectionsCount}
              resultsTypeText={text}
              style={{ lineHeight: '48px' }}
            />
          </div>
        </div>
      </div>
      <FilterContainer
        activeFilters={activeFilters}
        label="Course Filters"
        getFilterTagData={(filter, value) =>
          getCourseFilterTagData({ courses, teachers }, filter, value)
        }
      >
        <CourseFilterDropdown courses={courses} labelKey="courseName" />
        <CourseTypeFilterDropdown courses={courses} />
        <EnrollmentFilterDropdown />
        <TeacherConfirmFilterDropdown />
        <TeacherFilterDropdown
          teachers={teachers.map(t => ({
            name: `${t.firstName} ${t.lastName}`,
            ...t,
          }))}
          valueKey="proId"
        />
      </FilterContainer>

      {Object.keys(activeFilters).length && !visibleCoursesLen && courseCount ? (
        <FilterNoResultsMessage text={text} />
      ) : null}
    </>
  )
}

export default connect(mapStateToProps, { fetchTeachers })(CoordinatorCourseFilters)
