import RenderExamComponent from './RenderExamComponent'
import { openModal } from '../../actions/app'
import {
  PACKING_LIST_COMPONENT_MAPPING,
  PACKING_LIST_CTA_MAPPING,
  PACKING_LIST_LABEL_MAPPING,
  PACKING_GENERAL_COMPONENTS_ORDER,
  CHANGE_PACKING_LIST_ITEMS_MODAL,
} from '../../constants/PackingListConstants'

import packingStyles from '../../assets/style/scss/packing-details.scss'

const mapStateToProps = (state, ownProps) => {
  const {
    id = '',
    materials,
    packingListItemId,
    inline,
    readOnly,
    headerTitle,
    GoToUnused = null,
  } = ownProps
  return {
    id,
    materials,
    packingListItemId,
    inline,
    readOnly,
    headerTitle,
    GoToUnused,
  }
}

// Handle other materials like exam cds.
class CourseMaterialsReturned extends Component {
  constructor(props) {
    super(props)
    this.editButton = createRef()
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(e) {
    const { id, headerTitle, materials, packingListItemId, openModal } = this.props

    e.preventDefault()

    const formattedMaterials = [{
      label: PACKING_LIST_LABEL_MAPPING[id],
      name: id,
      quantity: materials.returned,
      returnedReason: materials.returnedReason || '',
    }]

    openModal(CHANGE_PACKING_LIST_ITEMS_MODAL, {
      materials: formattedMaterials,
      shippedQuantity: materials.quantity,
      packingListItemId,
      headerTitle,
      modalCloseFocusElem: this.editButton.current,
    })
  }

  renderActionElement() {
    const { id, GoToUnused } = this.props

    if (GoToUnused) {
      return <GoToUnused />
    }

    return (
      <button
        style={{ padding: '0' }}
        type="button"
        className="btn-link"
        ref={this.editButton}
        onClick={this.handleClick}
      >
        {PACKING_LIST_CTA_MAPPING[id] || 'Edit '}
      </button>
    )
  }

  render() {
    const { id, materials, inline, readOnly } = this.props
    return (
      <div className={inline ? `${packingStyles.inline}` : ''}>
        <h4>{PACKING_LIST_LABEL_MAPPING[id] || ''}</h4>
        {PACKING_GENERAL_COMPONENTS_ORDER.map(item => {
          if (materials[item] === null || materials[item] === undefined)
            return null
          return (
            <div key={item} style={{ display: 'inline-block', padding: '12px 24px 24px 0' }}>
              <RenderExamComponent label={PACKING_LIST_COMPONENT_MAPPING[item]} count={materials[item]} />
            </div>
          )
        })}
        {!readOnly && (
          <div style={{ marginBottom: '24px' }}>
            {this.renderActionElement()}
          </div>
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, { openModal })(CourseMaterialsReturned)
