import { reduxForm, getFormValues } from 'redux-form'
import { formatDate, DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import { Select, Loader, Error, ChangesSavedTextNotification, Accordions } from '../../common'
import { fetchScoreReportingServices, saveScoreReportingServices } from '../../../actions/orders'
import { addFetchAction, removeFetchAction } from '../../../actions/app'
import DownloadFreeResponseBooklets from './DownloadFreeResponseBooklets'
import { isEmpty } from '../../../utils/common'
import { isFeatureEnabled } from '../../../selectors/features'
import {
  SCORE_REPORTING_SERVICES_BOOKLET_FIELD,
  SCORE_REPORTING_SERVICES_LABEL_FIELD,
  SCORE_REPORTING_SERVICES_LABELS,
} from '../../../constants/OrderConstants'

const SCORE_REPORTING_FORM_NAME = 'scoreReportServicesForm'
const BOOKLET_OPTIONS = [
  { label: 'No', value: false },
  { label: 'Yes', value: true },
]
const LABEL_OPTIONS = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
]

const mapStateToProps = state => {
  const {
    ordersScoreReportingServices: { fetching, error, locked, mainOrderSummary, unsubmittedChanges },
    settingsEducationPeriod: { selectedEducationPeriod, availableEducationPeriods },
    settingsDeadlines: { data: settingsDeadlinesData },
    user: {
      data: { selectedOrgId },
    },
  } = state

  const {
    freeResponseBookletDeadline,
    freeResponseBookletDeadlineIsPast,
    freeResponseBookletDownloadDeadline,
    freeResponseBookletDownloadDeadlineIsPast,
    scoreLabelDeadline,
    scoreLabelDeadlineIsPast,
  } = settingsDeadlinesData?.[selectedEducationPeriod] ?? {}
  const educationPeriodDescr = availableEducationPeriods[selectedEducationPeriod]?.descr

  return {
    fetching,
    requestError: error,
    currentFormValues: getFormValues(SCORE_REPORTING_FORM_NAME)(state) || {},
    initialValues: {
      [SCORE_REPORTING_SERVICES_BOOKLET_FIELD]: !isEmpty(
        unsubmittedChanges?.[SCORE_REPORTING_SERVICES_BOOKLET_FIELD]
      )
        ? unsubmittedChanges?.[SCORE_REPORTING_SERVICES_BOOKLET_FIELD]
        : mainOrderSummary?.[SCORE_REPORTING_SERVICES_BOOKLET_FIELD] ?? false,
      [SCORE_REPORTING_SERVICES_LABEL_FIELD]: !isEmpty(
        unsubmittedChanges?.[SCORE_REPORTING_SERVICES_LABEL_FIELD]
      )
        ? unsubmittedChanges?.[SCORE_REPORTING_SERVICES_LABEL_FIELD]
        : mainOrderSummary?.[SCORE_REPORTING_SERVICES_LABEL_FIELD] ?? 0,
    },
    educationPeriodCd: selectedEducationPeriod,
    educationPeriodDescr,
    orgId: selectedOrgId,
    freeResponseBookletDeadline: formatDate(
      freeResponseBookletDeadline,
      DATETIME_FORMATS.longMonthDayYear
    ),
    scoreLabelDeadline: formatDate(scoreLabelDeadline, DATETIME_FORMATS.longMonthDayYear),
    freeResponseBookletDeadlineIsPast,
    freeResponseBookletDownloadAvailable: freeResponseBookletDownloadDeadlineIsPast,
    freeResponseBookletDownloadMonth: formatDate(
      freeResponseBookletDownloadDeadline,
      DATETIME_FORMATS.longMonth
    ),
    freeResponseBookletDownloadYear: formatDate(
      freeResponseBookletDownloadDeadline,
      DATETIME_FORMATS.longYear
    ),
    scoreLabelDeadlineIsPast,
    locked,
    displayFreeResponseBookletDownload:
      mainOrderSummary?.[SCORE_REPORTING_SERVICES_BOOKLET_FIELD] ?? false,
    scoreLabelsEnabled: isFeatureEnabled(state, 'SCORE_LABELS'),
  }
}

const ScoreReportingService = ({ options, children, title, fieldName, normalize, disabled }) => {
  const titleId = `${fieldName}-label`

  return (
    <div className="row">
      <div className="col-md-10 col-sm-9">
        <h2 id={titleId} style={{ lineHeight: '48px' }}>
          {title}
        </h2>
        {children}
      </div>
      <div className="col-md-2 col-sm-3">
        <Select
          showEmptyOption={false}
          labelledBy={titleId}
          options={options}
          normalize={normalize}
          name={fieldName}
          disabled={disabled}
        />
      </div>
    </div>
  )
}

const getSummaryItems = currentFormValues => {
  const items = []
  const bookletVal = currentFormValues[SCORE_REPORTING_SERVICES_BOOKLET_FIELD]
  const labelVal = currentFormValues[SCORE_REPORTING_SERVICES_LABEL_FIELD]

  if (bookletVal)
    items.push({
      key: SCORE_REPORTING_SERVICES_BOOKLET_FIELD,
      value: SCORE_REPORTING_SERVICES_LABELS[SCORE_REPORTING_SERVICES_BOOKLET_FIELD],
    })

  if (labelVal)
    items.push({
      key: SCORE_REPORTING_SERVICES_LABEL_FIELD,
      value: `${SCORE_REPORTING_SERVICES_LABELS[SCORE_REPORTING_SERVICES_LABEL_FIELD]} (${labelVal})`,
    })

  return items
}

const OrderSummary = ({ orderingItems }) => {
  const summaryItems = getSummaryItems(orderingItems)

  return summaryItems.length ? (
    <ul className="list-unstyled">
      {summaryItems.map(({ key, value }) => (
        <li key={`summary_${key}`}>{value}</li>
      ))}
    </ul>
  ) : (
    <p>No items added</p>
  )
}

const ScoreReportServicesTab = ({
  handleSubmit,
  tabLabel,
  pristine,
  fetching,
  requestError,
  submitFailed,
  submitting,
  submitSucceeded,
  educationPeriodDescr,
  freeResponseBookletDeadline,
  scoreLabelDeadline,
  freeResponseBookletDeadlineIsPast,
  freeResponseBookletDownloadAvailable,
  freeResponseBookletDownloadMonth,
  freeResponseBookletDownloadYear,
  scoreLabelDeadlineIsPast,
  locked,
  displayFreeResponseBookletDownload,
  scoreLabelsEnabled,
  initialValues,
  currentFormValues,
  fetchScoreReportingServices,
  addFetchAction,
  removeFetchAction,
}) => {
  const [saved, setSaved] = useState(false)
  const educationPeriodYearEnd = educationPeriodDescr.split('-')[1]

  useEffect(() => {
    addFetchAction({
      name: 'fetchScoreReportingServices',
      action: (orgId, educationPeriodCd) => fetchScoreReportingServices(orgId, educationPeriodCd),
    })
    return () => removeFetchAction('fetchScoreReportingServices')
  }, [])

  useEffect(() => {
    if (submitSucceeded) {
      setSaved(true)
      setTimeout(() => {
        setSaved(false)
      }, 500)
    }
  }, [submitSucceeded])

  if (requestError && !submitFailed) {
    return <Error title="Error Retrieving Data" message={requestError} />
  }

  if (fetching && !submitting) {
    return <Loader />
  }

  return (
    <form className="row" aria-labelledby={tabLabel} role="tabpanel" onSubmit={handleSubmit}>
      {requestError ? <Error message={requestError} /> : null}
      <div className="col-md-9 col-sm-8">
        <ScoreReportingService
          title={SCORE_REPORTING_SERVICES_LABELS[SCORE_REPORTING_SERVICES_BOOKLET_FIELD]}
          fieldName={SCORE_REPORTING_SERVICES_BOOKLET_FIELD}
          normalize={val => val === 'true'}
          disabled={freeResponseBookletDeadlineIsPast && locked}
          options={BOOKLET_OPTIONS}
        >
          <p>
            (Optional) You may request students&#39; free response booklets from the AP Exam
            administration. Booklets requested by students and booklets for exams whose free
            response content has not been released after the exam won&#39;t be available. See Part 2
            of the <em>{educationPeriodDescr} AP Coordinator&#39;s Manual</em> for details.
          </p>
          <p>
            <strong>Deadline to order:</strong> {freeResponseBookletDeadline}.
          </p>
          <p>
            <strong>Cost:</strong> The cost is based on the number of booklets ordered by your
            school, not on the number of booklets received. $60 for 1-20 exams; $120 for 21-50
            exams; $180 for 51-100 exams; $300 for more than 100 exams.
          </p>
          <p>
            Please note, this service is only available for schools in the United States and Canada.
          </p>
          {displayFreeResponseBookletDownload ? (
            <>
              {freeResponseBookletDownloadAvailable ? (
                <>
                  <h3>Download Free Response Booklets</h3>
                  <p>
                    Expand this section to download individual subjects&#39; available free response
                    booklets. For the best experience, download no more than 5 subjects at a time.
                  </p>
                  <Accordions
                    id="downloadFRBAccordion"
                    panels={[
                      {
                        id: 'frbAccordion',
                        title: 'View Available Free Response Booklets',
                        panelId: 'frbDownloadPanel',
                        PanelBody: () => <DownloadFreeResponseBooklets />,
                        openByDefault: false,
                      },
                    ]}
                  />
                </>
              ) : (
                <>
                  <h3>Download Free Response Booklets</h3>
                  <p>
                    You&#39;ll be notified in {freeResponseBookletDownloadMonth}{' '}
                    {freeResponseBookletDownloadYear} once your school&#39;s free response booklets
                    are available for download.
                  </p>
                </>
              )}
            </>
          ) : null}
        </ScoreReportingService>
        {scoreLabelsEnabled ? (
          <>
            <hr />
            <ScoreReportingService
              title={SCORE_REPORTING_SERVICES_LABELS[SCORE_REPORTING_SERVICES_LABEL_FIELD]}
              fieldName={SCORE_REPORTING_SERVICES_LABEL_FIELD}
              normalize={val => parseInt(val, 10)}
              disabled={scoreLabelDeadlineIsPast && locked}
              options={LABEL_OPTIONS}
            >
              <p>
                (Optional) Score labels from the {educationPeriodYearEnd} AP Exam administration
                include student information. See Part 2 of the{' '}
                <em>{educationPeriodDescr} AP Coordinator&#39;s Manual</em> for details.
              </p>
              <p>
                <strong>Deadline to order:</strong> {scoreLabelDeadline}; shipping will begin in
                mid-July {educationPeriodYearEnd}.
              </p>
              <p>
                <strong>Cost:</strong> $100 per set
              </p>
            </ScoreReportingService>
          </>
        ) : null}
      </div>
      <div className="col-md-3 col-sm-4">
        <div style={{ backgroundColor: '#e0e0e0', padding: 15 }}>
          <h3>Order Summary</h3>
          <OrderSummary orderingItems={currentFormValues} />
          <ChangesSavedTextNotification inProp={saved} durationIn={50} durationOut={500}>
            <p
              className="success-text"
              style={{
                lineHeight: 'normal',
                marginBottom: 0,
                fontSize: '.9em',
                position: 'absolute',
              }}
            >
              <i
                className="cb-glyph cb-check-circle"
                aria-hidden={true}
                style={{ marginRight: 5 }}
              />
              <strong>Your changes have been saved.</strong>
            </p>
          </ChangesSavedTextNotification>
          <button
            type="submit"
            disabled={pristine || submitting}
            className="btn btn-sm btn-primary"
            style={{ width: '100%' }}
          >
            {submitting ? 'Updating...' : 'Update Order'}
          </button>
        </div>
      </div>
    </form>
  )
}

export default connect(mapStateToProps, {
  fetchScoreReportingServices,
  saveScoreReportingServices,
  addFetchAction,
  removeFetchAction,
})(
  reduxForm({
    form: SCORE_REPORTING_FORM_NAME,
    enableReinitialize: true,
    onSubmit: (data, dispatch, { orgId, educationPeriodCd, saveScoreReportingServices }) =>
      saveScoreReportingServices(orgId, educationPeriodCd, data),
  })(ScoreReportServicesTab)
)
