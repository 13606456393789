import { formatDateTime } from '@myap/ui-library/esm/date'
import { NAV_LINKS } from '../../../constants/NavConstants'
import { fetchDocusignSignedDocumentUrl } from '../../../actions/settingsOrg'
import { Loader } from '../../common'

const STYLE_MUTED = { color: '#888' }
const STYLE_ICON = { fontWeight: 700 }

const mapStateToProps = state => ({
  orgId: state.user.data.selectedOrgId,
  edPeriod: state.settingsEducationPeriod.selectedEducationPeriod,
  signNowDisabled: state.user.data.isCSR,
  partFormDisabled: false, // Not currently disabled for any user role
})

class SignatureStatus extends Component {
  constructor() {
    super()
    this.state = { fetching: false, error: false, url: undefined }
    this.getDocumentUrl = this.getDocumentUrl.bind(this)
    this.clickToViewForm = this.clickToViewForm.bind(this)
  }

  async getDocumentUrl() {
    const { orgId, edPeriod } = this.props
    const { data = {}, error } = await fetchDocusignSignedDocumentUrl(orgId, edPeriod)
    this.setState({ fetching: false, error: Boolean(error), ...data })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.url && prevState.url !== this.state.url) this.hiddenAnchor.click()
  }

  clickToViewForm(e) {
    e.preventDefault()
    this.setState({ fetching: true, error: false }, this.getDocumentUrl)
  }

  render() {
    const { orgId, edPeriod, srTitle, data, signNowDisabled, partFormDisabled } = this.props
    const { url, fetching, error } = this.state

    return (
      <>
        <h5>
          <span className="sr-only">{srTitle} </span>Signature Status
        </h5>
        {!data ? <div style={STYLE_MUTED}>No signature required</div> : null}

        {data && data.coordinatorSigned ? (
          <>
            <div className="success-text" style={STYLE_ICON}>
              <i className="cb-glyph cb-check" aria-hidden={true} /> Signed
            </div>
            <div style={STYLE_MUTED}>
              {formatDateTime(data.coordinatorSigningDate, 'MM/dd/yyyy h:mm a')}
            </div>
            <div style={{ position: 'relative', display: 'inline-block', margin: 10 }}>
              <a
                href="#"
                className={fetching || partFormDisabled ? 'disabled' : ''}
                onClick={this.clickToViewForm}
              >
                View Participation Form <span className="sr-only">(Download PDF)</span>
              </a>
              {fetching ? (
                <Loader size="sm" style={{ margin: 0, top: 3, right: -25, position: 'absolute' }} />
              ) : null}
              {error ? (
                <p className="cb-error-msg" role="alert" aria-live="polite">
                  Error: Could not download the participation form. Please try again.
                </p>
              ) : null}
              <a href={url} ref={node => (this.hiddenAnchor = node)} style={{ display: 'none' }} />
            </div>
          </>
        ) : null}

        {data && !data.coordinatorSigned ? (
          <>
            <div className="warning-text" style={STYLE_ICON}>
              <i className="cb-glyph cb-bell" aria-hidden={true} /> Awaiting signature
            </div>
            <button
              className="btn btn-sm btn-secondary"
              style={{ marginTop: 5, position: 'relative' }}
              disabled={signNowDisabled}
              onClick={() =>
                window.open(
                  `${
                    window.location.origin + NAV_LINKS.docusign
                  }?orgId=${orgId}&edPeriod=${edPeriod}`
                )
              }
            >
              Sign Now <span className="sr-only">Opens a new window</span>
            </button>
          </>
        ) : null}
      </>
    )
  }
}

export default connect(mapStateToProps)(SignatureStatus)
