import { isTakingExam } from '../../../selectors/section'
import { Tooltip } from '../../common'
import ListOfSelectedSSDMaterials from './ListOfSelectedSSDMaterials'
import ExamSSDApprovalStatus from './ExamSSDApprovalStatus'
import ExamSSDApprovalHistory from './ExamSSDApprovalHistory'
import { hasNonStockedApprovalStatus } from '../../../selectors/student'
import styles from '../../../assets/style/scss/student-details.scss'

const mapStateToProps = state => {
  const {
    settingsSSDMaterials: { allowedTestSSDMaterialCodes = {} },
    user: {
      data: { isLevelTwo },
    },
  } = state

  return {
    allowedTestSSDMaterialCodes,
    isLevelTwo,
  }
}

const specialExamMaterialsInfoMsg =
  "Order special exam materials if a student's approved or expected accommodation requires it. If a student has an accommodation but will be using a regular-format exam, you don't need to indicate any special exam materials."

const StudentDetailsSSDMaterialsSection = ({ exam, allowedTestSSDMaterialCodes, isLevelTwo }) => {
  const etsApprovalHistoryExists = hasNonStockedApprovalStatus(exam)
  const editMaterialsStyles = {
    display: 'inline-block',
    color: 'rgb(0, 119, 200)',
    fontSize: '8px',
  }
  const canShowSSD = () => {
    const ssdCodes = allowedTestSSDMaterialCodes[exam.testCd] || {}

    return (
      isTakingExam(exam) &&
      ssdCodes.allowedSSDMaterialCodes?.ssdallowed &&
      !exam.inactiveReason &&
      isLevelTwo
    )
  }
  const showSSD = canShowSSD()

  if (showSSD || etsApprovalHistoryExists) {
    return (
      <div className={`${styles['ssd-materials-container']} col-lg-5 col-md-4 col-xs-12`}>
        {showSSD && (
          <>
            <h5>
              Special Exam Materials
              <div style={{ position: 'absolute', top: 1, right: '-20px' }}>
                <Tooltip
                  title={specialExamMaterialsInfoMsg}
                  placement="top"
                  container="body"
                  label="More information: Special Exam Materials Information"
                >
                  <i
                    className="cb-glyph cb-glyph-xs cb-glyph-circular cb-question"
                    aria-hidden="true"
                    style={editMaterialsStyles}
                    data-alt={specialExamMaterialsInfoMsg}
                  />
                </Tooltip>
              </div>
            </h5>
            <div className={styles.ssd}>
              <ListOfSelectedSSDMaterials exam={exam} />
            </div>
          </>
        )}
        {etsApprovalHistoryExists && (
          <>
            {isTakingExam(exam) ? <hr style={{ margin: '10px 0' }} /> : null}
            <ExamSSDApprovalStatus exam={exam} />
            <ExamSSDApprovalHistory exam={exam} />
          </>
        )}
      </div>
    )
  }

  return null
}

export default connect(mapStateToProps)(StudentDetailsSSDMaterialsSection)
