/*
 * UserConstants
 * These are the variables that determine what our central data store changes in our state.
 * When you add a new action, you have to add a new constant here
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'YOUR_ACTION_CONSTANT';
 */

export const GET_USER_DETAILS_PENDING = 'GET_USER_DETAILS_PENDING'
export const GET_USER_DETAILS_FULFILLED = 'GET_USER_DETAILS_FULFILLED'
export const GET_USER_DETAILS_REJECTED = 'GET_USER_DETAILS_REJECTED'
export const GET_USER_DETAILS_NOACCESS = 'GET_USER_DETAILS_NOACCESS'

export const LOGOUT_USER = 'LOGOUT_USER'

export const USER_SET_SELECTED_ROLE = 'USER_SET_SELECTED_ROLE'

export const ACCEPT_TERMS_AND_CONDITIONS_PENDING = 'ACCEPT_TERMS_AND_CONDITIONS_PENDING'
export const ACCEPT_TERMS_AND_CONDITIONS_FULFILLED = 'ACCEPT_TERMS_AND_CONDITIONS_FULFILLED'
export const ACCEPT_TERMS_AND_CONDITIONS_REJECTED = 'ACCEPT_TERMS_AND_CONDITIONS_REJECTED'

export const SELECT_ROLE_PENDING = 'SELECT_ROLE_PENDING'
export const SELECT_ROLE_FULFILLED = 'SELECT_ROLE_FULFILLED'
export const SELECT_ROLE_REJECTED = 'SELECT_ROLE_REJECTED'

export const TAC_GENERAL_NAME = 'general'
export const TAC_FUNDING_NAME = 'funding'
export const TAC_GENERAL = 'GENERAL'
export const TAC_FUNDING = 'FUNDING'
