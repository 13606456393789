import { fetchStudentsBySection } from '../../../actions/studentsBySection'
import { Error, Loader, SortableTable } from '../../common'
import StudentEnrollmentRow from './StudentEnrollmentRow'
import { isAPCourse, isCareerKickstartCourse } from '../../../selectors/course'
import { sortStudentsByExamIntent } from '../../../selectors/student'

import stylesheet from '../../../assets/style/scss/section-details-table.scss'

const mapStateToProps = (state, { section = {} }) => ({
  ...state.studentsBySection,
  isFullAccessCourse:
    (isAPCourse(section) || isCareerKickstartCourse(section)) && !state.user.data.isLevelOne,
  teacherCanManageRoster: section.teacherCanManageRoster,
})

class SectionEnrollmentsTable extends Component {
  constructor(props) {
    super(props)
    this.renderStudents = this.renderStudents.bind(this)
  }

  componentDidMount() {
    const { section, fetchStudentsBySection } = this.props
    fetchStudentsBySection(section)
  }

  componentDidUpdate(nextProps) {
    const { section, fetchStudentsBySection } = this.props

    if (nextProps.section.sectionId !== section.sectionId) fetchStudentsBySection(section)
  }

  renderStudents(data) {
    const { isFullAccessCourse } = this.props

    return data.map(student => (
      <StudentEnrollmentRow
        key={student.enrollmentId}
        student={student}
        isFullAccessCourse={isFullAccessCourse}
      />
    ))
  }

  render() {
    const {
      students = [],
      fetching,
      error,
      isFullAccessCourse,
      teacherCanManageRoster,
    } = this.props
    const hStudent = {
      headerText: { title: 'Student Name' },
      headerAttributes: { className: `${stylesheet['student-name']}` },
      key: ['lastName', 'firstName'],
      sorting: 'asc',
      defaultSort: true,
    }
    const hExam = {
      headerText: { title: 'Order Exam?' },
      key: 'examIntent',
      sorting: 'asc',
      sortFunction: sortStudentsByExamIntent,
    }
    const hExamDate = {
      headerText: { title: 'Exam Date' },
      key: 'examDate',
    }
    const hActions = {
      headerText: { title: 'Section Enrollment Actions', srOnly: true },
      headerAttributes: { className: `${stylesheet.actions}` },
    }
    let columns = []
    if (isFullAccessCourse) {
      if (teacherCanManageRoster) {
        columns = [hStudent, hExam, hExamDate, hActions]
      } else {
        columns = [hStudent, hExam, hExamDate]
      }
    } else if (teacherCanManageRoster) {
      columns = [hStudent, hActions]
    } else {
      columns = [hStudent]
    }

    if (error)
      return (
        <div className="container">
          <Error
            title="Error Retrieving Data"
            message="An error occurred retrieving student enrollments. Please try again later."
          />
        </div>
      )

    if (fetching) return <Loader />

    if (!students.length) return <p>No students available</p>

    return (
      <div className="row">
        <div className="col-xs-12">
          <SortableTable
            tableAttributes={{
              summary:
                'Students are listed in ascending order by last name. Additional sorting options are available by clicking on the column headers.',
              className: `table cb-no-table-border ${stylesheet['section-details-table']}`,
            }}
            data={students}
            rowBuilder={this.renderStudents}
            columns={columns}
            tableCaption={{
              caption: 'A list of all students who have registered for this Section.',
              srOnly: true,
            }}
            tbody={true}
          />
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, { fetchStudentsBySection })(SectionEnrollmentsTable)
