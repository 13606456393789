import { pdf, Document } from '@react-pdf/renderer'
import { formatDate } from '@myap/ui-library/esm/date'
import { isPreAPCourse } from '../../../../selectors/course'
import { DropdownPortal } from '../../../common'
import ExamNARForm from '../pdfs/ExamNARForm'
import { openModal } from '../../../../actions/app'
import {
  dropStudentEnrollment,
  changeStudentSection,
  changeTestedWithAccommodations,
  toggleStudentActionMenu,
} from '../../../../actions/studentsByOrg'
import { reportNARGenerated } from '../../../../actions/studentsCommon'
import { isDroppedWithCost } from '../../../../selectors/student'
import { getExamWindowsForCourse } from '../../../../selectors/examWindows'
import { DIGITAL_EXAM } from '../../../../constants/SettingsConstants'
import {
  STUDENT_ENROLLMENT_STATUS_YES,
  STUDENT_ENROLLMENT_STATUS_UNUSED,
  DOWNLOAD_SUCCESS,
  DOWNLOAD_FAILED,
} from '../../../../constants/StudentConstants'

const mapStateToProps = (state, { rowData: { testCd } }) => {
  return {
    examWindows: getExamWindowsForCourse(state, testCd),
  }
}

const ActionsColumn = ({
  section,
  rowData,
  rowData: {
    firstName,
    lastName,
    name: courseName,
    studentId,
    reasonInactive,
    examFormat,
    testedWithAccomInd,
    menuOpen = false,
    examIntent,
    hasDigitalApplicableAccoms,
    approvedSSDAccommodations,
    testWindow,
  },
  examWindows,
  dropEnabled,
  updating,
  isTestedWithAccommAvailable,
  viewingPriorEdPd,
  schoolNameAndAddress,
  isNARAvailable,
  menuName,
  dropdownOptions,
  openModal,
  dropStudentEnrollment,
  changeStudentSection,
  changeTestedWithAccommodations,
  toggleStudentActionMenu,
}) => {
  const examDate = examWindows?.[testWindow]?.examDateTime
  const [testedWithAccom, setTestedWithAccom] = useState(testedWithAccomInd)
  const studentName = `${firstName} ${lastName}`
  const isPreAP = isPreAPCourse(rowData)

  const openActionModal = (e, type, options) => {
    e.preventDefault()
    openModal(type, {
      ...options,
      exam: rowData,
      studentName,
      storeLocation: 'studentsByOrg',
      modalCloseFocusElem: document.getElementById(`${menuName}${rowData.examId}`),
    })
  }

  const dropStudent = e => {
    openActionModal(e, 'DropStudentModal', {
      courseName,
      action: dropStudentEnrollment,
    })
  }

  const changeSection = e => {
    openActionModal(e, 'ChangeSectionModal', {
      courseName,
      section,
      action: changeStudentSection,
    })
  }

  const transferOut = e => {
    openActionModal(e, 'ConfirmTransferStudentOutModal', { studentId })
  }

  const toggleTestedWithAccom = e => {
    e.preventDefault()
    e.nativeEvent.stopImmediatePropagation()
    setTestedWithAccom(!testedWithAccom)
    changeTestedWithAccommodations({ testedWithAccomInd: !testedWithAccom, exam: rowData })
  }

  const generateNAR = async e => {
    e.preventDefault()
    const { aiCode, studentId, name: courseName, enrollmentId } = rowData
    const dateGenerated = formatDate(new Date(), 'MMddyyyy')
    try {
      const pdfBlob = await pdf(
        <Document>
          <ExamNARForm data={{ ...rowData, courseName, schoolNameAndAddress, examDate }} />
        </Document>
      ).toBlob()
      const url = URL.createObjectURL(pdfBlob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `NAR_${aiCode}_${studentId}_${courseName}_${dateGenerated}.pdf`)
      // Append to html link element page
      document.body.appendChild(link)
      // Start download
      link.click()
      // Clean up and remove the link
      link.parentNode.removeChild(link)
      // Clean up url for blob
      URL.revokeObjectURL(url)
      reportNARGenerated({ enrollmentIds: [enrollmentId], downloadStatus: DOWNLOAD_SUCCESS })
    } catch (err) {
      reportNARGenerated({ enrollmentIds: [enrollmentId], downloadStatus: DOWNLOAD_FAILED })
      openModal('GenerateNARErrorModal', {
        error: `There was an error generating the NAR. Please try again.`,
        modalCloseFocusElem: document.getElementById(`${menuName}${rowData.examId}`),
      })
    }
  }

  const generateMenuItems = () => {
    const disabled = (examFormat === DIGITAL_EXAM && hasDigitalApplicableAccoms) || viewingPriorEdPd

    if (isDroppedWithCost(rowData)) return [{ label: 'Transfer Out', clickEvent: transferOut }]

    if (reasonInactive) return []

    if (isPreAP)
      return [
        { label: 'Change Section', clickEvent: changeSection },
        ...(dropEnabled ? [{ label: 'Drop Student', clickEvent: dropStudent }] : []),
      ]

    return [
      { label: 'Change Section', clickEvent: changeSection },
      ...(dropEnabled ? [{ label: 'Drop Student', clickEvent: dropStudent }] : []),
      { label: 'Transfer Out', clickEvent: transferOut },
      ...(isTestedWithAccommAvailable &&
      (examIntent === STUDENT_ENROLLMENT_STATUS_YES ||
        examIntent === STUDENT_ENROLLMENT_STATUS_UNUSED)
        ? [
            {
              label: (
                <>
                  <i
                    className={`cb-glyph ${testedWithAccom ? 'cb-check-alt' : 'cb-box'}`}
                    style={{ position: 'absolute', left: '5px', top: '2px' }}
                  />
                  {disabled ? <span className="sr-only">(disabled) </span> : ''}Student tested with
                  an accommodation
                  <span className="sr-only"> ({testedWithAccom ? 'yes' : 'no'} selected)</span>
                </>
              ),
              clickEvent: toggleTestedWithAccom,
              stayOpen: true,
              gutterIcon: true,
              multiLine: true,
              disabled,
            },
          ]
        : []),
      ...(examIntent === STUDENT_ENROLLMENT_STATUS_YES &&
      approvedSSDAccommodations?.length > 0 &&
      isNARAvailable
        ? [
            {
              label: 'Generate NAR',
              clickEvent: generateNAR,
            },
          ]
        : []),
    ]
  }

  const menuOptions = {
    label: 'Actions',
    menuItems: generateMenuItems(),
  }
  // Only make the width of the menu fixed if at least one of the menuItems is multiLine
  if (menuOptions.menuItems.some(item => item.multiLine)) {
    menuOptions.fixWidth = 200
  }
  return menuOptions.menuItems.length ? (
    <DropdownPortal
      {...menuOptions}
      {...dropdownOptions}
      menuOpen={menuOpen}
      toggleOffFunc={() =>
        toggleStudentActionMenu({ enrollmentId: rowData.enrollmentId, menuOpen: false })
      }
    />
  ) : (
    <span className="sr-only">No Actions Available</span>
  )
}

export default connect(mapStateToProps, {
  openModal,
  dropStudentEnrollment,
  changeStudentSection,
  changeTestedWithAccommodations,
  toggleStudentActionMenu,
})(ActionsColumn)
