import { change as changeFieldValue } from 'redux-form'
import { Input } from '../../common'
import { ConditionalMaterialField } from '../common/SsdUtils'
import {
  SELECT_SSD_MATERIALS_FORM,
  ALLOWED_SSD_MATERIALS,
  SSD_MATERIALS_FIELDS,
} from '../../../constants/StudentConstants'

import styles from '../../../assets/style/scss/materials-modal.scss'

class NoSSDMaterials extends Component {
  componentDidUpdate() {
    const { selectedSSDMaterials, changeFieldValue } = this.props
    const { specialFormatsCheck, readerMaterialCheck, testingTimeCheck, otherAccommodationsCheck } =
      selectedSSDMaterials
    const anyChecked =
      specialFormatsCheck || readerMaterialCheck || testingTimeCheck || otherAccommodationsCheck

    // If any of the groups are active or any of the fields have values
    if (
      anyChecked &&
      selectedSSDMaterials[SSD_MATERIALS_FIELDS.ssdMaterialsNotOrdered.name] !== false
    ) {
      changeFieldValue(
        SELECT_SSD_MATERIALS_FORM,
        SSD_MATERIALS_FIELDS.ssdMaterialsNotOrdered.name,
        false
      )
    }
  }

  toggleGroup(e) {
    const { selectedSSDMaterials, changeFieldValue } = this.props

    if (!selectedSSDMaterials.ssdMaterialsNotOrdered) {
      // And turn off everything else
      if (selectedSSDMaterials.specialFormatsCheck)
        changeFieldValue(SELECT_SSD_MATERIALS_FORM, 'specialFormatsCheck', false)
      if (selectedSSDMaterials.readerMaterialCheck)
        changeFieldValue(SELECT_SSD_MATERIALS_FORM, 'readerMaterialCheck', false)
      if (selectedSSDMaterials.testingTimeCheck)
        changeFieldValue(SELECT_SSD_MATERIALS_FORM, 'testingTimeCheck', false)
      if (selectedSSDMaterials.otherAccommodationsCheck)
        changeFieldValue(SELECT_SSD_MATERIALS_FORM, 'otherAccommodationsCheck', false)
    }
  }

  render() {
    const { metaData, allowedSSDMaterialCodes } = this.props

    return (
      <ConditionalMaterialField
        allowedCodes={allowedSSDMaterialCodes}
        fieldName={ALLOWED_SSD_MATERIALS.notOrderedSSDMaterials}
        fieldId={SSD_MATERIALS_FIELDS.ssdMaterialsNotOrdered.value}
      >
        <div
          name="no-materials-group"
          className={`${styles['materials-fieldset']} ${styles['no-materials-ordered-fieldset']}`}
        >
          <p id="noMaterialsGroup">
            <strong>Optional:</strong> If this student won&#39;t be using any special exam materials
            for this exam, you may check the box below:
          </p>
          <div
            role="group"
            aria-labelledby="noMaterialsGroup"
            className={styles['materials-radiogroup']}
          >
            <Input
              type="checkbox"
              name={SSD_MATERIALS_FIELDS.ssdMaterialsNotOrdered.name}
              label={metaData[SSD_MATERIALS_FIELDS.ssdMaterialsNotOrdered.value].description}
              onChange={this.toggleGroup.bind(this)}
            />
          </div>
        </div>
      </ConditionalMaterialField>
    )
  }
}

export default connect(null, { changeFieldValue })(NoSSDMaterials)
