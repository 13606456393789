import { SelectAcademicYear } from '@myap/ui-library/esm/components'
import { setSelectedEducationPeriod } from '../../actions/settingsGlobal'
import { openModal } from '../../actions/app'

const mapStateToProps = (state, ownProps) => {
  const { unsavedForm, onRoleChange, style } = ownProps
  const {
    settingsEducationPeriod: { selectedEducationPeriod, availableEducationPeriods },
  } = state

  return {
    selectedEducationPeriod,
    availableEducationPeriods,
    unsavedForm,
    onRoleChange,
    style,
  }
}

export default connect(
  mapStateToProps,
  { setSelectedEducationPeriod, openModal }
)(props => {
  const {
    selectedEducationPeriod,
    availableEducationPeriods,
    unsavedForm,
    onRoleChange,
    setSelectedEducationPeriod,
    openModal,
    style,
  } = props
  return (
    <SelectAcademicYear
      selectedEducationPeriod={selectedEducationPeriod}
      availableEducationPeriods={availableEducationPeriods}
      selectYearAction={setSelectedEducationPeriod}
      unsavedForm={unsavedForm}
      unsavedChangesModal="ConfirmUnsavedChangesModal"
      onRoleChange={onRoleChange}
      style={style}
      openModal={openModal}
    />
  )
})
