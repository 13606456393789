import { Link } from 'react-router-dom'
import { DATETIME_FORMATS, formatDateTime } from '@myap/ui-library/esm/date'
import { Tooltip } from '@myap/ui-library/esm/components'
import { DIGITAL_EXAM } from '../../../constants/SettingsConstants'
import { NAV_LINKS } from '../../../constants/NavConstants'
import { COURSE_FILTER } from '../../../constants/FilterConstants'
import {
  COORDINATOR_TOOLTIP_HEADERS,
  TEACHER_TOOLTIP_HEADERS,
} from '../../../constants/ExamReadinessConstants'

import style from '../../../assets/style/scss/exam-readiness.scss'
import { formatExamWindow } from '../../../selectors/examWindows'

export const TooltipHeader = ({ column, isTeacher }) => {
  const tooltipHeaders = isTeacher ? TEACHER_TOOLTIP_HEADERS : COORDINATOR_TOOLTIP_HEADERS
  const { label, msg } = tooltipHeaders[column]
  return (
    <div className="table-header-tooltip">
      {label}
      <Tooltip title={msg} placement="top" container="body" label={`More information: ${label}`}>
        <i className="cb-glyph cb-glyph-circular cb-question" />
      </Tooltip>
    </div>
  )
}

export const StudentName = ({ rowData }) => (
  <div className={style['student-column']}>
    <Tooltip
      tag="a"
      title={`${rowData.lastName}, ${rowData.firstName}`}
      placement="top"
      container="body"
      label="More information: Student name"
    >
      {rowData.lastName}, {rowData.firstName}
    </Tooltip>
  </div>
)

export const CourseName = ({ rowData }) => (
  <Link to={`${NAV_LINKS.examreadiness}?${COURSE_FILTER.filter}=${rowData.testCode}`}>
    <Tooltip
      tag="span"
      title={rowData.courseName}
      placement="top"
      container="body"
      label="More information: Course name"
    >
      {rowData.courseName}
    </Tooltip>
  </Link>
)
export const ExamDate = ({ rowData }) => {
  const { examFormat, examDateTime } = rowData
  const { icon, text, zone } =
    examFormat === DIGITAL_EXAM
      ? { icon: 'computer', text: 'Digital', zone: 'ET' }
      : { icon: 'compose', text: 'Paper' }

  return (
    <div className={style['exam-date-column']}>
      <i aria-hidden={true} className={`cb-glyph cb-${icon}`} />
      <span className="sr-only">{text} Exam</span>
      <strong>{formatDateTime(examDateTime, DATETIME_FORMATS.shortMonthDay)}</strong>
      <span>{formatExamWindow(examDateTime, DATETIME_FORMATS.hour, examFormat)}</span>
      {zone && <span>{zone}</span>}
    </div>
  )
}

const Icon = ({ showAlert, showAlertAsWarning }) =>
  showAlert ? (
    <span className={`alert-icon ${showAlertAsWarning ? 'alert-icon-warning' : ''}`}>
      <span className="sr-only">{showAlertAsWarning ? 'Alert' : 'Warning'}</span>
      <i className="cb-glyph cb-glyph-circular cb-exclamation" aria-hidden={true} />
    </span>
  ) : null

export const Login = ({ rowData }) => (
  <>
    {rowData.appInstalledFormatted} <Icon {...rowData.appInstalledAlert} />
  </>
)

export const Practice = ({ rowData }) => rowData.practiceStatusFormatted

export const Setup = ({ rowData }) => (
  <>
    {rowData.setupStatusFormatted} <Icon {...rowData.setupStatusAlert} />
  </>
)

export const Checkin = ({ rowData }) => (
  <>
    {rowData.checkinStatusFormatted} <Icon {...rowData.checkinStatusAlert} />
  </>
)

export const Exam = ({ rowData, showCheckinStatus = false }) => {
  const {
    examStatus,
    examStatusFormatted,
    checkinStatusFormatted,
    examStatusAlert,
    checkinStatusAlert,
  } = rowData
  const showExamStatus = examStatus || !showCheckinStatus
  const text = showExamStatus ? examStatusFormatted : checkinStatusFormatted
  const alert = showExamStatus ? examStatusAlert : checkinStatusAlert
  return (
    <>
      {text} <Icon {...alert} />
    </>
  )
}
