import { EXAM_NAME } from '../../constants/ShipmentConstants'
import { Error, Loader, SSDMaterialsList } from '../common'
import { fetchAllowedSSDMaterials } from '../../actions/settingsGlobal'


const mapStateToProps = ({ settingsSSDMaterials:{ fetching, error, fetched }}) => {
  return {
    fetching,
    fetched,
    error,
  }
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    fetchAllowedSSDMaterials,
  }, dispatch)
)

export class NonstockExamTable extends Component {
  componentDidMount() {
    const { fetched, fetching, error, fetchAllowedSSDMaterials } = this.props
    if (!fetched && !fetching && !error)
      fetchAllowedSSDMaterials()
  }

  render() {
    const { fetching, error, suborder } = this.props
    if (error) {
      return (
        <div className="container" style={{ marginTop: '20px', width:'100%' }}>
          <Error title="Error Retrieving Data" message={error} />
        </div>
      )
    }

    if (fetching) {
      return <Loader />
    }

    return (
    <div className="cb-scrollbar-container" style={{ margin: '0 7px' }}>
      <div className="cb-scrollbar top">
      <div className="cb-scrollbar-thumb" />
      </div>

    <div className="table-responsive">
      <table className="table table-striped">
      <thead>
        <tr>
          <th scope="col">{EXAM_NAME}</th>
          <th scope="col">Quantity</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row"><p>{suborder.courseName}</p>
          {
            suborder.ssdMaterials.length > 0 && (
            <SSDMaterialsList selectedSSDMaterials={suborder.ssdMaterials}><div /></SSDMaterialsList>)
          }
          </th>
          <td>1</td>
        </tr>
      </tbody>
      </table>
    </div>
    </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NonstockExamTable)
