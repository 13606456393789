import { useHistory, useLocation } from 'react-router-dom'
import TeacherCourseRow from './teacher/TeacherCourseRow'
import ExamDecisionDeadlineNotice from '../common/ExamDecisionDeadlineNotice'
import { openModal } from '../../actions/app'
import { setNavLink, unsetNavLink, setReturnApp } from '../../actions/nav'
import { queryParamsToObj } from '../../utils/common'
import { sortColumnByKey } from '../../utils/sort'

const mapStateToProps = (state, ownProps) => ({
  courses: state.courses,
  returnApp: state.nav.returnApp,
})

const CourseRows = ({ courses: { result, entities } }) => {
  const courses = sortColumnByKey(
    result.courses.map(c => entities.courses[c]),
    ['courseName'],
    ['asc']
  )
  if (result.courses.length)
    return courses.map(course => <TeacherCourseRow key={course.testCd} course={course} />)

  return <li>No Courses available</li>
}

const TeacherCourses = ({
  courses,
  returnApp,
  openModal,
  setNavLink,
  unsetNavLink,
  setReturnApp,
}) => {
  const history = useHistory()
  const { pathname, search } = useLocation()
  const courseTestCd = queryParamsToObj(search)?.['create']
  const appFromQueryParam = queryParamsToObj(search)?.['returnapp'] || null
  const {
    result: { courses: resultCourses },
  } = courses
  const listStyles = {
    listStyleType: 'none',
    margin: '0',
    padding: '0',
  }

  useEffect(() => {
    const hasParams = appFromQueryParam || courseTestCd
    if (appFromQueryParam) {
      setReturnApp(appFromQueryParam)
    }
    if (courseTestCd) {
      openModal('CreateAndEditSectionModal', {
        courseTestCd,
        creating: true,
        orgIdUnknown: true,
      })
    }
    if (hasParams) history.replace(pathname)
  }, [])

  useEffect(() => {
    if (resultCourses.length) {
      let backLinkText = ''
      let link = ''
      switch (returnApp) {
        case 'apcentral':
          backLinkText = 'Back'
          link = Config.MYAP_URL.prof
          break

        default:
          backLinkText = 'Back to AP Classroom'
          link = Config.FINE_TUNE_URL
      }
      setNavLink(link, backLinkText)
    }

    return () => unsetNavLink()
  }, [courses])

  if (resultCourses.length)
    return (
      <div className="container" style={{ marginTop: '2em' }}>
        <div className="row">
          <div className="col-xs-12">
            <p>
              Set up your class sections so students can sign in, enroll online, and make their exam
              decisions. If you don&#39;t see one of your courses, please see{' '}
              <a href="http://www.collegeboard.org/apcourseaudit" target="_new">
                <span className="sr-only">Opens in new window </span>AP Course Audit
              </a>
              .
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <ExamDecisionDeadlineNotice isSectionDetails={false} />
            <div className="row">
              <div className="col-xs-12">
                <ul style={listStyles}>
                  <CourseRows courses={courses} />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )

  return null
}

export default connect(
  mapStateToProps,
  { openModal, setNavLink, unsetNavLink, setReturnApp }
)(TeacherCourses)
