import { OpenNewWindow } from '../common'

export default ({ linkName, disabled = false, linkUrl, description, newWindow = true }) => (
  <>
    <h2 className="roboto" style={{ marginBottom: 0, marginTop: 30 }}>
      {newWindow ? (
        <OpenNewWindow url={linkUrl} disabled={disabled}>
          {linkName}
        </OpenNewWindow>
      ) : (
        <a href={linkUrl} className={disabled ? 'disabled' : ''}>
          {linkName}
        </a>
      )}
    </h2>
    {description ? <p>{description}</p> : null}
  </>
)
