import { DownloadToCSV } from '../../common'
import { COURSE_SCHEDULE_TYPES } from '../../../constants/SectionConstants'
import { CODE_TYPES } from '../../../constants/SectionConstants'
import { sortColumnByKey } from '../../../utils/sort'
import memoize from 'memoizee'

const transformData = memoize((sections) => {

	const transformedSections = Object.keys(sections).map(key => {
		const s = sections[key]

		return {
			courseName: s.courseName,
			sectionName: s.sectionName,
			sectionType: COURSE_SCHEDULE_TYPES[s.sectionType].label,
			teachers: s.sectionTeachers.map(t => t.teacherName).join('; '),
			maxEnroll: s.maxNumberOfStudents,
			enrollCount: s.enrollmentCount,
			[CODE_TYPES.join.prop]: s[CODE_TYPES.join.prop]
		}
	})

	return sortColumnByKey(transformedSections, 'courseName', 'asc')
})

const MainHeader = (orgName) => [
	{label: `Class sections for ${orgName}`, key: 'courseName'},
	{label: '', key: 'sectionName'},
	{label: '', key: 'sectionType'},
	{label: '', key: 'teachers'},
	{label: '', key: 'maxEnroll'},
	{label: '', key: 'enrollCount'},
	{label: '', key: CODE_TYPES.join.prop}
]

const RowHeaders = { courseName: 'Course Name', sectionName: 'Section Name', sectionType: 'Section Type',
	teachers: 'Teacher Name(s)', maxEnroll: 'Max Student Registrations', enrollCount: 'Student Registrations', [CODE_TYPES.join.prop]: 'Active Join Code' }


const mapStateToProps = state => {
	const { user: { data: { selectedRole, roles }}, courses: { entities: { sections }}} = state

	return {
		sections: transformData(sections),
		header: MainHeader(roles[selectedRole].orgName)
	}
}


export default connect(mapStateToProps)(({ header, sections }) => <DownloadToCSV filename="section-info"
	header={header} title="Section Info with Join Codes" timestampHeaderKey="courseName" data={[RowHeaders, ...sections]} />)

