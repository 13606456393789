import { normalize, schema } from 'normalizr'

const sectionSchema = new schema.Entity(
  'sections',
  {},
  {
    idAttribute: 'sectionId',
    processStrategy: entity => {
      const { sectionTeachers, ...sectionData } = entity
      // The purpose of this is to remove the sectionTeacherId property
      const processedTeachers = sectionTeachers.map(teacher => {
        delete teacher.sectionTeacherId
        return teacher
      })
      return {
        ...sectionData,
        sectionTeachers: processedTeachers,
        fetching: entity.fetching || false,
        fetched: entity.fetched || false,
        error: entity.error || null,
      }
    },
  }
)

const ssdMetaDataSchema = new schema.Entity('metaData', {}, { idAttribute: 'ssdMaterialCd' })
const allowedSSDMaterialCodesSchema = new schema.Entity(
  'allowedTestSSDMaterialCodes',
  {},
  { idAttribute: 'testCd' }
)

const courseSchema = new schema.Entity(
  'courses',
  { sections: [sectionSchema] },
  { idAttribute: 'testCd' }
)
const maxAllowedSchema = new schema.Entity('items', {}, { idAttribute: 'ancillaryCd' })
const selectedSecureMaterialsSchema = new schema.Entity('items', {}, { idAttribute: 'ancillaryCd' })

export const normalizeCourses = data => normalize(data, { courses: [courseSchema] })
export const normalizeSection = data => normalize(data, sectionSchema)

export const normalizeSSDMaterials = data => ({
  metaData: normalize(data.metaData, [ssdMetaDataSchema]).entities.metaData,
  allowedTestSSDMaterialCodes: normalize(data.allowedTestSSDMaterialCodes, [
    allowedSSDMaterialCodesSchema,
  ]).entities.allowedTestSSDMaterialCodes,
})

export const normalizeMaxAllowedSecureMaterials = data => normalize(data, [maxAllowedSchema])
export const normalizeSelectedSecureMaterialsForCourse = data =>
  normalize(data, [selectedSecureMaterialsSchema])
