import { useLocation } from 'react-router-dom'
import CoordinatorCourseRow from './coordinator/CoordinatorCourseRow'
import CoordinatorCourseFilters from '../filters/course/CoordinatorCourseFilters'
import { SortableTable } from '../common'
import {
  filterCoordinatorCourses,
  getCoursesWithoutSectionsCount,
  getCoursesSectionCount,
  isAPCourse,
  isCareerKickstartCourse,
} from '../../selectors/course'
import { getFilters } from '../../actions/filters'
import { sortColumnByKey } from '../../utils/sort'
import AddSectionButton from './common/AddSectionButton'
import DownloadSectionInfoButton from './common/DownloadSectionInfoButton'
import { COORDINATOR_COURSE_VIEW_BYCOURSE } from '../../constants/CourseConstants'

import stylesheet from '../../assets/style/scss/courses-table.scss'

const headerColumns = [
  {
    headerText: { title: 'Course Name' },
    key: 'courseName',
    sorting: 'asc',
    headerAttributes: { id: 'CoursesCourseName' },
    defaultSort: true,
  },
  {
    headerText: { title: 'Section' },
    headerAttributes: { id: 'CoursesSection' },
  },
  {
    headerText: { title: 'Teacher' },
    headerAttributes: { id: 'CoursesTeacher' },
  },
  {
    headerText: { title: 'Student Registrations' },
    headerAttributes: { id: 'CoursesOnlineEnrollment', className: `${stylesheet.enrollments}` },
  },
  {
    headerText: { title: 'Exam Registrations' },
    headerAttributes: { id: 'CoursesExamDecision', className: `${stylesheet['exam-decision']}` },
  },
  {
    headerText: { title: 'Actions', srOnly: true },
    headerAttributes: { id: 'CoursesActions', className: `${stylesheet.actions}` },
  },
]

const CoordinatorCourses = ({ courses }) => {
  const { search } = useLocation()
  const activeFilters = getFilters(search)
  const [view, setView] = useState(COORDINATOR_COURSE_VIEW_BYCOURSE)
  const visibleCourses = filterCoordinatorCourses(courses, activeFilters) || []
  const visibleCoursesLen = visibleCourses.length
  const totalCourseCount = courses?.result?.courses?.length || 0
  const coursesWithoutSectionsCount = getCoursesWithoutSectionsCount(courses)
  const isCourseView = view === COORDINATOR_COURSE_VIEW_BYCOURSE
  const showCourseTable = isCourseView ? visibleCoursesLen : getCoursesSectionCount(visibleCourses)

  return (
    <div>
      {totalCourseCount ? (
        <CoordinatorCourseFilters
          visibleCourses={visibleCourses}
          toggleView={setView}
          view={view}
          activeFilters={activeFilters}
        />
      ) : null}
      <div className="container">
        <div className="row" style={{ marginTop: totalCourseCount ? 10 : 40, marginBottom: 10 }}>
          {totalCourseCount ? (
            <div className="col-xs-6" style={{ lineHeight: '24px' }}>
              <DownloadSectionInfoButton />
            </div>
          ) : null}
          <div className={`col-xs-${totalCourseCount ? '6' : '12'} text-right`}>
            <AddSectionButton />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            {showCourseTable ? (
              <SortableTable
                tableAttributes={{
                  summary:
                    'Courses are listed in ascending order by Course name. Additional sorting options are available by clicking on the COURSE NAME column header. Use the filter controls above to filter the Courses list down further.',
                  className: `table cb-no-table-border ${stylesheet['coordinator-courses-table']} ${
                    isCourseView ? stylesheet['course-view'] : ''
                  }`,
                }}
                data={visibleCourses}
                columns={headerColumns}
                rowBuilder={courses =>
                  courses.map(c => (
                    <CoordinatorCourseRow
                      key={c.testCd}
                      course={c}
                      isCourseView={isCourseView}
                      showExamDecisions={isAPCourse(c) || isCareerKickstartCourse(c)}
                      courseSections={sortColumnByKey(c.sections, 'sectionName', 'asc')}
                      headerData={headerColumns}
                    />
                  ))
                }
                tableCaption={{
                  caption:
                    'A consolidated list of all of the Courses that have been created for your organization.',
                  srOnly: true,
                }}
                tbody={false}
                stickyHeader={true}
              />
            ) : null}

            {!totalCourseCount ? <p>No {isCourseView ? 'courses' : 'sections'} available</p> : null}

            {!isCourseView && coursesWithoutSectionsCount ? (
              <p>
                There are {coursesWithoutSectionsCount} course
                {coursesWithoutSectionsCount.length !== 1 && 's'} that have no sections defined.
                <button
                  type="button"
                  className="btn-link"
                  onClick={() => setView(COORDINATOR_COURSE_VIEW_BYCOURSE)}
                >
                  View all courses
                </button>
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(state => ({ courses: state.courses }))(CoordinatorCourses)
