import {
  isDateAfter,
  isDateBefore,
  parseDateTime,
  manipulateDays,
  manipulateHours,
  manipulateMinutes,
  manipulateSeconds,
  formatDateTime,
  formatDate,
} from '@myap/ui-library/esm/date'
import { isAdministeringDigitalExams } from './settings'
import { sortColumnByKey } from '../utils/sort'
import {
  PAPER_EXAM,
  DIGITAL_EXAM,
  DIGITAL_EXAM_OPT_OUT_PERIOD_DAYS,
  DIGITAL_EXAM_OPT_OUT_PERIOD_START_TIME,
  ADMIN_WINDOW_DISPLAY_NAME_REF_DATA,
} from '../constants/SettingsConstants'
import { isEmpty } from '../utils/common'

export const getUnzonedDateTime = dateTime => {
  const dateTimeLen = dateTime?.length
  // If dateTime includes a time zone
  if (dateTimeLen === 22) {
    // Need to remove the time zone so parseDateTime() doesn't return a zoned time
    return dateTime.substring(0, dateTimeLen - 6)
  }
  return dateTime
}

export const getExamWindowsForCourse = (state, testCd) => {
  const {
    user: {
      data: { selectedOrgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod },
  } = state
  return state.settingsExamWindows.data?.[selectedOrgId]?.[selectedEducationPeriod]?.[testCd] || {}
}

export const getSortedExamWindowsForCourse = (state, testCd, availableWindows = []) => {
  const examWindows = getExamWindowsForCourse(state, testCd)
  let examWindowsArray = Object.values(examWindows)
  if (availableWindows.length) {
    examWindowsArray = examWindowsArray.filter(examWindow => {
      const index = availableWindows.findIndex(item => item.code === examWindow.adminWindow)
      return index >= 0
    })
  }
  const sortedWindows = sortColumnByKey(examWindowsArray, ['sequence'], ['asc'])
  return sortedWindows
}

export const getAllExamWindows = state => {
  const {
    user: {
      data: { selectedOrgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod },
  } = state
  return state.settingsExamWindows.data?.[selectedOrgId]?.[selectedEducationPeriod] || {}
}

export const getExamWindowRefData = state => {
  const {
    user: {
      data: { selectedOrgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod },
  } = state
  return (
    state.settingsExamWindows.examWindowRefData?.[selectedOrgId]?.[selectedEducationPeriod] || {}
  )
}

/**
 * Does not require state, simply returns an array of exam windows sorted by sequence within an examWindowData object
 * Example input:
 * examWindowData = {
 *   ADMIN3: { adminWindow: 'ADMIN3', displayName: 'Admin 3', sequence: 3 },
 *   ADMIN1: { adminWindow: 'ADMIN1', displayName: 'Admin 1', sequence: 1 },
 *   ADMIN2: { adminWindow: 'ADMIN2', displayName: 'Admin 2', sequence: 2 },
 * }
 **/
export const getSortedExamWindowRefData = examWindowRefData =>
  sortColumnByKey(Object.values(examWindowRefData), ['sequence'], ['asc'])

export const getUnlockableExamWindows = state => {
  const sortedExamWindows = getSortedExamWindowRefData(getExamWindowRefData(state))
  return sortedExamWindows.filter(adminWindow => adminWindow.requiresUnlocking)
}

export const courseHasDigitalExams = (state, testCd, availableWindows) => {
  const examWindows = getSortedExamWindowsForCourse(state, testCd, availableWindows)
  return examWindows.some(examWindow => examWindow.examFormat === DIGITAL_EXAM)
}

export const isAfterLastExamWindowCutOff = (state, testCd, examFormat = null) => {
  const examWindows = getSortedExamWindowsForCourse(state, testCd)
  // Courses with only one admin do not have exams
  if (examWindows.length > 1) {
    let lastExam
    const reversedExamWindows = examWindows.reverse()
    if (examFormat) {
      lastExam = reversedExamWindows.find(examWindow => examWindow.examFormat === examFormat)
    } else {
      lastExam = reversedExamWindows[0]
    }
    return isDateAfter(new Date(), lastExam?.examDateTime)
  }
  return false
}

export const getAvailableDigitalExamWindows = (state, testCd, availableWindows) => {
  const sortedExamWindows = getSortedExamWindowsForCourse(state, testCd, availableWindows)
  return sortedExamWindows.filter(examWindow => examWindow.examFormat === DIGITAL_EXAM)
}

export const isDigitalExamInCutoffPeriod = (state, { testCd, testWindow: adminWindow }) => {
  // We're going to ignore isAdministeringDigitalExams and just look at whether the exam is digital
  if (isExamDigital(state, testCd, adminWindow) /* && isAdministeringDigitalExams(state)*/) {
    const parsedExamDateTime = getExamDay(state, testCd, adminWindow)
    const [addHours, addMinutes, addSeconds] = DIGITAL_EXAM_OPT_OUT_PERIOD_START_TIME.split(':')
    let cutOff = manipulateDays(parsedExamDateTime, DIGITAL_EXAM_OPT_OUT_PERIOD_DAYS)
    cutOff = manipulateHours(cutOff, parseInt(addHours, 10))
    cutOff = manipulateMinutes(cutOff, parseInt(addMinutes, 10))
    cutOff = manipulateSeconds(cutOff, parseInt(addSeconds, 10))
    return isDateAfter(new Date(), cutOff)
  }
  return false
}

export const getFutureExamWindows = (state, testCd, availableWindows) => {
  const sortedExamWindows = getSortedExamWindowsForCourse(state, testCd, availableWindows)
  return sortedExamWindows.filter(
    examWindow =>
      isDateBefore(new Date(), examWindow.examDateTime) &&
      (examWindow.examFormat === PAPER_EXAM ||
        (examWindow.examFormat === DIGITAL_EXAM &&
          !isDigitalExamInCutoffPeriod(state, {
            testCd: examWindow.testCd,
            testWindow: examWindow.adminWindow,
          })))
  )
}

export const isExamPaper = (state, testCd, adminWindow) => {
  const admin = getExamWindowsForCourse(state, testCd)
  return admin[adminWindow]?.examFormat === PAPER_EXAM
}

export const isExamDigital = (state, testCd, adminWindow) => {
  const admin = getExamWindowsForCourse(state, testCd)
  return admin[adminWindow]?.examFormat === DIGITAL_EXAM
}

export const getExamFormats = ({ state, allExamWindows, testCd, adminWindow }) => {
  const examWindowDataForCourse = !isEmpty(state)
    ? getExamWindowsForCourse(state, testCd)
    : !isEmpty(allExamWindows)
    ? allExamWindows[testCd]
    : {}
  const examFormat = examWindowDataForCourse?.[adminWindow]?.examFormat ?? PAPER_EXAM
  const noEndOfCourseExam = examWindowDataForCourse?.[adminWindow]?.noEndOfCourseExam ?? false
  return {
    paper: examFormat === PAPER_EXAM,
    digital: examFormat === DIGITAL_EXAM,
    noEndOfCourseExam,
  }
}

export const getExamDay = (state, testCd, adminWindow) => {
  try {
    const {
      [adminWindow]: { examDateTime },
    } = getExamWindowsForCourse(state, testCd)
    // Get to midnight on the day of the exam
    const [examDate, examTime] = examDateTime.split('T')
    return parseDateTime(`${examDate}T00:00:00-04:00`)
  } catch (err) {
    // it's possible for this to fail if exam-window-config data for a particular edpd
    // has not been fetched yet, so fail silently, it'll get there eventually
    return
  }
}

// isDigital means the exam is digital AND administeringDigitalExams for this education period
export const formatExamWindow = (date, dateFormat, isDigital, showSuffix = false) => {
  const operativeDate = !isDigital ? getUnzonedDateTime(date) : date
  return `${
    isDigital
      ? formatDateTime(operativeDate, dateFormat)
      : formatDate(parseDateTime(operativeDate), dateFormat)
  }${showSuffix && isDigital ? ' ET' : ''}`
}

// Used when building exam window reference data, some admin windows should use a predefined
// displayName
export const getAdminWindowDisplayNameForRefData = ({ adminWindow, displayName }) => {
  const specialAdminWindows = Object.keys(ADMIN_WINDOW_DISPLAY_NAME_REF_DATA)
  if (specialAdminWindows.includes(adminWindow)) {
    return ADMIN_WINDOW_DISPLAY_NAME_REF_DATA[adminWindow]
  }
  return displayName
}
