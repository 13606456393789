import memoize from 'memoizee'
import {
  STUDENT_ENROLLMENT_STATUS,
  SSD_APPROVED,
  STUDENT_ENROLLMENT_STATUS_YES,
} from '../constants/StudentConstants'
import { DIGITAL_EXAM } from '../constants/SettingsConstants'
import { sortColumnByKey } from '../utils/sort'
import { filterData } from './filters/utils'
import { STUDENT_FILTER_FUNCTIONS } from './filters/studentFilters'
import { isTakingExam, isUnusedExam } from './section'
import { getUnzonedDateTime } from './examWindows'

export const isTransferred = memoize(({ reasonInactive }) => reasonInactive === 'T')
export const isDropped = memoize(({ reasonInactive }) => reasonInactive === 'D')
export const isDroppedWithCost = memoize(
  ({ reasonInactive, droppedWithCost }) => isDropped({ reasonInactive }) && droppedWithCost
)
export const isMerged = memoize(({ reasonInactive }) => reasonInactive === 'M')

// etsDecisionType values: NSSSD, EXCEPTION, ORDERS_AFTER_DEADLINE
export const hasNonStockedApprovalStatus = memoize(
  ({ etsDecisionType }) => etsDecisionType === 'NSSSD'
)
export const isEditAllowed = memoize(
  ({ etsApprovalStatus, etsDecisionType }) =>
    !(etsApprovalStatus === SSD_APPROVED && etsDecisionType === 'NSSSD')
)

export const canChangeReasonAndLocation = exam => {
  const { unused } = exam
  return isTakingExam(exam) && !unused
}

export const canChangeExamWindow = exam => {
  const { unused, isCurrentExam, testWindows, noEndOfCourseExam } = exam
  return (
    ((isTakingExam(exam) && !unused && !noEndOfCourseExam) ||
      (isCurrentExam && isUnusedExam(exam))) &&
    testWindows.length > 1
  )
}

export const canChangeTestedWithAccoms = exam => {
  const { isCurrentExam, hasDigitalApplicableAccoms, noEndOfCourseExam } = exam
  return (
    (!hasDigitalApplicableAccoms && isTakingExam(exam) && !noEndOfCourseExam) ||
    (isCurrentExam && isUnusedExam(exam) && !isTransferred(exam) && !isDropped(exam))
  )
}

export const getSectionInformation = memoize(
  ({ sectionMap }, { sectionId }) => sectionMap[sectionId] || {}
)

export const sortStudentsByExamIntent = memoize((data, key, sorting) => {
  const examIntentOrder = Object.keys(STUDENT_ENROLLMENT_STATUS)
  const orderedByExamIntent = data.sort(
    (a, b) => examIntentOrder.indexOf(a[key]) - examIntentOrder.indexOf(b[key])
  )
  return sortColumnByKey(orderedByExamIntent, ['lastName', 'firstName'], sorting)
})

const getEnrollmentData = (
  exam,
  enrollmentAttrs,
  studentId,
  sectionMap,
  accommodationCategoryCodes,
  isCurrentExam = false
) => ({
  ...exam,
  ...enrollmentAttrs,
  examId: exam?.examId || enrollmentAttrs.enrollmentId,
  studentId,
  unused: exam?.examId ? exam?.unused : false, // this should be a temporary fix, while backend works to send examId when currentExam is unused
  testCd: sectionMap[enrollmentAttrs.sectionId]?.testCd,
  ...(accommodationCategoryCodes ? { accommodationCategoryCodes } : {}),
  isCurrentExam,
})

const calculateExamConflicts = ({ exams, examWindowsData }) => {
  const TIMEZONELEN = 6 // ex: -04:00
  const numExams = exams.length

  for (let currentExam = 0; currentExam < numExams; currentExam += 1) {
    delete exams[currentExam].hasConflict
    const {
      testCd: currentTestCd,
      examId: currentExamId,
      testWindow: currentTestWindow,
      examIntent: currentExamIntent,
    } = exams[currentExam]
    // Pre-AP courses don't have entries in exam-window-config
    const currentExamDateTime =
      examWindowsData[currentTestCd]?.[currentTestWindow]?.examFormat === DIGITAL_EXAM
        ? examWindowsData[currentTestCd]?.[currentTestWindow]?.examDateTime.substring(
            0,
            examWindowsData[currentTestCd]?.[currentTestWindow]?.examDateTime.length - TIMEZONELEN
          ) ?? null
        : examWindowsData[currentTestCd]?.[currentTestWindow]?.examDateTime ?? null
    const currentNoEndOfCourseExam =
      examWindowsData[currentTestCd]?.[currentTestWindow]?.noEndOfCourseExam ?? true
    for (let compareExam = 0; compareExam < numExams; compareExam += 1) {
      const {
        testCd: compareTestCd,
        examId: compareExamId,
        testWindow: compareTestWindow,
        examIntent: compareExamIntent,
      } = exams[compareExam]
      // Pre-AP courses don't have entries in exam-window-config
      const compareExamDateTime =
        examWindowsData[compareTestCd]?.[compareTestWindow]?.examFormat === DIGITAL_EXAM
          ? examWindowsData[compareTestCd]?.[compareTestWindow]?.examDateTime.substring(
              0,
              examWindowsData[compareTestCd]?.[compareTestWindow]?.examDateTime.length - TIMEZONELEN
            ) ?? null
          : examWindowsData[compareTestCd]?.[compareTestWindow]?.examDateTime ?? null
      const compareNoEndOfCourseExam =
        examWindowsData[compareTestCd]?.[compareTestWindow]?.noEndOfCourseExam ?? true
      if (
        currentExamId !== compareExamId &&
        !currentNoEndOfCourseExam &&
        !compareNoEndOfCourseExam &&
        currentExamIntent === STUDENT_ENROLLMENT_STATUS_YES &&
        compareExamIntent === STUDENT_ENROLLMENT_STATUS_YES
      ) {
        if (getUnzonedDateTime(currentExamDateTime) === getUnzonedDateTime(compareExamDateTime)) {
          exams[currentExam].hasConflict = exams[compareExam]
          //console.log(
          //  `Exam conflict found examId: ${currentExamId} conflicts with examId: ${compareExamId}`
          //)
        }
      }
    }
  }
  return exams
}

// make old school for loop because it still has better performance (x2)
const reduceEnrollments = ({
  enrollments,
  studentId,
  sectionMap,
  examWindowsData,
  accommodationCategoryCodes,
}) => {
  const len = enrollments.length
  const exams = []

  for (let i = 0; i < len; i += 1) {
    const { unusedExams, currentExam, ...enrollmentAttrs } = enrollments[i]
    const processed = [
      getEnrollmentData(
        currentExam,
        enrollmentAttrs,
        studentId,
        sectionMap,
        accommodationCategoryCodes,
        true
      ),
      ...unusedExams.map(u =>
        getEnrollmentData(u, enrollmentAttrs, studentId, sectionMap, accommodationCategoryCodes)
      ),
    ]
    exams.push(...processed)
  }

  // Some operations do not impact exam dates and therefore exam conflicts do not need to be
  // calculated
  if (Object.keys(examWindowsData).length > 0) {
    return calculateExamConflicts({ exams, examWindowsData })
  }
  return exams
}

// make old school for loop because it still has better performance (x4)
export const processStudentsByOrgData = ({ students, sectionMap, examWindowsData }) => {
  const studentMap = {}
  const exams = []
  const len = students.length

  for (let i = 0; i < len; i += 1) {
    const { studentId, enrollments, accommodationCategoryCodes, ...attrs } = students[i]
    studentMap[studentId] = attrs
    const addedEnrollments = reduceEnrollments({
      enrollments,
      studentId,
      sectionMap,
      examWindowsData,
      accommodationCategoryCodes,
    })
    exams.push(...addedEnrollments)
  }

  return {
    studentMap,
    exams,
  }
}

export const processStudentData = ({
  student: { enrollments, studentId, accommodationCategoryCodes, ...data },
  sectionMap,
  examWindowsData = {},
}) => ({
  studentMap: { [studentId]: { ...data, enrollments } },
  exams: reduceEnrollments({
    enrollments,
    studentId,
    sectionMap,
    examWindowsData,
    accommodationCategoryCodes,
  }),
})

export const processStudentAndSubsidyData = ({
  student: { enrollments, studentId, accommodationCategoryCodes, ...student },
  sectionMap,
  examWindowsData = {},
  subsidies,
}) => ({
  studentMap: { [studentId]: { ...student, enrollments } },
  exams: reduceEnrollments({
    enrollments,
    studentId,
    sectionMap,
    examWindowsData,
    accommodationCategoryCodes,
  }),
  subsidies,
})

const mappedExams = memoize(
  (exams, dataMaps) => {
    const { studentMap, courseMap, sectionMap } = dataMaps
    const arr = []
    const numOfExams = exams.length
    for (let i = 0; i < numOfExams; i += 1) {
      const exam = exams[i]
      const { studentId, testCd, sectionId } = exam
      arr[i] = {
        ...exam,
        ...studentMap[studentId],
        ...courseMap[testCd],
        testProgramCd: courseMap[testCd]?.testProgram,
        sectionTeachers: sectionMap[sectionId]?.teacherIds,
      }
    }
    return arr
  },
  { length: 1 }
)

// sort/filter
// create array with merged data and after sort/filter map back to exams array
export const sortStudentExams = memoize((exams, sortBy, direction, dataMaps) => {
  const mergedData = mappedExams(exams, dataMaps)
  const isStudentSort = sortBy === 'studentName'
  const sortElements = isStudentSort
    ? ['lastName', 'firstName', 'studentId', 'name']
    : ['name', 'lastName', 'firstName', 'studentId']
  const sortDirection = isStudentSort ? [direction, direction, 'ASC', 'ASC'] : direction
  const sorted = sortColumnByKey(mergedData, sortElements, sortDirection)
  return sorted
})

export const filterExams = memoize(
  (filters, exams, dataMaps) => {
    const mergedData = mappedExams(exams, dataMaps)
    if (!Object.keys(filters).length) return mergedData
    const filtered = filterData(mergedData, filters, STUDENT_FILTER_FUNCTIONS)
    return filtered
  },
  { length: 2 }
)

export const getTotalVisibleStudents = memoize(
  (visibleExams, studentMap) => {
    return Object.keys(studentMap).filter(s => visibleExams.find(e => e.studentId === s)).length
  },
  { length: 1 }
)

export const getTotalStudents = memoize(studentMap => Object.keys(studentMap).length)

export const hasDigitalExams = state => {
  return state.studentsByOrg.exams.some(({ examFormat }) => examFormat === DIGITAL_EXAM)
}
