import style from '../../../assets/style/scss/table-utils.scss'

class SortableTableHeaderItem extends Component {
  static defaultProps = {
    headerProps: {},
    sorting: false,
  }

  onClick(e) {
    e.preventDefault()
    if (this.props.sorting) this.props.onClick(this.props.index, this.props.sorting)
  }

  render() {
    const { sorting, direction, headerText, headerAttributes } = this.props
    const sortTerms = {
      asc: 'ascending',
      desc: 'descending',
    }

    const sortDirection =
      sorting && this.props.active === this.props.index ? sortTerms[direction] : null
    const Display = () => (
      <span className={headerText.srOnly ? 'sr-only' : undefined}>{headerText.title}</span>
    )

    return (
      <th
        {...headerAttributes}
        role="columnheader"
        aria-sort={sortDirection}
        data-colsort={sorting ? sortDirection || 'none' : null}
      >
        {sorting ? (
          <a href="#" onClick={this.onClick.bind(this)} aria-label={`Sort ${headerText.title}`}>
            <Display />
            <i
              className={`cb-glyph cb-sort${sortDirection ? `-${direction}` : ''}`}
              aria-hidden="true"
            />
          </a>
        ) : (
          <Display />
        )}
      </th>
    )
  }
}

export default class SortableTableHeader extends Component {
  header = createRef()
  columnWidths = []

  componentDidMount() {
    this.setColumnWidths()
  }

  componentDidUpdate() {
    this.setColumnWidths()
  }

  setColumnWidths() {
    const columns = this.header.current.querySelectorAll('tr > th')

    if (!this.shouldHaveStickyHeader())
      this.columnWidths = Array.from(columns).map(c => c.getBoundingClientRect().width)
  }

  onClick(index, sorting) {
    this.props.onStateChange.bind(this)(index, sorting)
  }

  getHeaderColumn(column, index) {
    const { sorting } = this.props
    const savedWidth = this.columnWidths[index]
    const columnStyle = this.shouldHaveStickyHeader() && savedWidth ? { width: savedWidth } : {}

    return (
      <SortableTableHeaderItem
        sorting={column.sorting}
        direction={sorting}
        key={index}
        index={index}
        active={this.props.active}
        headerText={column.headerText}
        headerAttributes={{ ...column.headerAttributes, style: columnStyle }}
        onClick={this.onClick.bind(this, index, sorting)}
      />
    )
  }

  shouldHaveStickyHeader() {
    return Number.isInteger(this.props.stickyHeaderOffset)
  }

  render() {
    const { columns, stickyHeaderOffset } = this.props
    const style = this.shouldHaveStickyHeader()
      ? { position: 'fixed', top: stickyHeaderOffset }
      : {}

    return (
      <thead ref={this.header} style={style}>
        <tr role="row">{columns.map((c, i) => this.getHeaderColumn(c, i))}</tr>
      </thead>
    )
  }
}
