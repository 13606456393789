import { getFormValues, change } from 'redux-form'
import get from 'lodash-es/get'
import {
  Text,
  Input,
  PositiveNumberValidation,
  PositiveMaximumNumberValidation,
} from '../../common/'
import { percentFormat } from '../../../utils/numbers'
import {
  SCHOOL_INFORMATION_FORM,
  SCHOOL_INFORMATION_FIELDS as FIELDS,
  DEVICE_IPAD,
  DEVICE_CHROMEBOOKS,
  DEVICE_MAC,
  DEVICE_WINDOWS,
  DEVICE_NONE,
  DEVICE_TYPES,
  DISTRIBUTED_IND,
  AVAILABLE_IND,
} from '../../../constants/SetupConstants'

import stylesheet from '../../../assets/style/scss/settings-form.scss'

export default connect(
  state => {
    const {
      settingsEducationPeriod: { availableEducationPeriods, selectedEducationPeriod },
    } = state
    const currentFormValues = getFormValues(SCHOOL_INFORMATION_FORM)(state) ?? {}
    return {
      currentFormValues,
      yearDesc: availableEducationPeriods?.[selectedEducationPeriod]?.descr,
    }
  },
  { change }
)(({ currentFormValues, yearDesc, change }) => {
  const handleDeviceChange = (deviceChecked, indName) => {
    const noneChecked = get(
      currentFormValues,
      `${FIELDS.DEVICE_AVAILABILITY.name}.${DEVICE_NONE}.${indName}`
    )
    if (deviceChecked && noneChecked) {
      change(
        SCHOOL_INFORMATION_FORM,
        `${FIELDS.DEVICE_AVAILABILITY.name}.${DEVICE_NONE}.${indName}`,
        false
      )
    }
  }
  const handleNoneChange = (noneChecked, indName) => {
    if (noneChecked) {
      change(
        SCHOOL_INFORMATION_FORM,
        `${FIELDS.DEVICE_AVAILABILITY.name}.${DEVICE_CHROMEBOOKS}.${indName}`,
        false
      )
      change(
        SCHOOL_INFORMATION_FORM,
        `${FIELDS.DEVICE_AVAILABILITY.name}.${DEVICE_IPAD}.${indName}`,
        false
      )
      change(
        SCHOOL_INFORMATION_FORM,
        `${FIELDS.DEVICE_AVAILABILITY.name}.${DEVICE_MAC}.${indName}`,
        false
      )
      change(
        SCHOOL_INFORMATION_FORM,
        `${FIELDS.DEVICE_AVAILABILITY.name}.${DEVICE_WINDOWS}.${indName}`,
        false
      )
    }
  }

  return (
    <>
      <div className="row" style={{ marginTop: '48px' }}>
        <div className="col-xs-12">
          <Text
            name={FIELDS.STUDENTS_USING_PROVIDED_DEVICES.name}
            label={
              <>
                For completing school assignments either at home or at school, approximately what
                percentage of AP students regularly uses or will use a device provided by the school
                or district in the {yearDesc} school year? (i.e., a school-owned laptop, tablet or
                Chromebook)
              </>
            }
            placeholder="Enter a percentage"
            isRequired={FIELDS.STUDENTS_USING_PROVIDED_DEVICES.required}
            validate={[PositiveNumberValidation, PositiveMaximumNumberValidation]}
            maxlength={3}
            normalize={value => {
              if (!Number.isNaN(parseInt(value, 10))) {
                return parseInt(value, 10)
              }
              return value
            }}
            inputStyle={{ width: '300px' }}
          />
        </div>
      </div>
      <div style={{ marginTop: '24px' }}>
        <div className="row">
          <div className="col-xs-12">
            <p className="cb-required">
              Which of the following devices has or will your school distribute or make available to
              AP students for use in the {yearDesc} school year? Select all that apply.{' '}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-6">
            <table className={`table ${stylesheet['device-questions-table']}`}>
              <thead>
                <tr>
                  <td></td>
                  <td>Distribute 1:1 to students for individual use</td>
                  <td>Make available to students via laptop carts</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">{DEVICE_TYPES[DEVICE_CHROMEBOOKS]}</th>
                  <td>
                    <Input
                      type="checkbox"
                      name={`${FIELDS.DEVICE_AVAILABILITY.name}.${DEVICE_CHROMEBOOKS}.${DISTRIBUTED_IND}`}
                      onChange={val => handleDeviceChange(val.target.checked, DISTRIBUTED_IND)}
                    />
                  </td>
                  <td>
                    <Input
                      type="checkbox"
                      name={`${FIELDS.DEVICE_AVAILABILITY.name}.${DEVICE_CHROMEBOOKS}.${AVAILABLE_IND}`}
                      onChange={val => handleDeviceChange(val.target.checked, AVAILABLE_IND)}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">{DEVICE_TYPES[DEVICE_IPAD]}</th>
                  <td>
                    <Input
                      type="checkbox"
                      name={`${FIELDS.DEVICE_AVAILABILITY.name}.${DEVICE_IPAD}.${DISTRIBUTED_IND}`}
                      onChange={val => handleDeviceChange(val.target.checked, DISTRIBUTED_IND)}
                    />
                  </td>
                  <td>
                    <Input
                      type="checkbox"
                      name={`${FIELDS.DEVICE_AVAILABILITY.name}.${DEVICE_IPAD}.${AVAILABLE_IND}`}
                      onChange={val => handleDeviceChange(val.target.checked, AVAILABLE_IND)}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">{DEVICE_TYPES[DEVICE_MAC]}</th>
                  <td>
                    <Input
                      type="checkbox"
                      name={`${FIELDS.DEVICE_AVAILABILITY.name}.${DEVICE_MAC}.${DISTRIBUTED_IND}`}
                      onChange={val => handleDeviceChange(val.target.checked, DISTRIBUTED_IND)}
                    />
                  </td>
                  <td>
                    <Input
                      type="checkbox"
                      name={`${FIELDS.DEVICE_AVAILABILITY.name}.${DEVICE_MAC}.${AVAILABLE_IND}`}
                      onChange={val => handleDeviceChange(val.target.checked, AVAILABLE_IND)}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">{DEVICE_TYPES[DEVICE_WINDOWS]}</th>
                  <td>
                    <Input
                      type="checkbox"
                      name={`${FIELDS.DEVICE_AVAILABILITY.name}.${DEVICE_WINDOWS}.${DISTRIBUTED_IND}`}
                      onChange={val => handleDeviceChange(val.target.checked, DISTRIBUTED_IND)}
                    />
                  </td>
                  <td>
                    <Input
                      type="checkbox"
                      name={`${FIELDS.DEVICE_AVAILABILITY.name}.${DEVICE_WINDOWS}.${AVAILABLE_IND}`}
                      onChange={val => handleDeviceChange(val.target.checked, AVAILABLE_IND)}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">{DEVICE_TYPES[DEVICE_NONE]}</th>
                  <td>
                    <Input
                      type="checkbox"
                      name={`${FIELDS.DEVICE_AVAILABILITY.name}.${DEVICE_NONE}.${DISTRIBUTED_IND}`}
                      onChange={val => handleNoneChange(val.target.checked, DISTRIBUTED_IND)}
                    />
                  </td>
                  <td>
                    <Input
                      type="checkbox"
                      name={`${FIELDS.DEVICE_AVAILABILITY.name}.${DEVICE_NONE}.${AVAILABLE_IND}`}
                      onChange={val => handleNoneChange(val.target.checked, AVAILABLE_IND)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
})
