import { Tooltip } from '../../common'
import { isTakingExam } from '../../../selectors/section'
import ListOfSelectedSpecialDigitalFormats from './ListOfSelectedSpecialDigitalFormats'
import { DIGITAL_EXAM } from '../../../constants/SettingsConstants'
import styles from '../../../assets/style/scss/student-details.scss'

const tooltipMsg =
  'Review the accommodations indicated for the student’s digital exam. If the student doesn’t wish to use any of the accommodations on their digital exam, de-select the accommodation and submit the change to your exam order.'

const mapStateToProps = state => {
  const {
    user: {
      data: { isLevelTwo },
    },
  } = state

  return {
    isLevelTwo,
  }
}

const StudentDetailsSpecialDigitalFormatsSection = connect(mapStateToProps)(
  ({ exam, isLevelTwo }) => {
    const editMaterialsStyles = {
      display: 'inline-block',
      color: 'rgb(0, 119, 200)',
      fontSize: '8px',
    }
    const canShowSDF = () =>
      isTakingExam(exam) && !exam.inactiveReason && isLevelTwo && exam.examFormat === DIGITAL_EXAM
    const showSDF = canShowSDF()

    if (showSDF) {
      return (
        <div className={`${styles['ssd-materials-container']} col-lg-5 col-md-4 col-xs-12`}>
          <h5>
            Digital Exam: Special Format
            <div style={{ position: 'absolute', top: 1, right: '-20px' }}>
              <Tooltip
                title={tooltipMsg}
                placement="top"
                container="body"
                label="More information: Digital Exam: Special Format Information"
              >
                <i
                  className="cb-glyph cb-glyph-xs cb-glyph-circular cb-question"
                  aria-hidden="true"
                  style={editMaterialsStyles}
                  data-alt={tooltipMsg}
                />
              </Tooltip>
            </div>
          </h5>
          <div className={styles.ssd}>
            <ListOfSelectedSpecialDigitalFormats exam={exam} />
          </div>
        </div>
      )
    }
    return null
  }
)

export default connect(mapStateToProps)(StudentDetailsSpecialDigitalFormatsSection)
