const Tooltip = ({
  tag = 'a',
  isDisabled,
  title,
  container,
  placement,
  label,
  style = {},
  children,
}) => {
  const containerRef = useRef(null)
  const { SPACE, ENTER, ESC } = APRICOT.utils.keys

  useEffect(() => {
    $(containerRef.current).tooltip({ container, placement })
  })

  return React.createElement(
    tag,
    {
      ref: containerRef,
      'data-toggle': 'tooltip',
      'data-original-title': title,
      onFocus: () => $(containerRef.current).tooltip('show'),
      onBlur: () => $(containerRef.current).tooltip('hide'),
      onKeyDown: e => {
        if (e.keyCode === SPACE || e.keyCode === ENTER) {
          $(containerRef.current).tooltip('show')
        }
        if (e.keyCode === ESC) {
          $(containerRef.current).tooltip('hide')
        }
      },
      'aria-label': label,
      role: 'button',
      'aria-disabled': isDisabled,
      tabIndex: 0,
      style,
    },
    children
  )
}

export default Tooltip
