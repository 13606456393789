import { Link } from 'react-router-dom'
import DisplaySectionTeacher from '../common/DisplaySectionTeacher'
import { openModal } from '../../../actions/app'
import { CREATE_FORM_EXAM_ONLY_VALUE, CODE_TYPES } from '../../../constants/SectionConstants'
import { SECTION_FILTER, COURSE_FILTER } from '../../../constants/FilterConstants'
import { SecondSemesterIcon, WeServiceIcon, Dropdown } from '../../common'
import { numberFormat } from '../../../utils/numbers'
import { replaceSpaceWithUnderscore } from '../../../utils/common'

import stylesheet from '../../../assets/style/scss/courses-table.scss'

const mapStateToProps = state => {
  const {
    user: {
      data: { isLevelTwo },
    },
  } = state
  return { isLevelTwo }
}

const mapDispatchToProps = dispatch => bindActionCreators({ openModal }, dispatch)

export class CoordinatorCourseSectionRow extends Component {
  editSection(e) {
    const { section, openModal } = this.props
    e.preventDefault()

    openModal('CreateAndEditSectionModal', {
      section,
      creating: false,
      modalCloseFocusElem: this.modalCloseFocusElem,
    })
  }

  deleteSection(e) {
    const {
      section: { sectionId },
    } = this.props
    e.preventDefault()
    this.props.openModal('SectionDeleteModal', {
      sectionId,
      modalCloseFocusElem: this.modalCloseFocusElem,
    })
  }

  viewJoinCode(e) {
    e.preventDefault()
    this.props.openModal('SectionCodeModal', {
      sectionId: this.props.section.sectionId,
      useCode: CODE_TYPES.join,
      modalCloseFocusElem: this.modalCloseFocusElem,
    })
  }

  viewTransferCode(e) {
    e.preventDefault()
    this.props.openModal('SectionCodeModal', {
      sectionId: this.props.section.sectionId,
      useCode: CODE_TYPES.transfer,
      modalCloseFocusElem: this.modalCloseFocusElem,
    })
  }

  ActionDropdown(id) {
    const actionItems = {
      label: 'Actions',
      id,
      menuItems: [
        { label: 'Edit Section', clickEvent: this.editSection.bind(this) },
        { label: 'Delete Section', clickEvent: this.deleteSection.bind(this) },
        { label: 'See Join Code', clickEvent: this.viewJoinCode.bind(this) },
        {
          label: 'See Transfer Code',
          clickEvent: this.viewTransferCode.bind(this),
        },
      ],
    }
    return actionItems
  }

  renderSectionTeachers(teachers) {
    const teacherListStyle = {
      listStyleType: 'none',
      margin: 0,
      padding: 0,
    }

    const teacherList = teachers.map((teacher, index) => (
      <DisplaySectionTeacher key={index} teacherName={teacher.teacherName} />
    ))

    return teachers.length > 0 ? <ul style={teacherListStyle}>{teacherList}</ul> : '-'
  }

  render() {
    const { section, isSectionView, showExamDecisions = true, headerData } = this.props
    const examOnlyStyle = { fontStyle: 'italic' }

    return (
      <tr className={stylesheet['section-row']}>
        {isSectionView ? (
          <td
            headers={`${headerData[0].headerAttributes.id} ${replaceSpaceWithUnderscore(
              section.courseName
            )}`}
            className={stylesheet['course-name']}
          >
            <Link to={`/students?${COURSE_FILTER.filter}=${section.testCd}`}>
              {section.courseName}
            </Link>
          </td>
        ) : null}
        <td
          headers={`${headerData[1].headerAttributes.id} ${replaceSpaceWithUnderscore(
            section.courseName
          )}`}
          className={stylesheet['section-name']}
        >
          <WeServiceIcon weService={section.weService} />
          <SecondSemesterIcon sectionType={section.sectionType} />
          <Link to={`/students?${SECTION_FILTER.filter}=${section.sectionId}`}>
            {section.sectionName}
          </Link>
        </td>
        <td
          headers={`${headerData[2].headerAttributes.id} ${replaceSpaceWithUnderscore(
            section.courseName
          )}`}
          className={stylesheet.teachers}
        >
          {section.sectionType === CREATE_FORM_EXAM_ONLY_VALUE ? (
            <span style={examOnlyStyle}>Exam Only</span>
          ) : (
            this.renderSectionTeachers(section.sectionTeachers)
          )}
        </td>
        <td
          headers={`${headerData[3].headerAttributes.id} ${replaceSpaceWithUnderscore(
            section.courseName
          )}`}
          className={stylesheet.enrolled}
        >
          {numberFormat(section.enrollmentCount) || 0}/{numberFormat(section.maxNumberOfStudents)}
        </td>
        <td
          headers={`${headerData[4].headerAttributes.id} ${replaceSpaceWithUnderscore(
            section.courseName
          )}`}
          className={stylesheet['taking-exam']}
        >
          {showExamDecisions
            ? `${numberFormat(section.examCount)}/${numberFormat(section.enrollmentCount)}`
            : null}
        </td>
        <td
          headers={`${headerData[5].headerAttributes.id} ${replaceSpaceWithUnderscore(
            section.courseName
          )}`}
          className={stylesheet.actions}
          ref={node =>
            (this.modalCloseFocusElem = node && node.querySelectorAll('.dropdown-toggle')[0])
          }
        >
          <Dropdown {...this.ActionDropdown(section.sectionId)} rightAligned={true} />
        </td>
      </tr>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoordinatorCourseSectionRow)
