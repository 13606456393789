import { useParams } from 'react-router-dom'
import { setNavLink, unsetNavLink } from '../../actions/nav'
import { fetchStatusSummary } from '../../actions/status'
import StudentDetails from '../../components/students/coordinator/StudentDetails'
import { isFeatureEnabled } from '../../selectors/features'
import { isEmpty } from '../../utils/common'

const mapStateToProps = state => {
  const {
    status: { data: status, fetched },
    settingsEducationPeriod: { selectedEducationPeriod },
    user: {
      data: { selectedOrgId },
    },
  } = state
  return {
    selectedOrgId,
    selectedEducationPeriod,
    status,
    fetched,
    statusPanelEnabled: isFeatureEnabled(state, 'STATUS_PANEL'),
  }
}

const StudentDetailsPage = ({
  setNavLink,
  unsetNavLink,
  selectedOrgId,
  selectedEducationPeriod,
  status,
  fetched,
  statusPanelEnabled,
  fetchStatusSummary,
}) => {
  const { studentId } = useParams()

  useEffect(() => {
    setNavLink('/students', 'Back to Students')

    // need order status, only fetch if hasn't data doesn't exist
    if (statusPanelEnabled && isEmpty(status) && !fetched) {
      fetchStatusSummary(selectedOrgId, selectedEducationPeriod)
    }

    return () => unsetNavLink()
  }, [status, fetched, selectedOrgId, selectedEducationPeriod, statusPanelEnabled])

  return (
    <CSSTransition
      classNames="animate-fade"
      in={true}
      timeout={500}
      appear={true}
      enter={false}
      exit={false}
    >
      <div className="container">
        <StudentDetails studentId={studentId} />
      </div>
    </CSSTransition>
  )
}

export default connect(mapStateToProps, { setNavLink, unsetNavLink, fetchStatusSummary })(
  StudentDetailsPage
)
