/*
 * MessageConstants
 * These are the variables that contain the text which is displayed on certain errors
 *
 * Follow this format:
 * export const YOUR_CONSTANT = 'Your message text';
 */

export const DEFAULT_REQUEST_ERROR = 'An error has occurred. Please try again later.'

//page access error
export const GENERIC_PAGE_ACCESS_ERROR =
  'You may not have access to the page. Let us think about it and get back to you later.'

//section errors
export const CREATE_SECTION_ERROR =
  'An error occurred creating this section. Please try again later.'
export const EDIT_SECTION_ERROR = 'An error occurred editing this section. Please try again later.'
export const UPDATE_SECTION_JOINCODE_ERROR =
  'An error occurred creating a new join code. Please try again later.'
export const SECTION_TRANSFERCODE_ERROR =
  'An error occurred creating a new transfer code. Please try again later.'
export const DELETE_SECTION_ERROR =
  'An error occurred deleting this section. Please try again later.'
export const DELETE_SECTION_ENROLLED_ERROR =
  'You cannot delete a section when students are enrolled. To remove this section, first drop the students.'
export const CONFIRM_SECTION_EXAM_DECISION_ERROR =
  'An error occurred confirming this section. Please try again later.'
export const TEACHER_GRANT_ACCESS_SEARCH_ERROR =
  'No results found. Please ensure the email address is correct and try again.'

export const FETCH_STUDENT_EXAM_REGISTRATIONS_ERROR =
  'An error occurred retrieving exam registrations. Please try again later.'
