import Clipboard from 'clipboard'
import { Loader } from '../../common'
import { updateJoinCode, generateTransferCode } from '../../../actions/section'
import { isEmpty } from '../../../utils/common'
import { CODE_TYPES } from '../../../constants/SectionConstants'
import style from '../../../assets/style/scss/join-code.scss'

const mapStateToProps = (state, ownProps) => {
  const { isInModal = true, sectionId, useCode } = ownProps
  const { [sectionId]: section } = state.courses.entities.sections
  const isJoinCode = useCode.name === CODE_TYPES.join.name
  const isTransferCode = useCode.name === CODE_TYPES.transfer.name

  return {
    section,
    isJoinCode,
    isTransferCode,
    isInModal,
    useCode,
  }
}

const SectionCode = ({
  isInModal,
  isJoinCode,
  isTransferCode,
  useCode,
  section,
  section: { courseName, sectionName, sectionTeachers, sectionId, enrollmentCount, fetching },
  updateJoinCode,
  generateTransferCode,
}) => {
  const clipboardRef = useRef(null)

  let clipboard

  const code = section[useCode.prop]
  const buttonClass = `btn btn-sm btn-secondary ${style['join-code-buttons']}`
  let message = ''
  if (isJoinCode) {
    if (!enrollmentCount) {
      message += 'No students have signed up yet. '
    }
    message += 'Share this code so students can sign in and enroll in this section.'
  } else if (isTransferCode) {
    message +=
      'Share this code only with students who have transferred into your school from a different school. Enrolling with this code allows transfer students to register for the exam without incurring a late fee.'
  }

  useEffect(() => {
    if (isTransferCode && isEmpty(section[useCode.prop])) {
      generateTransferCode(section)
    }

    clipboard = new Clipboard(clipboardRef.current)
  }, [])

  return (
    <>
      {isInModal ? (
        <h5 className="h2" style={{ marginTop: '0' }}>
          {courseName} - {sectionName}
        </h5>
      ) : null}
      <p className="cb-small-font-size">{message}</p>
      <div className="text-center">
        {fetching ? (
          <div style={{ display: 'flex', alignItems: 'center', height: '146px' }}>
            <Loader />
          </div>
        ) : (
          <div className={`cb-digital-gray-bg ${style['join-code-container']}`}>
            <div
              className="roboto-slab-bold text-center"
              style={{ fontSize: '56px', padding: '0 20px', lineHeight: 'normal' }}
            >
              {code}
            </div>
            <div className={style['join-code-url']} id={`${useCode.name}CodeModal`}>
              https://myap.collegeboard.org/
            </div>
            <button
              type="button"
              data-clipboard-target={`#${useCode.name}CodeModal`}
              className={`btn-link ${style['copy-to-clipboard']}`}
              ref={clipboardRef}
            >
              <i className="cb-glyph cb-series" aria-hidden={true} />
              <span className="sr-only">Copy url to clipboard</span>
            </button>
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-xs-6">
          <button
            type="button"
            className={buttonClass}
            disabled={fetching}
            onClick={() => window.open(`/courses/${useCode.url}/${sectionId}`)}
          >
            <span className="sr-only">Opens in new window</span>Print Code
          </button>
          <p className="cb-small-font-size">Print an info sheet to post or hand out.</p>
        </div>
        <div className="col-xs-6">
          <button
            type="button"
            className={buttonClass}
            disabled={fetching}
            onClick={() => {
              if (isJoinCode) {
                updateJoinCode(section)
              } else if (isTransferCode) {
                generateTransferCode(section)
              }
            }}
          >
            Expire Code
          </button>
          <p className="cb-small-font-size">
            Deactivate the current {useCode.text} code and generate a new one.
          </p>
        </div>
      </div>
    </>
  )
}

export default connect(
  mapStateToProps,
  { updateJoinCode, generateTransferCode }
)(SectionCode)
