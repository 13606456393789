import { Text, PositiveNumberValidation, Tooltip } from '../../common'
import { SCHOOL_INFORMATION_FIELDS as FIELDS } from '../../../constants/SetupConstants'
import { STUDENT_POPULATION_TOOLTIP } from '../../../constants/SettingsConstants'

const normalizeValue = value => {
  if (!Number.isNaN(parseInt(value, 10))) {
    return parseInt(value, 10)
  }
  // return the original value so it can be handled by validators
  return value
}

export default props => (
  <div>
    {!props.hideTitle ? (
      <div>
        <h4 className="h2" style={{ marginTop: 40 }}>
          Student Population{' '}
          <div style={{ display: 'inline-block' }}>
            <Tooltip
              title={STUDENT_POPULATION_TOOLTIP}
              placement="top"
              container="body"
              label="More information: Student Population"
            >
              <i
                aria-hidden="true"
                className="cb-glyph cb-glyph-xs cb-glyph-circular cb-exclamation"
                style={{ display: 'inline-block', fontSize: '8px' }}
                data-alt={STUDENT_POPULATION_TOOLTIP}
              />
            </Tooltip>
          </div>
        </h4>
        <p>These figures should include all students in your school, not just AP students.</p>
      </div>
    ) : null}
    <fieldset>
      {!props.hideTitle ? (
        <legend className="h4" style={{ border: 0, marginBottom: 24, fontSize: '1.1em' }}>
          Number of students by grade{' '}
          <span className="cb-roboto" style={{ fontWeight: 400 }}>
            (including Non-AP students)
          </span>
        </legend>
      ) : null}
      <div className="row">
        <div className="col-xs-6">
          <Text
            label={
              <>
                9<sup>th</sup> Grade
              </>
            }
            name={FIELDS.POP9.name}
            placeholder="Enter population"
            isRequired={FIELDS.POP9.required}
            validate={[PositiveNumberValidation]}
            maxlength={4}
            normalize={normalizeValue}
          />
        </div>
        <div className="col-xs-6">
          <Text
            label={
              <>
                10<sup>th</sup> Grade
              </>
            }
            name={FIELDS.POP10.name}
            placeholder="Enter population"
            isRequired={FIELDS.POP10.required}
            validate={[PositiveNumberValidation]}
            maxlength={4}
            normalize={normalizeValue}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-6">
          <Text
            label={
              <>
                11<sup>th</sup> Grade
              </>
            }
            name={FIELDS.POP11.name}
            placeholder="Enter population"
            isRequired={FIELDS.POP11.required}
            validate={[PositiveNumberValidation]}
            maxlength={4}
            normalize={normalizeValue}
          />
        </div>
        <div className="col-xs-6">
          <Text
            label={
              <>
                12<sup>th</sup> Grade
              </>
            }
            name={FIELDS.POP12.name}
            placeholder="Enter population"
            isRequired={FIELDS.POP12.required}
            validate={[PositiveNumberValidation]}
            maxlength={4}
            normalize={normalizeValue}
          />
        </div>
      </div>
    </fieldset>
  </div>
)
