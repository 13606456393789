export const FETCH_COURSES_PENDING = 'FETCH_COURSES_PENDING'
export const FETCH_COURSES_FULFILLED = 'FETCH_COURSES_FULFILLED'
export const FETCH_COURSES_REJECTED = 'FETCH_COURSES_REJECTED'

export const FETCH_AVAILABLE_COURSES_PENDING = 'FETCH_AVAILABLE_COURSES_PENDING'
export const FETCH_AVAILABLE_COURSES_FULFILLED = 'FETCH_AVAILABLE_COURSES_FULFILLED'
export const FETCH_AVAILABLE_COURSES_REJECTED = 'FETCH_AVAILABLE_COURSES_REJECTED'

export const FETCH_ORGS_BY_COURSE_PENDING = 'FETCH_ORGS_BY_COURSE_PENDING'
export const FETCH_ORGS_BY_COURSE_FULFILLED = 'FETCH_ORGS_BY_COURSE_FULFILLED'
export const FETCH_ORGS_BY_COURSE_REJECTED = 'FETCH_ORGS_BY_COURSE_REJECTED'

export const FETCH_COURSE_TYPES_PENDING = 'FETCH_COURSE_TYPES_PENDING'
export const FETCH_COURSE_TYPES_FULFILLED = 'FETCH_COURSE_TYPES_FULFILLED'
export const FETCH_COURSE_TYPES_REJECTED = 'FETCH_COURSE_TYPES_REJECTED'

export const UPDATE_SECTION_AFTER_STUDENT_MOVED_OR_DROPPED =
  'UPDATE_SECTION_AFTER_STUDENT_MOVED_OR_DROPPED'

export const COORDINATOR_COURSE_VIEW_BYCOURSE = 'c'
export const COORDINATOR_COURSE_VIEW_BYSECTION = 's'
export const COORDINATOR_COURSE_VIEW_OPTIONS = [
  { label: 'Course', value: COORDINATOR_COURSE_VIEW_BYCOURSE },
  { label: 'Section', value: COORDINATOR_COURSE_VIEW_BYSECTION },
]

export const COURSE_TYPE_AP = 'A'
export const COURSE_TYPE_PREAP = 'T'
export const COURSE_TYPE_CAREER_KICKSTART = 'K'
