import { Modal, Error } from '../../components/common'
import { resetStudentUpdate } from '../../actions/studentsCommon'
import SelectNotTakingExamReasons from '../../components/students/common/SelectNotTakingExamReason'
import { UPDATE_TYPE_INTENT } from '../../constants/StudentConstants'


const mapStateToProps = (state, { storeLocation, updateId }) => {
  const { update: { id, updating, type, error }} = state[storeLocation]
  const isActive = type === UPDATE_TYPE_INTENT && id === updateId
  return {
    active: isActive,
    updating: isActive && updating,
    error: isActive && error,
  }
}

class ExamIntentNotTakingReasonModal extends Component {
  state = { notReasonObj: {}, shouldCloseModal: false, active: false }

  constructor(props) {
    super(props)
    this.getUpdatedExamIntent = this.getUpdatedExamIntent.bind(this)
    this.updateNotReason = this.updateNotReason.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    if (state.active && !props.active)
      return { shouldCloseModal: true }

    return { active: props.active }
  }

  componentWillUnmount() {
    const { resetStudentUpdate } = this.props
    resetStudentUpdate()
  }

  getUpdatedExamIntent() {
    const { exam } = this.props
    const { notReasonObj } = this.state
    return { ...exam, ...notReasonObj }
  }

  getFooterActions() {
    const { updating, action } = this.props

    return [
      { buttonLabel: 'Cancel', isDismissable: true, isPrimary: false },
      { buttonLabel: 'Update',
        isPrimary: true,
        isDisabled: updating,
        busy: updating,
        onClick: () => action(this.getUpdatedExamIntent(), UPDATE_TYPE_INTENT),
      },
    ]
  }

  updateNotReason(reason) {
    this.setState({ notReasonObj: reason })
  }

  render() {
    const { modalCloseFocusElem, error, courseName } = this.props
    const { shouldCloseModal } = this.state

    return (
      <Modal
        headerTitle="Change Exam Order Status"
        shouldCloseModal={shouldCloseModal}
        onCloseAction={this.onCloseAction}
        modalCloseFocusElem={modalCloseFocusElem}
        footerActions={this.getFooterActions()}
      >
        { error ? <Error title="Error Updating Student" message={error} /> : null }
        <p>You will not be ordering an <strong>{courseName}</strong> exam for this student.</p>
        <SelectNotTakingExamReasons onChangeAction={this.updateNotReason} />
      </Modal>
    )
  }
}

export default connect(mapStateToProps, { resetStudentUpdate })(ExamIntentNotTakingReasonModal)
