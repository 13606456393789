import { Link } from 'react-router-dom'
import { formatDate, DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import { addFetchAction, removeFetchAction } from '../../../actions/app'
import { fetchExamOrderSummary } from '../../../actions/orders'
import { ORDER_TAB_EXAMS } from '../../../constants/OrderConstants'
import { SETTINGS_PANELS } from '../../../constants/SettingsConstants'
import { isSubmitted } from '../../../selectors/order'
import { SubmitButton } from '.'
import { NAV_LINKS } from '../../../constants/NavConstants'
import { Tooltip } from '../../common'
import style from '../../../assets/style/scss/orders.scss'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId },
    },
    settingsOrg,
  } = state
  const {
    mainOrderSummary = {},
    unsubmittedChangesSummary = {},
    approvalRequestSummary = {},
    unsubmittedSpecialOrderCount = 0,
    unsubmittedScoreReportingOrder,
  } = state.ordersSummary
  const u = unsubmittedChangesSummary
  const m = mainOrderSummary
  const hasUnsubmittedChanges =
    isSubmitted(m.orderStatus) &&
    (u.empty === false || unsubmittedSpecialOrderCount > 0 || unsubmittedScoreReportingOrder)
  const unsubmittedInitialOrder = !isSubmitted(m.orderStatus) && m.numberExams > 0

  return {
    m: mainOrderSummary,
    u: unsubmittedChangesSummary,
    hasUnsubmittedChanges,
    unsubmittedInitialOrder,
    a: approvalRequestSummary,
    allowedToOrder: settingsOrg.allowedToOrder[selectedOrgId],
    unsubmittedScoreReportingOrder,
  }
}

const specialMaterialsTooltipMsg =
  'Some materials require approval before they can be processed. Once approved, these will be included with your exam order.'

const SubmitOrderPanel = ({
  m,
  a,
  hasUnsubmittedChanges,
  unsubmittedInitialOrder,
  allowedToOrder,
  fetchExamOrderSummary,
  addFetchAction,
  removeFetchAction,
}) => {
  const hasBeenSubmitted = isSubmitted(m.orderStatus)
  const linkStyle = `${style['order-details-link']}`
  const approvalRequestsIconStyles = {
    display: 'inline-block',
    color: 'rgb(0, 119, 200)',
    fontSize: '8px',
  }
  useEffect(() => {
    addFetchAction({
      name: 'fetchExamOrderSummary',
      action: (orgId, educationPeriodCd) => fetchExamOrderSummary(orgId, educationPeriodCd),
    })
    return () => removeFetchAction('fetchExamOrderSummary')
  }, [])

  return (
    <div>
      <h2 className={`h3 ${!allowedToOrder ? style.restricted : ''}`}>Orders</h2>
      {!allowedToOrder ? (
        <p>
          You cannot submit your order until you{' '}
          <Link to={`/settings?scroll=${SETTINGS_PANELS.partForm}`}>
            <strong>complete the participation form</strong>
          </Link>
          .
        </p>
      ) : null}
      {unsubmittedInitialOrder && allowedToOrder ? (
        <>
          <div className="row">
            <div className="col-xs-6" />
            <div className={`col-xs-6 ${style['order-details-dates-cont']}`}>
              <SubmitButton />
            </div>
          </div>
          <hr />
        </>
      ) : null}
      {hasUnsubmittedChanges && allowedToOrder ? (
        <>
          <div className="row">
            <div className="col-xs-6">
              <div>
                <Link to={NAV_LINKS.unsubmittedchanges} className={`${linkStyle} roboto-bold`}>
                  View Unsubmitted Changes
                </Link>
              </div>
            </div>
            <div className={`col-xs-6 ${style['order-details-dates-cont']}`}>
              <SubmitButton />
            </div>
          </div>
          <hr />
        </>
      ) : null}
      {hasBeenSubmitted ? (
        <>
          <div className="row">
            <div className="col-xs-6">
              <div>
                <Link to={NAV_LINKS.orderhistory} className={linkStyle}>
                  View Order History
                </Link>
              </div>
            </div>
            <div className={`col-xs-6 ${style['order-details-dates-cont']}`}>
              {m.dateSubmitted ? (
                <div>Submitted {formatDate(m.dateSubmitted, DATETIME_FORMATS.shortMonthDay)}</div>
              ) : null}
              {m.dateUpdated ? (
                <div>Last Modified {formatDate(m.dateUpdated, DATETIME_FORMATS.shortMonthDay)}</div>
              ) : null}
            </div>
          </div>
        </>
      ) : null}
      {!a.empty &&
      (!hasBeenSubmitted ||
        a.numberApprovalPending > 0 ||
        a.numberApproved > 0 ||
        a.numberRejected > 0 ||
        a.numberRequiresInfo) ? (
        <>
          <div className="row" style={{ marginTop: '15px' }}>
            <div className="col-xs-6">
              <div>
                <Link
                  to={NAV_LINKS.orderapprovalreqs}
                  className={`${style['order-details-link']} roboto-bold`}
                >
                  View Approval Requests
                </Link>
                <div style={{ display: 'inline-block', marginLeft: '5px' }}>
                  <Tooltip
                    title={specialMaterialsTooltipMsg}
                    placement="top"
                    container="body"
                    label="More information: Special Exam Materials Approval Requests"
                  >
                    <i
                      className="cb-glyph cb-glyph-xs cb-glyph-circular cb-question"
                      aria-hidden="true"
                      style={approvalRequestsIconStyles}
                      data-alt={specialMaterialsTooltipMsg}
                    />
                  </Tooltip>
                </div>
                {a.numberUnsubmitted > 0 && !hasBeenSubmitted ? (
                  <div className="cb-small-font-size">{a.numberUnsubmitted} Not Submitted</div>
                ) : null}
                {a.numberApprovalPending > 0 ? (
                  <div className="cb-small-font-size">
                    {a.numberApprovalPending} Pending Approval
                  </div>
                ) : null}
                {a.numberApproved > 0 ? (
                  <div className="cb-small-font-size">{a.numberApproved} Approved</div>
                ) : null}
                {a.numberRequiresInfo > 0 ? (
                  <div className="cb-small-font-size">{a.numberRequiresInfo} Needs Info</div>
                ) : null}
                {a.numberRejected > 0 ? (
                  <div className="cb-small-font-size">{a.numberRejected} Denied</div>
                ) : null}
              </div>
            </div>
            <div className={`col-xs-6 ${style['order-details-dates-cont']}`}>
              {a.dateLastModified ? (
                <div>
                  Last Modified {formatDate(a.dateLastModified, DATETIME_FORMATS.shortMonthDay)}
                </div>
              ) : null}
            </div>
          </div>
        </>
      ) : null}
      <div className={style['order-callout-box']}>
        {hasBeenSubmitted ? (
          <div>
            <h3 className="h4">Changing your order</h3>
            <p>
              To add or remove exams from your order, use the links in the{' '}
              <Link to={`${NAV_LINKS.orders}#${ORDER_TAB_EXAMS}Tab`}>All Exams table</Link> below.
              Then come back here and submit your changes.
            </p>
          </div>
        ) : (
          <div>
            <h3 className="h4">Creating your order</h3>
            <p>
              To add or remove exams from your order, use the links in the{' '}
              <Link to={`${NAV_LINKS.orders}#${ORDER_TAB_EXAMS}Tab`}>All Exams table</Link> below.
              Then come back here and submit your order before deadline.
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default connect(mapStateToProps, {
  fetchExamOrderSummary,
  addFetchAction,
  removeFetchAction,
})(SubmitOrderPanel)
