import { change as changeFieldValue } from 'redux-form'
import { Text, Input, LimitedStringValidation } from '../../common'
import { ConditionalMaterialField } from '../common/SsdUtils'
import {
  SELECT_SSD_MATERIALS_FORM,
  ALLOWED_SSD_MATERIALS,
  SSD_MATERIALS_FIELDS,
} from '../../../constants/StudentConstants'

import styles from '../../../assets/style/scss/materials-modal.scss'

class OtherAccommodations extends Component {
  componentDidUpdate() {
    const { selectedSSDMaterials, changeFieldValue } = this.props

    // if this group is being toggled off, empty the fields
    if (
      !selectedSSDMaterials.otherAccommodationsCheck &&
      selectedSSDMaterials[SSD_MATERIALS_FIELDS.otherAccommodations.name] !== null
    )
      changeFieldValue(
        SELECT_SSD_MATERIALS_FORM,
        SSD_MATERIALS_FIELDS.otherAccommodations.name,
        null
      )
  }

  render() {
    const { allowedSSDMaterialCodes, selectedSSDMaterials } = this.props

    return (
      <ConditionalMaterialField
        allowedCodes={allowedSSDMaterialCodes}
        fieldName={ALLOWED_SSD_MATERIALS.otherAccommodations}
        fieldId={SSD_MATERIALS_FIELDS.otherAccommodations.value}
      >
        <div className={styles['materials-fieldset']}>
          <Input
            type="checkbox"
            name="otherAccommodationsCheck"
            labelId="otherMaterialsGroup"
            label="Other Materials"
          />
          <div
            role="group"
            aria-labelledby="otherMaterialsGroup"
            className={styles['materials-radiogroup']}
          >
            <p>
              In rare instances, a student may need a special exam material not listed on this
              screen. In &#34;Other materials,&#34; only indicate materials needed from the College
              Board based on a student&#39;s accommodation. Don&#39;t indicate an accommodation that
              doesn&#39;t require materials (for example, don&#39;t indicate rest breaks,
              medication, or extended time).
            </p>
            <label htmlFor={SSD_MATERIALS_FIELDS.otherAccommodations.name} className="sr-only">
              Other Materials
            </label>
            <Text
              type="text"
              name={SSD_MATERIALS_FIELDS.otherAccommodations.name}
              value={selectedSSDMaterials.otherAccommodations}
              placeholder="Describe Other Materials"
              maxlength="500"
              formGroupStyle={{ width: '100%', marginBottom: 0 }}
              disabled={!selectedSSDMaterials.otherAccommodationsCheck}
              validate={[LimitedStringValidation]}
            />
          </div>
        </div>
      </ConditionalMaterialField>
    )
  }
}

export default connect(null, { changeFieldValue })(OtherAccommodations)
