import {
  SSD_APPROVAL_STATUS,
  FILTER_NOT_SUBMITTED,
  SSD_UPDATED_NOT_SUBMITTED,
  SSD_CANCELLED_NOT_SUBMITTED,
} from '../../../constants/StudentConstants'
import { Tooltip } from '../../common'

const specialExamMaterialsApprovalStatusMsg = `Special exam materials have been updated for this student. You will need to submit your changes on the Orders page before they apply.`
const editMaterialsStyles = {
  display: 'inline-block',
  color: 'rgb(0, 119, 200)',
  fontSize: '8px',
}

const ExamSSDApprovalStatus = ({ exam: { etsApprovalStatus } }) => (
  <>
    <div>
      <strong>Approval Status:</strong> {SSD_APPROVAL_STATUS[etsApprovalStatus]}
      {etsApprovalStatus === FILTER_NOT_SUBMITTED ||
      etsApprovalStatus === SSD_UPDATED_NOT_SUBMITTED ||
      etsApprovalStatus === SSD_CANCELLED_NOT_SUBMITTED ? (
        <div style={{ display: 'inline-block', marginLeft: '5px' }}>
          <Tooltip
            title={specialExamMaterialsApprovalStatusMsg}
            placement="top"
            container="body"
            label="More information: Special Exam Materials Approval Status"
          >
            <i
              className="cb-glyph cb-glyph-xs cb-glyph-circular cb-question"
              aria-hidden="true"
              style={editMaterialsStyles}
              data-alt={specialExamMaterialsApprovalStatusMsg}
            />
          </Tooltip>
        </div>
      ) : null}
    </div>
  </>
)

export default ExamSSDApprovalStatus
