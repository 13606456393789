import { isValid, isDirty, submit, getFormValues } from 'redux-form'
import deepEqual from 'deep-equal'
import { Modal } from '../../components/common'
import { isEmpty } from '../../utils/common'
import { openModal } from '../../actions/app'
import { SELECT_SSD_MATERIALS_FORM, UPDATE_TYPE_SSD } from '../../constants/StudentConstants'
import SelectSSDMaterialsToBeOrderedForm from '../forms/SelectSSDMaterialsToBeOrderedForm'
import { getOrderDeadlineIsPast } from '../../selectors/section'
import { resetStudentUpdate } from '../../actions/studentsCommon'

const mapStateToProps = (state, { examId }) => {
  const {
    status: {
      data: { isSubmitted },
    },
    settingsEducationPeriod: { selectedEducationPeriod },
    settingsDeadlines: { data: settingsDeadlinesData },
    studentsByOrg: {
      update: { type, updating, error, id },
      courseMap,
      studentMap,
      sectionMap,
      exams,
    },
  } = state
  const deadlinesData = settingsDeadlinesData?.[selectedEducationPeriod] ?? {}
  const exam = exams.find(e => e.examId === examId)
  const isActive = type === UPDATE_TYPE_SSD && exam.examId === id
  const { lastName, firstName } = studentMap[exam.studentId]

  return {
    active: isActive,
    updating: isActive && updating,
    error: isActive && error,
    valid: isValid(SELECT_SSD_MATERIALS_FORM)(state),
    dirty: isDirty(SELECT_SSD_MATERIALS_FORM)(state),
    selectedSSDMaterials: getFormValues(SELECT_SSD_MATERIALS_FORM)(state) || {},
    exam,
    courseName: courseMap[exam.testCd].name,
    studentName: `${firstName} ${lastName}`,
    isChangeOrder: isSubmitted || getOrderDeadlineIsPast(sectionMap[exam.sectionId], deadlinesData),
  }
}

class OrderSSDMaterialsModal extends Component {
  state = { shouldCloseModal: false, active: false }

  constructor(props) {
    super(props)
    this.onCloseAction = this.onCloseAction.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.previousSSDMaterials = props.exam.ssdMaterials
  }

  static getDerivedStateFromProps(props, state) {
    if (state.active && !props.active) return { shouldCloseModal: true }

    return { active: props.active }
  }

  componentWillUnmount() {
    const { resetStudentUpdate } = this.props
    resetStudentUpdate()
  }

  onCloseAction() {
    const { openModal, exam, courseName, studentName } = this.props

    if (this.showChangeOrderModal()) {
      openModal('UpdateStudentExamSuccessModal', {
        exam,
        type: UPDATE_TYPE_SSD,
        courseName,
        studentName,
        modalCloseFocusElem: this.modalCloseFocusElem,
      })
    }
  }

  submitForm() {
    const { submit } = this.props
    submit(SELECT_SSD_MATERIALS_FORM)
  }

  footerActions() {
    const { valid, updating, dirty } = this.props
    return [
      {
        buttonLabel: 'Cancel',
        isDismissable: true,
        isPrimary: false,
        isDisabled: updating,
      },
      {
        buttonLabel: 'Update',
        isPrimary: true,
        onClick: this.submitForm,
        isDisabled: !valid || updating || !dirty,
        busy: updating,
      },
    ]
  }

  showChangeOrderModal() {
    const {
      isChangeOrder,
      exam: { ssdMaterials },
    } = this.props

    return (
      isChangeOrder &&
      !isEmpty(ssdMaterials) &&
      !deepEqual(ssdMaterials, this.previousSSDMaterials, { strict: true })
    )
  }

  render() {
    const { exam, courseName, modalCloseFocusElem } = this.props
    const { shouldCloseModal } = this.state

    return (
      <Modal
        shouldCloseModal={shouldCloseModal}
        onCloseAction={this.onCloseAction}
        modalCloseFocusElem={modalCloseFocusElem}
        headerTitle={`${courseName}: Special Exam Materials for Students with Accommodations`}
        footerActions={this.footerActions()}
      >
        <p>
          Indicate special exam materials based on approved or expected accommodations. (If this
          student will use a <strong>regular-format exam</strong>, don&#39;t indicate any special
          exam materials.){' '}
          <strong>
            Indicating materials below is not a formal request for accommodations; accommodations
            requests must be submitted to and approved by College Board SSD.
          </strong>
        </p>
        <SelectSSDMaterialsToBeOrderedForm exam={exam} />
      </Modal>
    )
  }
}

export default connect(mapStateToProps, {
  submit,
  openModal,
  resetStudentUpdate,
})(OrderSSDMaterialsModal)
