import { isSubmitted } from '../../../selectors/order'
import { openModal } from '../../../actions/app'
import { isFeatureDisabled } from '../../../selectors/features'
import { HOLD_NOTIFICATION } from '../../../constants/NotificationConstants'
import style from '../../../assets/style/scss/orders.scss'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId },
    },
    settingsOrg,
    ordersSummary: {
      fetching,
      error,
      mainOrderSummary = {},
      unsubmittedChangesSummary = {},
      unsubmittedSpecialOrderCount = 0,
    },
    ordersSubmission: { confirming },
    settingsEducationPeriod: { selectedEducationPeriod, isTransitionPeriod },
    notifications: {
      [HOLD_NOTIFICATION]: {
        fetching: fetchingNotifications,
        data: { allowedToOrder: noBillingOrSecurityHold },
      },
    },
  } = state
  return {
    selectedOrgId,
    selectedEducationPeriod,
    isMainOrder: !mainOrderSummary || !isSubmitted(mainOrderSummary.orderStatus),
    fetching,
    error,
    fetchingNotifications,
    confirming,
    noBillingOrSecurityHold,
    isDisabled:
      isFeatureDisabled(state, 'ORDER_EXAMS') ||
      isTransitionPeriod ||
      !settingsOrg.allowedToOrder[selectedOrgId] ||
      !noBillingOrSecurityHold ||
      fetching ||
      fetchingNotifications ||
      (!mainOrderSummary.numberExams &&
        unsubmittedChangesSummary.empty &&
        unsubmittedSpecialOrderCount === 0),
  }
}

const SubmitButton = ({
  fetching,
  error,
  confirming,
  isDisabled,
  isMainOrder,
  redirectOnSuccess,
  openModal,
}) => {
  const handleClick = () => {
    openModal('ConfirmExamOrderSubmissionModal', { isMainOrder, redirectOnSuccess })
  }

  if (fetching || error) return null

  return (
    <button
      type="button"
      className={`btn btn-sm btn-primary ${style['order-submit-button']}`}
      disabled={confirming || isDisabled}
      onClick={handleClick}
    >
      Submit
    </button>
  )
}

SubmitButton.displayName = 'SubmitButton'

export default connect(mapStateToProps, {
  openModal,
})(SubmitButton)
