import { useLocation } from 'react-router-dom'
import TeacherTable from './TeacherTable'
import TeacherPractice from './TeacherPractice'
import TeacherExamFilters from '../../filters/exam/TeacherExamFilters'
import { FilterNoResultsMessage } from '../../filters/common/FilterMessages'
import TeacherCourseDropdown from './TeacherCourseDropdown'
import { queryParamsToObj } from '../../../utils/common'
import { filterExamReadinessData, getExamValues } from '../../../selectors/examReadiness'
import { clearFilters, getFilters } from '../../../actions/filters'

import styles from '../../../assets/style/scss/exam-readiness.scss'

const TeacherCourse = connect(state => {
  const { selectedEducationPeriod, availableEducationPeriods } = state.settingsEducationPeriod
  const { examObj } = state.examReadiness
  return {
    examYear: availableEducationPeriods[selectedEducationPeriod].descr.split('-')[1],
    examObj,
  }
})(({ examYear, examObj = {}, courses = [], realTimeUpdatesDisabledMsg }) => {
  const { search } = useLocation()
  const [selectedCourse, setSelectedCourse] = useState(undefined)
  const activeFilters = getFilters(search)
  const exams = getExamValues(examObj, e => e.testCode === selectedCourse?.testCode)
  const visibleExams = filterExamReadinessData(activeFilters, exams)

  useEffect(() => {
    if (selectedCourse) clearFilters()
  }, [selectedCourse])

  useEffect(() => {
    if (courses.length) {
      const { testCd } = queryParamsToObj(search)
      const selectedIndex = courses.findIndex(c => c.testCode === parseInt(testCd, 10))
      const selectedCourseIndex = selectedIndex !== -1 ? selectedIndex : 0
      // this should only be set on the initial load of courses data
      if (!selectedCourse?.testCode) setSelectedCourse(courses[selectedCourseIndex])
    }
  }, [courses])

  return (
    <>
      <div className="container">
        <p style={{ margin: '1em 0' }}>
          Review the following to make sure your students are ready for the exam.{' '}
          <a href="https://apcentral.collegeboard.org/about-ap-2021/updates">
            Learn more about {examYear} Exams.
          </a>
        </p>
        <TeacherCourseDropdown
          courses={courses}
          selectedCourse={selectedCourse}
          setSelectedCourse={setSelectedCourse}
        />
      </div>
      <TeacherExamFilters
        examsByCourse={exams}
        activeFilters={activeFilters}
        selectedCourse={selectedCourse?.testCode}
      />
      <div className="container" role="region" aria-live="polite" style={{ marginTop: '2em' }}>
        <div className={styles['table-actions-legend']}>
          <div>
            <span>
              <i className="cb-glyph cb-compose" aria-hidden={true} /> Paper Exam
            </span>
            <span>
              <i className="cb-glyph cb-computer" aria-hidden={true} /> Digital Exam
            </span>
          </div>
          <TeacherPractice {...selectedCourse} />
        </div>
        {realTimeUpdatesDisabledMsg ? (
          <p className="cb-error-msg" style={{ marginBottom: '.5em' }} aria-live="polite">
            {realTimeUpdatesDisabledMsg}
          </p>
        ) : null}
        {visibleExams.length ? (
          <TeacherTable exams={visibleExams} allExams={exams} />
        ) : exams.length && Object.keys(activeFilters) ? (
          <FilterNoResultsMessage text="exams" className={null} />
        ) : (
          <p>You do not have any students registered to take this exam.</p>
        )}
      </div>
    </>
  )
})

export default TeacherCourse
