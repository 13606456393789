export const SET_NAV_LINK = 'SET_NAV_LINK'
export const UNSET_NAV_LINK = 'UNSET_NAV_LINK'
export const SUPPRESS_NAV = 'SUPPRESS_NAV'
export const UN_SUPPRESS_NAV = 'UN_SUPPRESS_NAV'
export const SUPPRESS_SCHOOL_MENU = 'SUPPRESS_SCHOOL_MENU'
export const UN_SUPPRESS_SCHOOL_MENU = 'UN_SUPPRESS_SCHOOL_MENU'
export const SET_RETURN_APP = 'SET_RETURN_APP'

export const NAV_LINKS = {
  redirect: '/redirect',
  dashboard: '/dashboard',
  noaccess: '/noaccess',
  login: '/login',
  tac: '/termsandconditions',
  courses: '/courses',
  students: '/students',
  studentdets: '/student/:studentId',
  orders: '/orders',
  unsubmittedchanges: '/orders/unsubmitted',
  orderhistory: '/orders/history',
  orderapprovalreqs: '/orders/approvalrequests',
  programstats: '/orders/programstatistics',
  shipments: '/orders/shipments',
  packingshipments: '/packingshipments',
  packinglist: '/packingshipments/:packingShipmentsId/packinglist/:packingListId',
  invoice: '/invoice',
  invoicehistory: '/invoice/history',
  invoicecopy: '/invoice/:type',
  // examroom: '/examroom',
  // examroomdets: '/examroom/:roomId',
  settings: '/settings',
  joincode: '/courses/joincode/:sectionId',
  transfercode: '/courses/transfercode/:sectionId',
  sectiondets: '/course/:courseId/section/:sectionId',
  setup: '/setup',
  profile: '/profile',
  docusign: '/docusign',
  examreadiness: '/digital-exam-readiness',
  districtfunding: '/funding',
  createIncidentReport: '/incidentreport/create',
  incidentReportHistory: '/incidentreport/history',
  printIncidentReport: '/incidentreport/print',
  teacherProfile: '/my-profile',
  selectRole: '/selectrole',
}

export const DEFAULT_ROUTES = [
  { path: NAV_LINKS.dashboard, component: 'DashboardPage', title: 'Home' },
]

const COORDINATOR_COMMON = [
  ...DEFAULT_ROUTES,
  { path: NAV_LINKS.courses, component: 'CoursesPage', title: 'Courses' },
  { path: NAV_LINKS.students, component: 'StudentsPage', title: 'Students' },
  { path: NAV_LINKS.studentdets, component: 'StudentDetailsPage', title: 'Student Details' },
  { path: NAV_LINKS.settings, component: 'SettingsPage', title: 'Settings' },
  { path: NAV_LINKS.joincode, component: 'ViewSectionCode', title: 'Print Join Code' },
]

export const COORDINATOR_LEVELTWO_ROUTES = [
  ...COORDINATOR_COMMON,
  { path: NAV_LINKS.orders, component: 'OrdersPage', title: 'Orders' },
  {
    path: NAV_LINKS.unsubmittedchanges,
    component: 'UnsubmittedChangesPage',
    title: 'Unsubmitted Order Changes',
  },
  { path: NAV_LINKS.orderhistory, component: 'OrderHistoryPage', title: 'Order History' },
  {
    path: NAV_LINKS.orderapprovalreqs,
    component: 'ApprovalRequestsPage',
    title: 'Approval Requests',
  },
  { path: NAV_LINKS.shipments, component: 'ShipmentsPage', title: 'Shipments' },
  {
    path: NAV_LINKS.packingshipments,
    component: 'PackingShipmentsSummaryPage',
    title: 'Packing List & Invoice',
  },
  { path: NAV_LINKS.packinglist, component: 'PackingListPage', title: 'Packing List' },
  { path: NAV_LINKS.invoicehistory, component: 'InvoiceHistoryPage', title: 'Invoice History' },
  { path: NAV_LINKS.invoicecopy, component: 'InvoicesPage', title: 'Invoice Reimbursement Copy' },
  { path: NAV_LINKS.invoice, component: 'InvoicesPage', title: 'Invoice' },
  { path: NAV_LINKS.programstats, component: 'ProgramStatisticsPage', title: 'Program Statistics' },
  // { path: NAV_LINKS.examroom, component: 'ExamRoomPage', title: 'Exam Room Schedule' },
  // { path: NAV_LINKS.examroomdets, component: 'ExamRoomDetailsPage', title: 'Exam Room Details' },
  {
    path: NAV_LINKS.transfercode,
    component: 'ViewSectionCode',
    title: 'Print Transfer Code',
  },
  { path: NAV_LINKS.examreadiness, component: 'ExamReadinessPage', title: 'Exam Readiness' },
  {
    path: NAV_LINKS.createIncidentReport,
    component: 'CreateIncidentReportPage',
    title: 'AP Coordinator Incident Report Form',
  },
  {
    path: NAV_LINKS.incidentReportHistory,
    component: 'IncidentReportHistoryPage',
    title: 'Incident Report History',
  },
  {
    path: NAV_LINKS.printIncidentReport,
    component: 'PrintIncidentReportPage',
    title: 'Print Incident Report',
  },
]

export const COORDINATOR_LEVELTWO_NAV = [
  { path: NAV_LINKS.dashboard, title: 'Home' },
  { path: NAV_LINKS.courses, title: 'Courses' },
  {
    title: 'Students',
    path: NAV_LINKS.students,
    //subnav: [
    //  { path: NAV_LINKS.students, title: 'Student Roster' },
    //  { path: NAV_LINKS.examreadiness, title: 'Digital Exam Readiness' },
    //],
  },
  { path: NAV_LINKS.orders, title: 'Orders' },
  { path: NAV_LINKS.packingshipments, title: 'Packing List & Invoice', isMulti: true },
  // { path: NAV_LINKS.examroom, title: 'Exam Room Schedule', isMulti: true },
  { path: NAV_LINKS.settings, title: 'Settings' },
]

export const COORDINATOR_LEVELONE_ROUTES = [
  ...COORDINATOR_COMMON,
  { path: NAV_LINKS.orders, component: 'OrdersPage', title: 'Orders' },
]

export const COORDINATOR_LEVELONE_NAV = [
  {
    path: NAV_LINKS.dashboard,
    title: 'Home',
    iconClass: 'glyphicon-cb cb-icon-icn_home hidden-xs',
  },
  { path: NAV_LINKS.courses, title: 'Courses' },
  { path: NAV_LINKS.students, title: 'Students' },
  { path: NAV_LINKS.orders, title: 'Orders' },
  { path: NAV_LINKS.settings, title: 'Settings', iconClass: 'glyphicon-cb cb-settings hidden-xs' },
]

export const COORDINATOR_PREAP_ROUTES = COORDINATOR_COMMON

export const COORDINATOR_PREAP_NAV = [
  {
    path: NAV_LINKS.dashboard,
    title: 'Home',
    iconClass: 'glyphicon-cb cb-icon-icn_home hidden-xs',
  },
  { path: NAV_LINKS.courses, title: 'Courses' },
  { path: NAV_LINKS.students, title: 'Students' },
  { path: NAV_LINKS.settings, title: 'Settings', iconClass: 'glyphicon-cb cb-settings hidden-xs' },
]

export const TEACHER_ROUTES = [
  ...DEFAULT_ROUTES,
  { path: NAV_LINKS.courses, component: 'CoursesPage', title: 'Courses' },
  {
    path: NAV_LINKS.joincode,
    component: 'ViewSectionCode',
    title: 'Print Join Code',
  },
  { path: NAV_LINKS.sectiondets, component: 'SectionDetailsPage', title: 'Course Details' },
  { path: NAV_LINKS.examreadiness, component: 'ExamReadinessPage', title: 'Exam Readiness' },
  { path: NAV_LINKS.teacherProfile, component: 'TeacherProfilePage', title: 'My Profile' },
]
