import RenderExamComponent from './RenderExamComponent'
import { openModal } from '../../actions/app'
import {
  PACKING_LIST_COMPONENT_MAPPING,
  PACKING_LIST_CTA_MAPPING,
  PACKING_LIST_LABEL_MAPPING,
  EXAM_MATERIALS,
  CHANGE_PACKING_LIST_ITEMS_MODAL,
} from '../../constants/PackingListConstants'

const mapStateToProps = (state, ownProps) => {
  const {
    id = '',
    materials,
    packingListItemId,
    inline,
    readOnly,
    headerTitle,
    shippedQuantity,
    unused,
  } = ownProps
  return {
    id,
    materials,
    packingListItemId,
    inline,
    readOnly,
    headerTitle,
    shippedQuantity,
    unused,
  }
}

/**
 * Handle exam materials returned.
 */
class ExamMaterialsReturned extends Component {
  constructor(props) {
    super(props)
    this.editButton = createRef()
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(e) {
    const {
      id,
      headerTitle,
      materials,
      shippedQuantity,
      packingListItemId,
      unused,
      openModal,
    } = this.props

    e.preventDefault()

    const formattedMaterials = this.filterMaterials(materials).map(key => ({
      label: PACKING_LIST_COMPONENT_MAPPING[key],
      name: key,
      quantity: materials[key],
      returnedReason: materials[`${key}Reason`] || '',
    }))

    openModal(CHANGE_PACKING_LIST_ITEMS_MODAL, {
      materials: formattedMaterials,
      shippedQuantity,
      packingListItemId,
      unused,
      headerTitle,
      modalCloseFocusElem: this.editButton.current,
    })
  }

  filterMaterials(materials) {
    return Object.keys(materials).filter(key => EXAM_MATERIALS.includes(key))
  }

  render() {
    const { id, materials, readOnly } = this.props
    const filteredMaterials = this.filterMaterials(materials)
    return (
      <>
        <h4>{PACKING_LIST_LABEL_MAPPING[id] || ''}</h4>
        {filteredMaterials.sort().map(key => (
          <div key={key} style={{ display: 'inline-block', padding: '24px', paddingLeft: '0' }}>
            <RenderExamComponent
              label={PACKING_LIST_COMPONENT_MAPPING[key]}
              count={materials[key]}
            />
          </div>
        ))}
        {!readOnly && (
          <div style={{ marginBottom: '24px' }}>
            <button
              style={{ padding: '0' }}
              type="button"
              className="btn-link"
              ref={this.editButton}
              onClick={this.handleClick}
            >
              {PACKING_LIST_CTA_MAPPING[id] || 'Edit '}
            </button>
          </div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  { openModal }
)(ExamMaterialsReturned)
