import { formatDate, DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import styles from '../../../assets/style/scss/student-details.scss'

const StatusIcon = ({ status }) => {
  const isPending = status === 'Pending'
  const iconClass =
    status === 'Approved' ? `cb-check ${styles.approved}` : `cb-error ${styles.denied}`
  return !isPending && iconClass ? (
    <i className={`cb-glyph ${iconClass}`} aria-hidden="true" />
  ) : null
}

const AccommodationsTable = ({ data }) => (
  <table className={`table cb-table-no-border ${styles['ssd-accommodations']}`}>
    <caption className="sr-only">List of current SSD accommodations</caption>
    <thead>
      <tr role="row">
        <th scope="col">Accommodation</th>
        <th scope="col">Comments</th>
        <th scope="col" className={`${styles['received-col']}`}>
          Application Received
        </th>
        <th scope="col" className={`${styles['status-col']}`}>
          Status
        </th>
        <th scope="col" className={`${styles['status-date-col']}`}>
          <span className="sr-only">Status Date</span>
        </th>
        <th scope="col">Expiration Date</th>
      </tr>
    </thead>
    <tbody>
      {data.map((d, i) => (
        <tr key={`${i}-status`}>
          <th scope="row">{d.accommodation}</th>
          <td>{d.comments}</td>
          <td className={`${styles['received-col']}`}>
            {formatDate(d.receivedOn, DATETIME_FORMATS.tinyMonthDayYear)}
          </td>
          <td className={`${styles['status-col']}`}>
            <div>
              <StatusIcon status={d.status} />
              <span>{d.status}</span>
            </div>
          </td>
          <td className={`${styles['status-date-col']}`}>
            {d.statusDate ? formatDate(d.statusDate, DATETIME_FORMATS.tinyMonthDayYear) : '\u2014'}
          </td>
          <td>{formatDate(d.expirationDate, DATETIME_FORMATS.tinyMonthDayYear)}</td>
        </tr>
      ))}
    </tbody>
  </table>
)

const StudentSSDAccommodations = ({ accommodationsInfo, isSchoolAdmin, isCSR }) => {
  const { ssdId, accommodations = [] } = accommodationsInfo || {}
  const hasAccommodations = accommodations.length

  return (
    <div>
      <h3>
        Accommodations for Students with Disabilities{' '}
        {ssdId ? <span className="cb-small-font">(SSD Code: {ssdId})</span> : null}
      </h3>
      <p>
        Students with documented disabilities may require special exam formats or materials. You
        should indicate all approved or expected special exam formats or materials before submitting
        your exam order, even if a student&#39;s accommodations haven&#39;t yet been approved. Some
        orders may require review by the College Board and will remain in "pending" status until
        this review is complete.
      </p>
      <p>
        For digital AP Exams, you must review and confirm the accommodations listed on the{' '}
        <strong>Student Details</strong> page for each student taking a digital AP Exam. 
        Accommodations can also be waived if needed. Confirming or waiving accommodations should 
        be done as soon as possible, no later than 2 calendar days before the scheduled digital exam date.
      </p>
      <p>
        Orders are separate from requests for accommodations. To take an AP Exam with
        accommodations, a student <strong>must</strong> be approved for the accommodations by the
        College Board&#39;s Services for Students with Disabilities (SSD). Requests for
        accommodations must be submitted to SSD by <strong>January 18, 2024</strong>. Providing
        accommodations to students without College Board approval will result in cancellation of
        their scores.
      </p>
      <p>
        To include special exam materials for this student in your exam order, click &quot;Special
        Exam Materials&quot; next to the course name(s) above. If this student has an accommodation
        but will use regular-format exam materials, no action is needed on this page; for example,
        +50% extended time without any other accommodation doesn&#39;t require special exam
        materials. See the <em>AP Coordinator&#39;s Manual, Part 1</em> for details about special
        exam materials and accommodations, or consult your school&#39;s SSD coordinator.
      </p>
      <p>
        Starting in early April, the Nonstandard Administration Report (NAR) can be generated on the{' '}
        <strong>Students</strong> page in AP Registration and Ordering. A NAR <strong>must</strong> be 
        completed for students testing with approved accommodations on paper AP Exams, and AP Chinese 
        and AP Japanese Exams administered on computers. For digital AP Exams only, the NAR is not needed.
      </p>
      <p>
        The information below is from the College Board&#39;s SSD platform. Refer to it when
        ordering special exam materials for students with approved or expected accommodations. For
        more detailed information about the student&#39;s accommodation(s), visit the{' '}
        <a
          href="https://ssd.collegeboard.com/ssd/coordinator/SefAction.action"
          target="_new"
          className={isSchoolAdmin || isCSR ? 'disabled' : ''}
        >
          SSD Online System
        </a>
        .
      </p>

      {hasAccommodations ? (
        <AccommodationsTable data={accommodations} />
      ) : (
        <p>There are currently no accommodations associated with this student.</p>
      )}
    </div>
  )
}

export default StudentSSDAccommodations
