import { CoordinatorCourses, TeacherCourses } from '../../components/courses'
import { fetchCourses } from '../../actions/course'
import { addFetchAction, removeFetchAction } from '../../actions/app'
import { Loader, Error } from '../../components/common'

const mapStateToProps = (state, props) => {
  const {
    courses: { fetching, error },
    user: { data },
  } = state

  return {
    error,
    fetching,
    user: data,
  }
}

const displayCourseList = user => {
  if (user.isCoordinator) return <CoordinatorCourses />
  if (user.isTeacher) return <TeacherCourses />
  return
  ;<p>I do not know who you are</p>
}

const CoursesPage = ({
  fetchCourses,
  addFetchAction,
  removeFetchAction,
  error,
  fetching,
  user,
}) => {
  useEffect(() => {
    addFetchAction({
      name: 'fetchCourses',
      action: (orgId, educationPeriodCd) => fetchCourses(orgId, educationPeriodCd),
    })
    return () => removeFetchAction('fetchCourses')
  }, [])

  if (error)
    return (
      <div className="container">
        <Error title="Error Retrieving Data" message={error} />
      </div>
    )

  if (fetching)
    return (
      <div className="container">
        <Loader />
      </div>
    )

  return (
    <CSSTransition
      classNames="animate-fade"
      in={true}
      timeout={500}
      appear={true}
      enter={false}
      exit={false}
    >
      {displayCourseList(user)}
    </CSSTransition>
  )
}

export default connect(
  mapStateToProps,
  { fetchCourses, addFetchAction, removeFetchAction }
)(CoursesPage)
