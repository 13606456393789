import { Redirect, Route } from 'react-router-dom'
import { NAV_LINKS } from '../../constants/NavConstants'
import { coordinatorNeedsToSetupSchool } from './utils'

const mapStateToProps = ({ user, settingsEducationPeriod }) => ({
  redirectToSetup:
    settingsEducationPeriod && coordinatorNeedsToSetupSchool(user, settingsEducationPeriod),
  redirectToNoAccess: !user.hasAccess,
  redirectToTAC: !user.data.acceptedGeneralTAC,
  redirectToSelectRole: user.data.hasMultipleRoles && !user.data.activeRoleCd,
})

const AppRoute = connect(mapStateToProps)(({ component: Component, ...props }) => {
  const {
    redirectToNoAccess,
    redirectToSetup,
    redirectToTAC,
    redirectToSelectRole,
    location: { pathname, search },
  } = props
  let redirect = null

  if (redirectToNoAccess) redirect = NAV_LINKS.noaccess
  else if (redirectToSetup) redirect = NAV_LINKS.setup
  else if (redirectToTAC) redirect = NAV_LINKS.tac
  else if (redirectToSelectRole) redirect = NAV_LINKS.selectRole

  return redirect && pathname !== redirect ? (
    <Redirect to={{ pathname: redirect, search }} />
  ) : (
    <Component {...props} />
  )
})

export default ({ component, ...rest }) => (
  <Route {...rest} render={props => <AppRoute {...props} component={component} />} />
)
