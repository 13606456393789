import { Modal, BasicSelect } from '../../../common'
import { UPDATE_TYPE_TESTED_WITH_ACCOM_IND } from '../../../../constants/StudentConstants'
import { isEmpty } from '../../../../utils/common'
import { canChangeTestedWithAccoms } from '../../../../selectors/student'

const mapStateToProps = state => {
  const {
    studentsByOrg: {
      exams,
      selected,
      multi: { updated, error },
    },
  } = state

  const selectedEnrollments = []
  for (let i = 0; i < exams.length; i++) {
    if (selected.includes(exams[i].enrollmentId)) {
      selectedEnrollments.push(exams[i])
    }
  }

  return {
    selectedEnrollments,
    updated,
    error,
  }
}

const TestedWithAccomsMultiSelect = connect(mapStateToProps)(
  ({
    modalProps,
    getFooterActions,
    courseName,
    selectedEnrollments,
    updated,
    error,
    setSuccessText,
  }) => {
    const [selectedTestedWithAccoms, setSelectedTestedWithAccoms] = useState()
    const selectionsComplete = !isEmpty(selectedTestedWithAccoms)
    const attemptedChangesCount = selectedEnrollments.length
    const actualChanges = selectionsComplete
      ? selectedEnrollments.filter(
          s => canChangeTestedWithAccoms(s) && s.testedWithAccomInd !== selectedTestedWithAccoms
        )
      : selectedEnrollments
    const actualChangesCount = actualChanges.length

    useEffect(() => {
      if (!isEmpty(selectedTestedWithAccoms) && updated) {
        setSuccessText(
          <>
            The Student Tested with Accommodations Indicator has successfully been updated for
            {actualChangesCount} student{actualChangesCount > 1 ? 's' : ''} taking the {courseName}{' '}
            exam.
          </>
        )
      }
    }, [selectedTestedWithAccoms, updated])

    return (
      <Modal
        {...modalProps}
        headerTitle="Change Student Tested with Accommodations Indicator"
        footerActions={getFooterActions(
          actualChanges,
          {
            type: UPDATE_TYPE_TESTED_WITH_ACCOM_IND,
            testedWithAccomInd: selectedTestedWithAccoms,
          },
          !selectionsComplete || actualChangesCount < 1
        )}
      >
        <div className="form-group">
          <BasicSelect
            label="Student Tested with Accommodations Indicator"
            options={[
              { label: 'Student tested with accommodations', value: true },
              { label: 'Student did not test with accommodations', value: false },
            ]}
            input={{
              name: 'multiSelectTestedWithAccoms',
              value: selectedTestedWithAccoms,
              onChange: e => setSelectedTestedWithAccoms(e.target.value === 'true'),
            }}
          />
        </div>

        {selectionsComplete && actualChangesCount > 0 ? (
          <>
            <p style={{ marginTop: '2em' }}>
              You are changing the Student Tested with Accommodations Indicator for{' '}
              {actualChangesCount} out of the {attemptedChangesCount} selected students taking the{' '}
              <strong>{courseName}</strong> exam.
            </p>
            <p>
              Some accommodations are automatically applied for students taking digital exams. These
              students cannot be updated to &quot;Student did not test with accommodations&quot;.
            </p>
          </>
        ) : null}
        {selectionsComplete && actualChangesCount < 1 ? (
          <p>
            The selected students cannot have their Tested with Accommodations Indicator changed.
          </p>
        ) : null}
        {error ? (
          <p className="cb-error-msg" aria-live="polite">
            {error}
          </p>
        ) : null}
      </Modal>
    )
  }
)

export default TestedWithAccomsMultiSelect
