import { Tooltip } from '../../../common'
import TakingExamDropdown from '../updatedropdowns/TakingExamDropdown'

const mapStateToProps = state => state.settingsExamDecisions[state.user.data.selectedOrgId] || {}

const teacherConfirmationEnabledMsg = `Students enroll in class sections in My AP and then need to provide confirmation for each AP Exam they intend to take. AP teachers can view and edit students' exam decisions; AP coordinators still have the ability to make final changes to students' exam decisions.`
const teacherConfirmationDisabledMsg = `Students enroll in class sections in My AP and then need to provide confirmation for each AP Exam they intend to take. AP teachers can view but not edit students' exam decisions. AP coordinators can make final changes to students' exam decisions.`
const examDecisionsDisabledMsg = `As a default setting, every student who enrolls in class sections in My AP will be included in the AP Exam roster. Before submitting the AP Exam order, AP coordinators only need to change the exam registration status for any students who are not taking an exam.`

export const OrderExamHeader = connect(mapStateToProps)(
  ({ enableStudentDecisions, enableTeacherConfirmation }) => {
    const teacherConfirmMsg = enableTeacherConfirmation
      ? teacherConfirmationEnabledMsg
      : teacherConfirmationDisabledMsg
    const tooltipMsg = enableStudentDecisions ? teacherConfirmMsg : examDecisionsDisabledMsg

    return (
      <>
        Order Exam?
        <Tooltip
          title={tooltipMsg}
          placement="top"
          container="body"
          label="More information: Order Exam information"
        >
          <i className="cb-glyph cb-glyph-circular cb-question" />
        </Tooltip>
      </>
    )
  }
)

export const OrderExam = ({ rowData, dropdownOptions }) => (
  <div>
    {rowData.teacherConfirmed ? (
      <i className="cb-glyph cb-educator">
        <span className="sr-only">Teacher Confirmed Enrollment</span>
      </i>
    ) : null}
    <TakingExamDropdown exam={rowData} dropdownOptions={dropdownOptions} />
  </div>
)
