import { formValueSelector, isInvalid, isSubmitting, submit, change } from 'redux-form'
import { Modal, Error } from '../../components/common'
import { fetchAvailableCourses } from '../../actions/course'
import { getAllCourses } from '../../selectors/course'
import { isEmpty } from '../../utils/common'
import {
  SEARCH_FOR_TEACHER_FORM_NAME,
  SEARCH_FOR_TEACHER_FORM_FIELDS,
} from '../../constants/TeacherConstants'
import {
  resetSearchForTeacher,
  grantTeacherAccessToCourse,
  resetGrantRevokeTeacherAccess,
} from '../../actions/settingsTeacherAccess'
import FindTeacherForm from '../forms/FindTeacherForm'
import { getSelectedEducationPeriod } from '../../selectors/settings'

const selector = formValueSelector(SEARCH_FOR_TEACHER_FORM_NAME)

const mapStateToProps = state => ({
  submitting: isSubmitting(SEARCH_FOR_TEACHER_FORM_NAME)(state),
  searchEmailField: selector(state, SEARCH_FOR_TEACHER_FORM_FIELDS.emailField),
  selectedCourse: selector(state, SEARCH_FOR_TEACHER_FORM_FIELDS.courseField),
  isInvalid: isInvalid(SEARCH_FOR_TEACHER_FORM_NAME)(state),
  orgId: state.user.data.selectedOrgId,
  allCourses: getAllCourses({ state, apOnly: false }),
  foundTeacher: state.teacherSearch,
  teacherGrantAccess: state.teacherGrantAccess,
  educationPeriodCd: getSelectedEducationPeriod(state),
})

const GrantTeacherAccessModal = ({
  submitting,
  searchEmailField,
  selectedCourse,
  isInvalid,
  orgId,
  educationPeriodCd,
  allCourses,
  foundTeacher,
  teacherGrantAccess,
  onlineInd,
  onCloseAction,
  modalCloseFocusElem,
  submit,
  change,
  fetchAvailableCourses,
  resetSearchForTeacher,
  grantTeacherAccessToCourse,
  resetGrantRevokeTeacherAccess,
}) => {
  const { fetching, fetched, error } = teacherGrantAccess
  const { personId: proId, fetched: fetchedTeacher, name, email } = foundTeacher
  const [closeModal, setCloseModal] = useState(false)

  useEffect(() => {
    const fetchCourses = async () => {
      await fetchAvailableCourses({ orgId, educationPeriodCd })
    }
    if (!allCourses.length && orgId && educationPeriodCd) {
      fetchCourses()
    }
  }, [allCourses.length, orgId, educationPeriodCd, fetchAvailableCourses])

  useEffect(() => {
    if (fetched) {
      setCloseModal(true)
    }
    return () => {
      resetSearchForTeacher()
      resetGrantRevokeTeacherAccess()
    }
  }, [fetched, resetSearchForTeacher, resetGrantRevokeTeacherAccess])

  const grantTeacherAccess = () => {
    grantTeacherAccessToCourse({
      proId: parseInt(proId, 10),
      testCd: parseInt(selectedCourse, 10),
      orgId,
      educationPeriodCd,
      onlineInd,
    })
  }

  const resetSearch = async () => {
    resetSearchForTeacher()
    await change(SEARCH_FOR_TEACHER_FORM_NAME, SEARCH_FOR_TEACHER_FORM_FIELDS.emailField, '')
    // Setting focus() below doesn't work, presumably because change() is still doing stuff,
    // and the emailField isn't enabled until the operation completes. If only there were
    // a way to trigger the focus after change() completes...
    document.getElementById(SEARCH_FOR_TEACHER_FORM_FIELDS.emailField).focus()
  }

  const footerActions = () => {
    const disableSearch = isEmpty(searchEmailField)
    const displayGrantAccess = !isEmpty(proId)

    if (displayGrantAccess) {
      return [
        { buttonLabel: 'No', isPrimary: false, onClick: () => resetSearch() },
        {
          buttonLabel: 'Yes',
          isDisabled: fetching,
          isPrimary: true,
          onClick: () => grantTeacherAccess(),
          busy: fetching,
        },
      ]
    }

    return [
      { buttonLabel: 'Cancel', isDismissable: true, isPrimary: false },
      {
        buttonLabel: 'Search',
        isDisabled: disableSearch || submitting || isInvalid,
        isPrimary: true,
        onClick: () => submit(SEARCH_FOR_TEACHER_FORM_NAME),
        busy: submitting,
      },
    ]
  }

  return (
    <Modal
      headerTitle="Grant Access"
      modalCloseFocusElem={modalCloseFocusElem}
      shouldCloseModal={closeModal}
      onCloseAction={onCloseAction}
      footerActions={footerActions()}
    >
      <FindTeacherForm />

      {error ? <Error message={error} /> : null}

      {fetchedTeacher && !isEmpty(proId) ? (
        <div>
          <p style={{ marginBottom: '6px' }}>Grant access for the following teacher?</p>
          <p>
            <strong>{`${name} (${!isEmpty(email) ? email : 'No email address'})`}</strong>
          </p>
        </div>
      ) : fetchedTeacher && isEmpty(proId) ? (
        <p style={{ marginTop: '20px', marginBottom: '0' }}>
          No results found. Please ensure the email is correct and try again.
        </p>
      ) : null}
    </Modal>
  )
}

export default connect(mapStateToProps, {
  submit,
  change,
  fetchAvailableCourses,
  resetSearchForTeacher,
  grantTeacherAccessToCourse,
  resetGrantRevokeTeacherAccess,
})(GrantTeacherAccessModal)
