import { ORDER_SUBMITTED, ORDER_PENDING, ORDER_NOT_SUBMITTED } from './OrderConstants'
import { COURSE_TYPE_AP, COURSE_TYPE_PREAP, COURSE_TYPE_CAREER_KICKSTART } from './CourseConstants'
import { TEST_LOCATION_HOME, TEST_LOCATION_SCHOOL } from './SettingsConstants'
import {
  SDF_ACCESSIBILITY,
  SDF_EXTENDED_TIME,
  SDF_BREAKS,
  SDF_MULTIDAY,
  SDF_LIMITED_TIME,
} from './StudentConstants'

export const TEACHER_FILTER = { filter: 'tch', label: 'Teacher' }
export const ENROLLMENT_FILTER = { filter: 'nrll', label: 'Enrollment' }
export const SECTION_FILTER = { filter: 'sect', label: 'Section' }
export const EXAM_DATE_FILTER = { filter: 'exdt', label: 'Exam Date' }
export const ADMIN_FILTER = { filter: 'adm', label: 'Admin' }
export const COURSE_FILTER = { filter: 'crse', label: 'Course' }
export const CAPSTONE_FILTER = { filter: 'cap', label: 'AP Capstone' }
export const ORDER_EXAM_FILTER = { filter: 'oe', label: 'Order Exam?' }
export const FEE_STATUS_FILTER = { filter: 'fs', label: 'AP Fee Status' }
export const TEACHER_CONFIRM_FILTER = { filter: 'tcon', label: 'Order Exam?' }
export const SSD_GROUP = { group: 'acc', groupLabel: 'Special Materials' }
export const SSD_FILTER = { filter: 'ssd', label: 'Paper Exams - Special Materials', ...SSD_GROUP }
export const SSD_DIGITAL_FILTER = {
  filter: 'ssddig',
  label: 'Digital Exams - Special Materials',
  ...SSD_GROUP,
}
export const STUDENT_ACCOMMODATIONS_FILTER = { filter: 'stdntacc', label: 'Accommodations' }
export const SSDID_FILTER = { filter: 'ssdid', label: 'SSD ID' }
export const COURSE_TYPE_FILTER = { filter: 'ctype', label: 'Course Type' }
export const ORDER_STATUS_FILTER = { filter: 'ord', label: 'Order Status' }
export const SUBSIDY_FILTER = { filter: 'sub', label: 'Subsidy' }
export const FEES_FILTER = { filter: 'fees', label: 'Fees' }
export const STUDENT_NAME_ID_FILTER = { filter: 'stu', label: 'Student' }
export const APPROVALS_GROUP = { group: 'app', groupLabel: 'Approval Status' }
export const SPECIAL_MATERIALS_APPROVAL_REQUEST_FILTER = {
  filter: 'smar',
  label: 'Exams with Special Materials',
  ...APPROVALS_GROUP,
}
export const EXCEPTION_ORDERING_APPROVAL_REQUEST_FILTER = {
  filter: 'eoar',
  label: 'Exception Ordering',
  ...APPROVALS_GROUP,
}
export const ORDER_AFTER_DEADLINE_APPROVAL_REQUEST_FILTER = {
  filter: 'oadar',
  label: 'Order After Deadline',
  ...APPROVALS_GROUP,
}
export const TEST_LOCATION_FILTER = { filter: 'loc', label: 'Testing Location' }
export const LOGIN_STATUS_FILTER = { filter: 'log', label: 'Login Status' }
export const PRACTICE_STATUS_FILTER = { filter: 'prc', label: 'Practice Status' }
export const SETUP_STATUS_FILTER = { filter: 'set', label: 'Setup Status' }
export const CHECKIN_STATUS_FILTER = { filter: 'chk', label: 'Check-In Status ' }
export const EXAM_STATUS_FILTER = { filter: 'exm', label: 'Exam Status' }
export const MAKEUP_STATUS_FILTER = { filter: 'mkp', label: 'Makeup Status' }
export const DIGITAL_EXAM_FILTER = { filter: 'dig', label: 'Digital Only', hideLabel: true }
export const ACTIVE_ALERT_FILTER = {
  filter: 'alrt',
  label: 'Show Active Alerts Only',
  hideLabel: true,
}

export const ORDER_STATUS_FILTER_VALUES = {
  [ORDER_SUBMITTED]: 'Submitted',
  [ORDER_PENDING]: 'Unsubmitted - Changed',
  [ORDER_NOT_SUBMITTED]: 'Unsubmitted - New',
}

export const NO_SECTIONS = 'N'
export const PARTIAL = 'P'
export const FULL = 'F'
export const ENROLLMENT_FILTER_VALUES = {
  [NO_SECTIONS]: 'Courses without sections',
  [PARTIAL]: 'Partially enrolled sections',
  [FULL]: 'Full sections',
}

export const FILTER_YES = 'Y'
export const FILTER_NO = 'N'
export const TEACHER_CONFIRM_FILTER_VALUES = {
  [FILTER_YES]: 'Confirmed by teacher',
  [FILTER_NO]: 'Not confirmed by teacher',
}

export const SSD_DIGITAL_NONE = 'none'
export const SSD_FILTER_VALUES = {
  [FILTER_YES]: 'Yes',
  [FILTER_NO]: 'No',
  [SDF_ACCESSIBILITY]: 'Accessible Form',
  [SDF_EXTENDED_TIME]: 'Extended Time',
  [SDF_BREAKS]: 'Breaks',
  [SDF_LIMITED_TIME]: 'Limited Time Testing',
  [SDF_MULTIDAY]: 'Multi-day Testing',
  [SSD_DIGITAL_NONE]: 'None',
}

export const FILTER_DEFAULT_VALUES = {
  [FILTER_YES]: 'Yes',
  [FILTER_NO]: 'No',
}

export const LATE_ORDERING_FEE = 'lateOrderFee'
export const LATE_TESTING_FEE = 'alternateExamFee'
export const UNUSED_CANCELLATION_FEE = 'cancellationFee'
export const REFUNDED_FEE = 'refunded'
export const NOT_REFUNDED_FEE = `not_${REFUNDED_FEE}`
export const HELD_REFUND_FEE = 'refundOnHold'
export const FEES_FILTER_VALUES = {
  [LATE_ORDERING_FEE]: 'Late Order Fee',
  [LATE_TESTING_FEE]: 'Late-Testing Fee',
  [UNUSED_CANCELLATION_FEE]: 'Unused/Canceled Exam Fee',
  [REFUNDED_FEE]: 'Refunded Exam',
  [NOT_REFUNDED_FEE]: 'Not Refunded Exam',
  [HELD_REFUND_FEE]: 'Held Refunds',
}

export const COURSE_TYPE_FILTER_VALUES = {
  [COURSE_TYPE_AP]: 'AP',
  [COURSE_TYPE_CAREER_KICKSTART]: 'Career Kickstart',
  [COURSE_TYPE_PREAP]: 'Pre-AP',
}

export const TEST_LOCATION_FILTER_VALUES = {
  [TEST_LOCATION_HOME]: 'At Home',
  [TEST_LOCATION_SCHOOL]: 'In School',
}

export const SUBSIDY_NONE = 'none'
export const SUBSIDY_FILTER_VALUES = {
  [SUBSIDY_NONE]: 'None',
}

export const EXAM_DATE_CONFLICTS_OPTION = { value: 'cfl', label: 'Schedule Conflicts' }
