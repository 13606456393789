import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { openModal } from '../../../../actions/app'
import { getFilters } from '../../../../actions/filters'
import { unselectEnrollments } from '../../../../actions/studentsByOrg'
import { COURSE_FILTER } from '../../../../constants/FilterConstants'
import { Dropdown, Tooltip } from '../../../common'
import TestDateMultiSelect from '../updatemodals/TestDateMultiSelect'
import TestedWithAccomsMultiSelect from '../updatemodals/TestedWithAccomsMultiSelect'
import GenerateNARMultiSelect from '../updatemodals/GenerateNARMultiSelect'
import { getDeadlineIsPast } from '../../../../selectors/settings'

const ID = 'multiSelectActions'

const MultiSelectDropdown = connect(
  state => {
    const {
      settingsEducationPeriod: { selectedIsCurrent },
      user: {
        data: { isETS },
      },
    } = state
    return {
      hasStudentsSelected: state.studentsByOrg.selected?.length,
      isTestedWithAccommAvailable:
        getDeadlineIsPast(state, 'testedWithAccomStartDate') || !selectedIsCurrent,
      isNARAvailable: !isETS && getDeadlineIsPast(state, 'narGenerationStartDate'),
    }
  },
  { openModal, unselectEnrollments }
)(
  ({
    hasStudentsSelected,
    isTestedWithAccommAvailable,
    isNARAvailable,
    openModal,
    unselectEnrollments,
  }) => {
    const { search } = useLocation()
    const filters = getFilters(search)
    const isFilteredByCourse = filters[COURSE_FILTER.filter]?.length === 1

    useEffect(() => {
      unselectEnrollments()
    }, [search])

    return (
      <span style={{ marginRight: 15 }}>
        <Dropdown
          disabled={!(isFilteredByCourse && hasStudentsSelected)}
          applyStyles={{ display: 'inline-block' }}
          label="Actions"
          id={ID}
          menuItems={[
            {
              label: 'Change Exam Date',
              clickEvent: () => {
                openModal('UpdateMultiSelectModal', {
                  modalCloseFocusElem: document.getElementById(`dropdownMenu${ID}`),
                  ModalComponent: TestDateMultiSelect,
                })
              },
            },
            ...(isTestedWithAccommAvailable
              ? [
                  {
                    label: 'Change Student Tested with Accommodation Indicator',
                    clickEvent: () => {
                      openModal('UpdateMultiSelectModal', {
                        modalCloseFocusElem: document.getElementById(`dropdownMenu${ID}`),
                        ModalComponent: TestedWithAccomsMultiSelect,
                      })
                    },
                  },
                ]
              : []),
            ...(isNARAvailable
              ? [
                  {
                    label: 'Generate NAR',
                    clickEvent: () => {
                      openModal('MultiSelectGenerateNARModal', {
                        modalCloseFocusElem: document.getElementById(`dropdownMenu${ID}`),
                        ModalComponent: GenerateNARMultiSelect,
                      })
                    },
                  },
                ]
              : []),
          ]}
        />
        {!isFilteredByCourse ? (
          <Tooltip
            title="You must filter by one course in order to make changes for multiple students."
            placement="top"
            container="body"
            label="More information: Bulk actions information"
            style={{
              display: 'inline-block',
              verticalAlign: 'top',
              paddingLeft: '5px',
              fontSize: '.7em',
            }}
          >
            <i className="cb-glyph cb-glyph-circular cb-question" />
          </Tooltip>
        ) : null}
      </span>
    )
  }
)

export default MultiSelectDropdown
