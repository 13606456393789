import {
  COURSE_SCHEDULE_TYPES,
  CREATE_FORM_FIRST_SEMESTER_VALUE,
  CREATE_FORM_SECOND_SEMESTER_VALUE,
} from '../../constants/SectionConstants'
import Tooltip from './Tooltip'

const Icon = ({ condition, title, iconText }) =>
  condition ? (
    <div style={{ display: 'inline-block', marginRight: 5 }}>
      <Tooltip title={title} placement="top" container="body" label={`${title}`}>
        <span className="cb-glyph-numeric" style={{ fontSize: '.6em' }}>
          {iconText}{' '}
        </span>
      </Tooltip>
    </div>
  ) : null

export const WeServiceIcon = ({ weService }) => (
  <Icon condition={weService} title="WE Service Section" iconText="WE" />
)

export const FirstSemesterIcon = ({ sectionType }) => (
  <Icon
    condition={sectionType === CREATE_FORM_FIRST_SEMESTER_VALUE}
    title={`${COURSE_SCHEDULE_TYPES.S_FS.label} Section`}
    iconText="1"
  />
)

export const SecondSemesterIcon = ({ sectionType }) => (
  <Icon
    condition={sectionType === CREATE_FORM_SECOND_SEMESTER_VALUE}
    title={`${COURSE_SCHEDULE_TYPES.S_SS.label} Section`}
    iconText="2"
  />
)
