import { reduxForm, formValueSelector } from 'redux-form'
import {
  STUDENT_LABEL_SORT_ORDER_FORM,
  STUDENT_LABEL_SORT_ORDER_SELECT,
  STUDENT_LABEL_SORT_ORDER_SORT_OPTIONS,
} from '../../constants/OrderConstants'
import { Select } from '../../components/common'
import { submitLabelSortOrder, fetchLabelSortOrder } from '../../actions/orders'
import { addFetchAction, removeFetchAction } from '../../actions/app'

import styles from '../../assets/style/scss/student-labels.scss'

const mapStateToProps = (state, ownProps) => {
  const {
    user: {
      data: { selectedOrgId: orgId },
    },
    ordersStudentLabels: { labelSortOrder },
    settingsEducationPeriod: { selectedEducationPeriod },
    settingsDeadlines: { data: deadlines },
  } = state
  const form = ownProps.form || STUDENT_LABEL_SORT_ORDER_FORM

  return {
    orgId,
    educationPeriodCd: selectedEducationPeriod,
    initialValues: { [STUDENT_LABEL_SORT_ORDER_SELECT]: labelSortOrder },
    activeSort: formValueSelector(form)(state, STUDENT_LABEL_SORT_ORDER_SELECT),
    optOutDeadlineIsPast: deadlines[selectedEducationPeriod]?.optOutDeadlineIsPast,
    form,
  }
}

const StudentLabelSortForm = ({
  submitting,
  pristine,
  showSelectOnly,
  error,
  optOutDeadlineIsPast,
  handleSubmit,
  fetchLabelSortOrder,
  addFetchAction,
  removeFetchAction,
}) => {
  const errorRef = useRef(null)

  useEffect(() => {
    addFetchAction({
      name: 'fetchLabelSortOrder',
      action: (orgId, educationPeriodCd) => fetchLabelSortOrder(orgId, educationPeriodCd),
    })
    return () => removeFetchAction('fetchLabelSortOrder')
  }, [])

  useEffect(() => {
    if (error) {
      errorRef.current?.focus()
    }
  }, [error])

  return (
    <form onSubmit={handleSubmit} className={!showSelectOnly ? `${styles['sort-order-form']}` : ''}>
      {error ? (
        <p className="cb-error-msg" tabIndex="-1" ref={errorRef}>
          {error}
        </p>
      ) : null}
      <Select
        name={STUDENT_LABEL_SORT_ORDER_SELECT}
        options={STUDENT_LABEL_SORT_ORDER_SORT_OPTIONS}
        showEmptyOption={false}
        truncate={!showSelectOnly && 15}
        label={!showSelectOnly && 'Sort By: '}
      />
      {showSelectOnly ? null : (
        <button
          type="submit"
          className="btn btn-sm btn-secondary"
          disabled={pristine || submitting || optOutDeadlineIsPast}
        >
          {submitting ? 'Saving...' : 'Save Changes'}
        </button>
      )}
    </form>
  )
}

const StudentLabelSortBySelector = reduxForm({
  onSubmit: (data, dispatch, { orgId, educationPeriodCd }) =>
    submitLabelSortOrder(
      { labelSortOrder: data[STUDENT_LABEL_SORT_ORDER_SELECT] },
      orgId,
      educationPeriodCd
    )(dispatch),
  enableReinitialize: true, // Set initialValues after data is received from service, then reset form to pristine after every save
})(StudentLabelSortForm)

export default connect(mapStateToProps, {
  submitLabelSortOrder,
  fetchLabelSortOrder,
  addFetchAction,
  removeFetchAction,
})(StudentLabelSortBySelector)
