import { change as changeFieldValue } from 'redux-form'
import { InlineText, Input, CustomFontSizeValidation, Tooltip } from '../../common'
import { ConditionalMaterialField } from '../common/SsdUtils'
import {
  SELECT_SSD_MATERIALS_FORM,
  ALLOWED_SSD_MATERIALS,
  SSD_MATERIALS_FIELDS,
} from '../../../constants/StudentConstants'
import { isEmpty } from '../../../utils/common'

import styles from '../../../assets/style/scss/materials-modal.scss'

const largeBlockInfoMessage = `Large print answer sheets are automatically included in orders for large-type exams; indicate them separately if students require them for use with regular-format exams.`

const SpecialFormats = ({
  selectedSSDMaterials,
  metaData,
  allowedSSDMaterialCodes,
  changeFieldValue,
}) => {
  const disableFieldCheck =
    selectedSSDMaterials.specialFormatsCheck &&
    selectedSSDMaterials[SSD_MATERIALS_FIELDS.customFontSize.name] ===
      SSD_MATERIALS_FIELDS.customFontSize.value
  const inlineBlock = { display: 'inline-block' }
  const iconStyles = {
    display: 'inline-block',
    color: 'rgb(0, 119, 200)',
    fontSize: '8px',
  }

  useEffect(() => {
    // When a font size option is selected that is not the custom font size or ATC is selected and a
    // custom font size value has been provided, null out customFontSize
    // When ATC is selected, clear/deselect everything else in Special Formats
    if (
      (selectedSSDMaterials[SSD_MATERIALS_FIELDS.fontSize.name] !==
        SSD_MATERIALS_FIELDS.customFontSize.value ||
        selectedSSDMaterials[SSD_MATERIALS_FIELDS.fontSize.name] ===
          SSD_MATERIALS_FIELDS.assistiveTechnologyCompatible.value) &&
      !isEmpty(selectedSSDMaterials[SSD_MATERIALS_FIELDS.customFontSizeNumber.name])
    ) {
      changeFieldValue(
        SELECT_SSD_MATERIALS_FORM,
        SSD_MATERIALS_FIELDS.customFontSizeNumber.name,
        ''
      )
    }
  }, [
    selectedSSDMaterials[SSD_MATERIALS_FIELDS.fontSize.name],
    selectedSSDMaterials[SSD_MATERIALS_FIELDS.customFontSizeNumber.name],
  ])

  useEffect(() => {
    // 14point, 20point, and customFontSize all require largeBlockAnswerSheet to be selected
    if (
      (selectedSSDMaterials[SSD_MATERIALS_FIELDS.fontSize.name] ===
        SSD_MATERIALS_FIELDS['14point'].value ||
        selectedSSDMaterials[SSD_MATERIALS_FIELDS.fontSize.name] ===
          SSD_MATERIALS_FIELDS['20point'].value ||
        !isEmpty(selectedSSDMaterials[SSD_MATERIALS_FIELDS.customFontSizeNumber.name])) &&
      !selectedSSDMaterials[SSD_MATERIALS_FIELDS.largeBlockAnswerSheet.name]
    ) {
      changeFieldValue(
        SELECT_SSD_MATERIALS_FORM,
        SSD_MATERIALS_FIELDS.largeBlockAnswerSheet.name,
        true
      )
    }
  }, [
    selectedSSDMaterials[SSD_MATERIALS_FIELDS.fontSize.name],
    selectedSSDMaterials[SSD_MATERIALS_FIELDS.customFontSizeNumber.name],
    selectedSSDMaterials[SSD_MATERIALS_FIELDS.largeBlockAnswerSheet.name],
  ])

  useEffect(() => {
    // if this group is being toggled off, empty the fields
    if (!selectedSSDMaterials.specialFormatsCheck) {
      if (
        Object.keys(selectedSSDMaterials).length > 0 &&
        selectedSSDMaterials[SSD_MATERIALS_FIELDS.fontSize.name] !== null
      ) {
        changeFieldValue(SELECT_SSD_MATERIALS_FORM, SSD_MATERIALS_FIELDS.fontSize.name, null)
      }
      if (!isEmpty(selectedSSDMaterials[SSD_MATERIALS_FIELDS.customFontSizeNumber.name])) {
        changeFieldValue(
          SELECT_SSD_MATERIALS_FORM,
          SSD_MATERIALS_FIELDS.customFontSizeNumber.name,
          ''
        )
      }
      if (selectedSSDMaterials[SSD_MATERIALS_FIELDS.largeBlockAnswerSheet.name]) {
        changeFieldValue(
          SELECT_SSD_MATERIALS_FORM,
          SSD_MATERIALS_FIELDS.largeBlockAnswerSheet.name,
          false
        )
      }
    }
  }, [selectedSSDMaterials, selectedSSDMaterials.specialFormatsCheck])

  if (allowedSSDMaterialCodes.fontSizes.length > 0) {
    return (
      <div className={styles['materials-fieldset']}>
        <Input
          type="checkbox"
          name="specialFormatsCheck"
          labelId="examMaterialsGroup"
          label="Special Formats"
        />
        <div
          role="group"
          aria-labelledby="examMaterialsGroup"
          className={styles['materials-radiogroup']}
        >
          <ConditionalMaterialField
            allowedCodes={allowedSSDMaterialCodes}
            fieldName={ALLOWED_SSD_MATERIALS.fontSizes}
            fieldId={SSD_MATERIALS_FIELDS['14point'].value}
          >
            <div className={styles['material-radiogroup-wrap']}>
              <Input
                type="radio"
                name={SSD_MATERIALS_FIELDS['14point'].name}
                value={SSD_MATERIALS_FIELDS['14point'].value}
                label={metaData[SSD_MATERIALS_FIELDS['14point'].value].description}
                normalize={value => parseInt(value, 10)}
                disabled={!selectedSSDMaterials.specialFormatsCheck}
              />
            </div>
          </ConditionalMaterialField>

          <ConditionalMaterialField
            allowedCodes={allowedSSDMaterialCodes}
            fieldName={ALLOWED_SSD_MATERIALS.fontSizes}
            fieldId={SSD_MATERIALS_FIELDS.customFontSize.value}
          >
            <div className={styles['material-radiogroup-wrap-extended']}>
              <InlineText
                controlInput={{
                  type: 'radio',
                  name: SSD_MATERIALS_FIELDS.customFontSize.name,
                  value: SSD_MATERIALS_FIELDS.customFontSize.value,
                  label: metaData[SSD_MATERIALS_FIELDS.customFontSize.value].description,
                  normalize: value => parseInt(value, 10),
                  disabled: !selectedSSDMaterials.specialFormatsCheck,
                  input: {
                    name: SSD_MATERIALS_FIELDS.customFontSize.name,
                    value: SSD_MATERIALS_FIELDS.customFontSize.value,
                    checked:
                      selectedSSDMaterials[SSD_MATERIALS_FIELDS.fontSize.name] ===
                      SSD_MATERIALS_FIELDS.customFontSize.value,
                    onChange: () => {
                      changeFieldValue(
                        SELECT_SSD_MATERIALS_FORM,
                        SSD_MATERIALS_FIELDS.customFontSize.name,
                        SSD_MATERIALS_FIELDS.customFontSize.value
                      )
                    },
                  },
                }}
                name={SSD_MATERIALS_FIELDS.customFontSizeNumber.name}
                validate={disableFieldCheck ? [CustomFontSizeValidation] : null}
                maxlength="3"
                label="point"
                disabled={
                  selectedSSDMaterials[SSD_MATERIALS_FIELDS.customFontSize.name] !==
                  SSD_MATERIALS_FIELDS.customFontSize.value
                }
                isRequired={disableFieldCheck}
                withFlexbox={true}
              />
            </div>
          </ConditionalMaterialField>

          <ConditionalMaterialField
            allowedCodes={allowedSSDMaterialCodes}
            fieldName={ALLOWED_SSD_MATERIALS.fontSizes}
            fieldId={SSD_MATERIALS_FIELDS['20point'].value}
          >
            <div className={styles['material-radiogroup-wrap']}>
              <Input
                type="radio"
                name={SSD_MATERIALS_FIELDS['20point'].name}
                value={SSD_MATERIALS_FIELDS['20point'].value}
                label={metaData[SSD_MATERIALS_FIELDS['20point'].value].description}
                normalize={value => parseInt(value, 10)}
                disabled={!selectedSSDMaterials.specialFormatsCheck}
              />
            </div>
          </ConditionalMaterialField>

          <ConditionalMaterialField
            allowedCodes={allowedSSDMaterialCodes}
            fieldName={ALLOWED_SSD_MATERIALS.fontSizes}
            fieldId={SSD_MATERIALS_FIELDS.braille.value}
          >
            <div className={styles['material-radiogroup-wrap-extended']}>
              <Input
                type="radio"
                name={SSD_MATERIALS_FIELDS.braille.name}
                value={SSD_MATERIALS_FIELDS.braille.value}
                label={metaData[SSD_MATERIALS_FIELDS.braille.value].description}
                normalize={value => parseInt(value, 10)}
                disabled={!selectedSSDMaterials.specialFormatsCheck}
              />
            </div>
          </ConditionalMaterialField>

          <ConditionalMaterialField
            allowedCodes={allowedSSDMaterialCodes}
            fieldName={ALLOWED_SSD_MATERIALS.fontSizes}
            fieldId={SSD_MATERIALS_FIELDS.assistiveTechnologyCompatible.value}
          >
            <div className={styles['material-radiogroup-wrap-extended']}>
              <Input
                type="radio"
                name={SSD_MATERIALS_FIELDS.assistiveTechnologyCompatible.name}
                value={SSD_MATERIALS_FIELDS.assistiveTechnologyCompatible.value}
                label={
                  metaData[SSD_MATERIALS_FIELDS.assistiveTechnologyCompatible.value].description
                }
                normalize={value => parseInt(value, 10)}
                disabled={!selectedSSDMaterials.specialFormatsCheck}
              />
            </div>
          </ConditionalMaterialField>

          <ConditionalMaterialField
            allowedCodes={allowedSSDMaterialCodes}
            fieldName={ALLOWED_SSD_MATERIALS.answerSheets}
            fieldId={SSD_MATERIALS_FIELDS.largeBlockAnswerSheet.value}
          >
            <div className={styles['material-radiogroup-wrap-full']}>
              <Input
                type="checkbox"
                name={SSD_MATERIALS_FIELDS.largeBlockAnswerSheet.name}
                label={metaData[SSD_MATERIALS_FIELDS.largeBlockAnswerSheet.value].description}
                style={inlineBlock}
                // normalize={value => !!value}
                disabled={
                  !selectedSSDMaterials.specialFormatsCheck ||
                  selectedSSDMaterials[SSD_MATERIALS_FIELDS['14point'].name] ===
                    SSD_MATERIALS_FIELDS['14point'].value ||
                  selectedSSDMaterials[SSD_MATERIALS_FIELDS['20point'].name] ===
                    SSD_MATERIALS_FIELDS['20point'].value ||
                  selectedSSDMaterials[SSD_MATERIALS_FIELDS.customFontSize.name] ===
                    SSD_MATERIALS_FIELDS.customFontSize.value
                }
              />{' '}
              <div style={inlineBlock}>
                <Tooltip
                  title={largeBlockInfoMessage}
                  placement="top"
                  container="body"
                  label="More information: Large print answer sheet"
                >
                  <i
                    className="cb-glyph cb-glyph-xs cb-glyph-circular cb-question"
                    aria-hidden="true"
                    style={iconStyles}
                    data-alt={largeBlockInfoMessage}
                  />
                </Tooltip>
              </div>
            </div>
          </ConditionalMaterialField>
        </div>
      </div>
    )
  }

  return null
}

export default connect(null, { changeFieldValue })(SpecialFormats)
