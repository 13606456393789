import { iam } from '@myap/ui-library/esm/profile'
import axios, { getServerErrorMessage } from '../utils/axios'
import { isEmpty } from '../utils/common'

const { getJWTToken, getAuthSession } = iam()

export const fetchTeachers = (orgId, educationPeriodCd, testCd) => async dispatch => {
  const reqType = !testCd ? 'FETCH_ORG_TEACHERS' : 'FETCH_TEACHERS'
  try {
    dispatch({ type: `${reqType}_PENDING` })
    const headers = {
      'X-CB-Catapult-Authentication-Token': getAuthSession().sessionId,
      'X-CB-Catapult-Authorization-Token': getJWTToken(),
    }
    const { data } = await axios.get(
      `${Config.API_GATE_URL}/course-auth/organizations/${orgId}/professionals`,
      { params: { epc: educationPeriodCd, ...(!isEmpty(testCd) ? { testCd } : {}) }, headers }
    )
    dispatch({
      type: `${reqType}_FULFILLED`,
      payload: { orgId, ...(testCd ? { testCd } : {}), data: data?.professionals ?? [] },
    })
  } catch (err) {
    dispatch({ type: `${reqType}_REJECTED`, payload: getServerErrorMessage(err) })
  }
}
