import { Link } from 'react-router-dom'
import { COURSE_FILTER } from '../../../../constants/FilterConstants'
import { isTransferred, isDroppedWithCost, isMerged } from '../../../../selectors/student'

const CourseColumn = ({ rowData, id }) => {
  const { testCd, name } = rowData
  if (isDroppedWithCost(rowData))
    return (
      <>
        (D<span className="sr-only">ropped</span>) {name}
      </>
    )
  if (isTransferred(rowData))
    return (
      <>
        (T<span className="sr-only">ransferred Out</span>) {name}
      </>
    )
  if (isMerged(rowData))
    return (
      <>
        (M<span className="sr-only">erged Enrollment</span>) {name}
      </>
    )

  return (
    <span id={id}>
      <Link to={`/students?${COURSE_FILTER.filter}=${testCd}`}>{name}</Link>
    </span>
  )
}

CourseColumn.displayName = 'CourseColumn'

export default CourseColumn
