import memoize from 'memoizee'
import { reduxForm, formValueSelector } from 'redux-form'
import { Text, Select, EmailValidation } from '../../components/common'
import {
  SEARCH_FOR_TEACHER_FORM_NAME,
  SEARCH_FOR_TEACHER_FORM_FIELDS,
} from '../../constants/TeacherConstants'
import { searchForTeacher } from '../../actions/settingsTeacherAccess'
import { getAllCourses } from '../../selectors/course'
import { isEmpty } from '../../utils/common'

const selector = formValueSelector(SEARCH_FOR_TEACHER_FORM_NAME)

const mapStateToProps = state => {
  const { teacherSearch } = state
  const selectedCourseId = selector(state, SEARCH_FOR_TEACHER_FORM_FIELDS.courseField)

  return {
    initialValues: {
      [SEARCH_FOR_TEACHER_FORM_FIELDS.courseField]: '',
      [SEARCH_FOR_TEACHER_FORM_FIELDS.emailField]: '',
    },
    courses: getAllCourses({ state, apOnly: false }),
    selectedCourseId,
    foundTeacher: teacherSearch,
  }
}

const mapCourses = memoize(courses => courses.map(c => ({ label: c.courseName, value: c.testCd })))

const FindTeacherForm = ({ courses, selectedCourseId, foundTeacher, handleSubmit, error }) => (
  <form onSubmit={handleSubmit}>
    <fieldset>
      <Select
        name={SEARCH_FOR_TEACHER_FORM_FIELDS.courseField}
        label="Courses"
        isRequired={true}
        options={mapCourses(courses)}
        showEmptyOption={true}
      />

      <Text
        name={SEARCH_FOR_TEACHER_FORM_FIELDS.emailField}
        isSearchBox={true}
        isRequired={true}
        disabled={
          isEmpty(selectedCourseId) || (foundTeacher.fetched && !isEmpty(foundTeacher.personId))
        }
        label="Teacher's Email Address"
        placeholder="Enter email"
        additionalText="Teachers must create a College Board account before you can grant them access."
        maxlength="70"
        inputStyle={{ width: '100%' }}
        validate={[EmailValidation]}
      />

      {error && (
        <p
          tabIndex="-1"
          id={`errMessage-${SEARCH_FOR_TEACHER_FORM_NAME}`}
          className="cb-error-msg"
          role="alert"
          aria-live="polite"
        >
          {error}
        </p>
      )}
    </fieldset>
  </form>
)

export default connect(mapStateToProps, { searchForTeacher })(
  reduxForm({
    form: SEARCH_FOR_TEACHER_FORM_NAME,
    returnRejectedSubmitPromise: true,
    onSubmit: (values, dispatch, { searchForTeacher }) =>
      searchForTeacher({ email: values[SEARCH_FOR_TEACHER_FORM_FIELDS.emailField] }),
  })(FindTeacherForm)
)
