import { BasicInput, Loader } from '../../../common'
import {
  STANDARD_FEE_STATUS,
  REDUCED_FEE_STATUS,
  UPDATE_TYPE_FEE,
  UPDATE_TYPE_ENROLLMENT_SUBSIDIES,
} from '../../../../constants/StudentConstants'
import { updateFeesStatusStudent } from '../../../../actions/studentsByOrg'
import { isDroppedWithCost } from '../../../../selectors/student'

const FEE_STATUS_LABEL = 'Reduced Fee'

const mapStateToProps = (state, { exam = {}, inactive = false }) => {
  const {
    settingsEducationPeriod: { selectedEducationPeriod },
    settingsDeadlines: { data: settingsDeadlinesData },
    studentsByOrg: {
      studentMap,
      update: { id, type, updating },
    },
  } = state
  const frplDeadlineIsPast = settingsDeadlinesData?.[selectedEducationPeriod]?.frplDeadlineIsPast
  return {
    exam,
    feesStatus: studentMap[exam.studentId]?.feesStatus,
    updating: type === UPDATE_TYPE_FEE && id === exam.studentId && updating,
    isReduced: studentMap[exam.studentId]?.feesStatus === REDUCED_FEE_STATUS,
    showInactiveVersion: frplDeadlineIsPast || isDroppedWithCost(exam) || inactive,
    updatingSubsidies: type === UPDATE_TYPE_ENROLLMENT_SUBSIDIES && updating,
  }
}

export default connect(mapStateToProps, { updateFeesStatusStudent })(
  ({
    exam,
    exam: { enrollmentId = null, studentId, reasonInactive },
    isReduced,
    showInactiveVersion,
    showLabel = false,
    ariaLabelledBy,
    updating,
    updatingSubsidies,
    updateFeesStatusStudent,
  }) => {
    const [clicked, doClick] = useState(false)
    const checkRef = useRef(null)

    useEffect(() => {
      if (clicked && !updating) {
        checkRef.current?.focus()
        doClick(false)
      }
    }, [updating, clicked])

    const handleChange = e => {
      const { value } = e.target
      updateFeesStatusStudent(exam, value)
    }

    const handleClick = () => {
      doClick(true)
    }

    if (updating)
      return (
        <div className="checkbox" style={{ textAlign: showLabel ? 'left' : 'center' }}>
          <Loader size="sm" style={{ display: 'inline-block', margin: '0 6px 0 0' }} />{' '}
          <label style={{ paddingLeft: 0, marginRight: !showLabel ? '-10px' : null }}>
            {showLabel ? (
              <>{FEE_STATUS_LABEL}</>
            ) : (
              <span className="sr-only">{FEE_STATUS_LABEL}</span>
            )}
          </label>
        </div>
      )

    if (!reasonInactive || showInactiveVersion)
      return (
        <>
          <BasicInput
            type="checkbox"
            input={{
              name: `feestatus-${enrollmentId || studentId}`,
              checked: isReduced,
              onChange: handleChange,
              onClick: handleClick,
              value: isReduced ? STANDARD_FEE_STATUS : REDUCED_FEE_STATUS,
            }}
            label={
              showLabel ? (
                <>{FEE_STATUS_LABEL}</>
              ) : (
                <span className="sr-only">{FEE_STATUS_LABEL}</span>
              )
            }
            ariaLabel={FEE_STATUS_LABEL}
            ariaLabelledBy={ariaLabelledBy}
            disabled={updatingSubsidies || showInactiveVersion}
            style={{ textAlign: showLabel ? 'left' : 'center' }}
            labelStyle={!showLabel ? { marginRight: '-10px' } : {}} // offset margini added by Apricot
            ref={checkRef}
          />
        </>
      )
    return null
  }
)
